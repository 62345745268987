import axios from "axios";

export async function searchMovie(text: string) {
  try {
    const data = await axios.get(
      `https://api.themoviedb.org/4/search/multi?api_key=${
        process.env.REACT_APP_TROVE_KEY_MOVIE
      }&page=1&query=${text.trim()}`
    );
    if (data?.data?.results) {
      return { data: data.data.results, status: true };
    }
  } catch (e) {
    return { data: [], status: false, error: e };
  }
}
export async function searchBook(text: string) {
  try {
    const books = await axios.get(
      `https://www.googleapis.com/books/v1/volumes?q=${text.trim()}&key=${
        process.env.REACT_APP_KEY_FIREBASE
      }&download=epub`
    );
    if (books?.data?.items) {
      return { data: books.data.items, status: true };
    }
  } catch (e) {
    return { data: [], status: false, error: e };
  }
}

export async function searchLocation(text: string) {
  try {
    const locations = await axios.get(
      `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${text.trim()}&key=${
        process.env.REACT_APP_KEY_FIREBASE
      }`
    );
    if (locations.data?.results) {
      return { data: locations.data.results, status: true };
    }
  } catch (e) {
    return { data: [], status: false, error: e };
  }
}

export async function searchYoutube(text: string) {
  try {
    const results = await axios.get(
      `https://youtube.googleapis.com/youtube/v3/search?part=snippet&maxResults=25&q=${text.trim()}&key=${
        process.env.REACT_APP_KEY_FIREBASE
      }`
    );
    if (results.data?.items) {
      return { data: results.data.items, status: true };
    }
  } catch (e) {
    return { data: [], status: false, error: e };
  }
}

export async function oEmbedIframely(link: string) {
  try {
    const data = await axios.get(
      `https://iframe.ly/api/oembed?url=${link}&api_key=${process.env.REACT_APP_KEY_IFRAMELY}`
    );
    if (data.data) {
      return { data: data.data, status: true };
    }
  } catch (e) {
    return { data: [], status: false, error: e };
  }
}

const initTokenSpotify = async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: `Basic ${process.env.REACT_APP_SPOTIFY_TOKEN}`,
      },
    };

    const data = await axios.post(
      "https://accounts.spotify.com/api/token",
      "grant_type=client_credentials",
      config
    );
    if (data?.data) {
      return { access_token: data.data.access_token };
    }
  } catch (e) {}
};

export async function searchMusic(text: string) {
  try {
    const access_token: any = await initTokenSpotify();
    const results = await axios.get(
      `https://api.spotify.com/v1/search?query=${text.trim()}&offset=0&limit=10&type=artist,playlist,album,track`,
      {
        headers: {
          Authorization: `Bearer ${access_token.access_token}`,
        },
      }
    );
    if (results.data) {
      const data = [
        ...results.data.artists.items,
        ...results.data.playlists.items,
        ...results.data.albums.items,
        ...results.data.tracks.items,
      ].filter((item) => item);
      return { data: data, status: true };
    }
  } catch (e) {
    return { data: [], status: false, error: e };
  }
}

export async function searchPodcast(text: string) {
  try {
    const access_token: any = await initTokenSpotify();
    const results = await axios.get(
      `https://api.spotify.com/v1/search?query=${text.trim()}&offset=0&limit=10&type=show,episode&market=US`,
      {
        headers: {
          Authorization: `Bearer ${access_token.access_token}`,
        },
      }
    );
    if (results.data) {
      const data = [
        ...results.data.shows.items,
        ...results.data.episodes.items,
      ].filter((item) => item);
      return { data: data, status: true };
    }
  } catch (e) {
    return { data: [], status: false, error: e };
  }
}

export async function initUploadVideo(
  mime: string,
  url: string
): Promise<string> {
  const header = {
    headers: {
      "X-Goog-Resumable": "start",
      "Content-Type": mime,
    },
  };
  const response = await axios.put(`${url}`, {}, header);
  if (response?.headers?.["location"]) {
    return response?.headers?.["location"];
  } else {
    return "";
  }
}

export async function chunkSingleUpload(
  location: string,
  buffer: Buffer,
  updateProgressBar: any
): Promise<any> {
  const header = {
    headers: {
      "Content-Length": buffer.length,
    },
    onUploadProgress: (data: any) => {
      updateProgressBar(Math.round((100 * data.loaded) / data.total))
    },
  };
  const response = await axios.put(`${location}`, buffer, header);
  return response;
}

export async function chunkMultipleUpload(
  location: string,
  total: number,
  current: number,
  buffer: Buffer,
  updateProgressBar: any
): Promise<any> {
  const header = {
    headers: {
      "Content-Length": buffer.length,
      "Content-Range": `bytes ${current}-${
        current + buffer.length - 1
      }/${total}`,
    },
    onUploadProgress: (data: any) => {
      updateProgressBar(Math.round((100 * (data.loaded + current)) / total))
    },
  };
  const response = await axios.put(`${location}`, buffer, header);
  return response;
}
