import { assign, createMachine } from "xstate";
import { Collection } from "../../types/collection";

export type Category = string;

export interface CollectionWizardMachineContext {
  id: string | undefined;
  categories: Category[];
  coverImage: string | null;
  description: string | null;
  public: boolean;
  title: string | null;

  errorMessage: string | null;
}

export const contextFromCollection = (
  collection: Collection | undefined
): CollectionWizardMachineContext => {
  return {
    id: collection?.id,
    categories: collection?.categories || [],
    coverImage: collection?.coverImage || "",
    description: collection?.description || "",
    // If you do `public: collection?.public || true` here then  it's impossible
    // for it to ever start as false, even when that's correct
    public: collection?.public === undefined ? true : collection.public,
    errorMessage: null,
    title: collection?.title || "",
  };
};

export type CollectionWizardMachineEvent =
  | { type: "BACK" }
  | { type: "CANCEL" }
  | {
      type: "CHANGE_CATEGORIES";
      info: { categories: Category[] };
    }
  | { type: "VALIDATE_CATEGORIES" }
  | {
      type: "CHANGE_CONTENT";
      info: {
        title: string | null;
        description: string | null;
        coverImage: string | null;
        public: boolean;
      };
    }
  | { type: "SUBMIT" };

// Just sayin' this is the coolest variable name I've gotten to write in a while
const collectionWizardMachine = createMachine<
  CollectionWizardMachineContext,
  CollectionWizardMachineEvent
>(
  {
    id: "collectionWizard",
    context: {
      id: undefined,
      categories: [],
      coverImage: null,
      description: null,
      errorMessage: null,
      public: true,
      title: null,
    },
    initial: "pickingCategories",
    states: {
      pickingCategories: {
        initial: "idle",
        id: "pickingCategories",
        onDone: { target: "enteringContent" },
        states: {
          idle: {
            exit: ["clearErrorMessage"],
            after: { 2000: { actions: "clearErrorMessage" } },
            on: {
              CANCEL: {
                actions: ["clearContext", "done"],
                target: "#pickingCategories",
              },
              CHANGE_CATEGORIES: { actions: ["assignCategoriesToContext"] },
              VALIDATE_CATEGORIES: { target: "validating" },
            },
          },
          validating: {
            invoke: {
              src: "validateCategories",
              onDone: { target: "complete" },
              onError: {
                target: "idle",
                actions: ["assignErrorMessageToContext"],
              },
            },
          },
          complete: { type: "final" },
        },
      },
      enteringContent: {
        id: "enteringContent",
        onDone: {
          actions: ["clearContext", "success", "done"],
          target: "#pickingCategories",
        },
        initial: "idle",
        states: {
          idle: {
            exit: ["clearErrorMessage"],
            after: { 2000: { actions: "clearErrorMessage" } },
            on: {
              BACK: { target: "#pickingCategories" },
              CHANGE_CONTENT: { actions: ["assignContentToContext"] },
              CANCEL: {
                actions: ["clearContext", "done"],
                target: "#pickingCategories",
              },
              SUBMIT: "validating",
            },
          },
          validating: {
            invoke: {
              src: "validateCollection",
              onDone: { target: "submitting" },
              onError: {
                target: "idle",
                actions: "assignErrorMessageToContext",
              },
            },
          },
          submitting: {
            invoke: {
              src: "createCollection",
              onDone: { target: "complete" },
              onError: {
                target: "idle",
                actions: "assignErrorMessageToContext",
              },
            },
          },
          complete: { type: "final" },
        },
      },
    },
  },
  {
    services: {
      validateCategories: async (context) => {
        if (context.categories.length > 0 && context.categories.length <= 2) {
          return true;
        } else {
          throw new Error("At least one category is required");
        }
      },
      validateCollection: async (context) => {
        const errors = [];
        if (
          (context.title?.length || 0) < 4 ||
          (context.title?.length || Infinity) > 40
        ) {
          errors.push("Title must be between 4 and 40 characters.");
        }
        if (
          (context.description?.length || 0) < 4 ||
          (context.description?.length || Infinity) > 150
        ) {
          errors.push("Description must be between 4 and 150 characters.");
        }
        if (context.coverImage === null) {
          errors.push("Cover image is required.");
        }
        if (errors.length) {
          throw new Error(errors.join(" "));
        }
        return true;
      },
      createCollection: async (context) => {
        throw new Error("No callback passed in for createCollection");
      },
    },
    actions: {
      assignCategoriesToContext: assign((ctx, event) => {
        if (event.type !== "CHANGE_CATEGORIES") return {};

        const newContext = {
          ...event.info,
          errorMessage: null,
        };

        if (!ctx.id) {
          localStorage.setItem("new-collection", JSON.stringify(newContext));
        }
        return newContext;
      }),
      assignContentToContext: assign((ctx, event) => {
        if (event.type !== "CHANGE_CONTENT") return {};

        const newContext = {
          ...ctx,
          ...event.info,
        };

        if (!ctx.id) {
          localStorage.setItem("new-collection", JSON.stringify(newContext));
        }
        return newContext;
      }),
      clearErrorMessage: assign((_ctx) => {
        return { errorMessage: null };
      }),
      assignErrorMessageToContext: assign((context, event: any) => {
        return {
          errorMessage: event.data?.message || "An unknown error occurred",
        };
      }),
      clearContext: assign((ctx, _event) => {
        localStorage.removeItem("new-collection");
        return {
          categories: [],
          coverImage: null,
          description: null,
          errorMessage: null,
          title: null,
        };
      }),
      done: () => {
        throw new Error("No done call passed in for Collection Wizard");
      },
      success: () => {
        throw new Error("No done call passed in for Collection Wizard");
      },
    },
  }
);

export default collectionWizardMachine;
