import moment from "moment";
export const validateYouTubeUrl = (url: any) => {
  var regExp =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(regExp)) {
    return url.match(regExp)[1];
  }
  return false;
};

const YOUTUBE_ICON =
  "https://storage.googleapis.com/kernel-editors/youtube.png";
const MUSIC_ICON = "https://storage.googleapis.com/kernel-editors/music.png";
const PODCAST_ICON =
  "https://storage.googleapis.com/kernel-editors/podcast.png";
const DELETE_ICON =
  "https://storage.googleapis.com/kernel-editors/del_Image.png";
const MOVIE_ICON = "https://storage.googleapis.com/kernel-editors/youtube.png";
const BOOK_ICON = "https://storage.googleapis.com/kernel-editors/book.png";
const NOT_FOUND_ICON =
  "https://storage.googleapis.com/kernel-editors/image-not-found.png";
export const LOCATION_ICON =
  "https://storage.googleapis.com/kernel-editors/location.png";

export const IframeYoutube = (link: string, item?: any) =>
  `<a class="iframe-Youtube" href="${link}"  style="text-decoration: none; color: #fff;" onclick="_.sendEvent('YoutubeClick')" contenteditable="false">
            <div class="author-edit author-edit-youtube" style="margin: 3px 0;border: 2px solid #2C2C2C; border-radius: 16px; width: 300px; height : 80px; padding :10px; display: flex; justify-content: center; align-items: center;" contenteditable="false">
                <div style="color : #F0F0F0; font-weight: 400; display: flex; text-decoration: none; justify-content: center; align-items: center;" contentEditable="false">
                    <img class="image-youtube" style="object-fit: contain; width: 50px; border-radius: 5px" src="${YOUTUBE_ICON}" contenteditable="false"/>
                    <div class="view-title-youtube" style="line-height: 1.5em; padding-left: 8px; padding-right: 5px; text-align: left; width : 190px; overflow: hidden; display: block; justify-content: center; align-items: center;" contenteditable="false">
                        <div class="title" style="font-size: 14px; text-overflow: ellipsis; overflow: hidden; max-width : 150px; height : 50px;">${!!item ? item.snippet?.title : link}</div>
                    </div>
                </div>
                <div style="height: 50px; width: 2px; background: #2C2C2C" contenteditable="false" ></div>
                <div class="author-options" style="display:flex; width: 40px ; height: 60px;justify-content: center; align-items: center;" contenteditable="false">
                    <div style="height: 20px; width : 20px; display: inline-grid; justify-content: center; align-items: center;">
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false" ></div>
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                    </div>
                </div>
            </div>
            <div class="Reader-see Reader-see-youtube" style="display: none; width:100%; border-radius: 8px;overflow: hidden; ">
                <iframe class="iframe-Reader iframe-youtube" width="100%" height="220" 
                style="caret-color: #F3BC01"
                src="https://www.youtube.com/embed/${validateYouTubeUrl(link)}" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
            </div>
        </a>`;

export const renderImageLocation = (item: any) => {
  if (item?.photos?.[0]?.photo_reference) {
    return `https://maps.googleapis.com/maps/api/place/photo?photoreference=${item?.photos[0]?.photo_reference}&maxheight=400&maxwidth=400&key=${process.env.REACT_APP_KEY_FIREBASE}`;
  } else {
    return LOCATION_ICON;
  }
};

export const IframeLocation = (item: any) =>
  `<a class="iframe-Location" href="https://www.google.com/maps/place/?q=place_id:${item.place_id}" style="text-decoration: none; color: #fff;"  onclick="_.sendEvent('LocationClick')" contenteditable="false">

        <div style="margin: 3px 0;border: 2px solid #2C2C2C; border-radius: 16px; width: 300px; padding : 10px; display: flex; align-items: center;" contenteditable="false">
            <div class="view-location" style="color : #F0F0F0; font-weight: 400; display: flex; text-decoration: none; justify-content: center; align-items: center;" contentEditable="false">
                <img class="image-location" style="object-fit: contain; width: 50px; heigth : 50px; margin-left : 5px; border-radius: 5px" src="${renderImageLocation(item)}" />
                <div class="view-title" style="line-height: 1.5em; padding-left: 8px; padding-right: 5px; text-align: left; width : 190px; overflow: hidden; display: block; justify-content: center; align-items: center;" contenteditable="false">
                    <div class="name" style="font-weight : 700; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 14px">${item.name ?? ""}</div>
                    <div class="address" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 12px;">${item.rating ? "Rating : " + item.rating + "/5" : item.formatted_address}</div>
                    <div class="types" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 12px;">${item.rating ? item.formatted_address : ""}</div>
                </div>
            </div>
            <div class="author-edit line-height" style="height: 60px; width: 2px; background: #2C2C2C" contenteditable="false" ></div>
            <div class="author-edit more-option" style="display:flex; width: 40px ; height: 60px;justify-content: center; align-items: center;" contenteditable="false">
                <div style="height: 20px; width : 20px; display: inline-grid; justify-content: center; align-items: center;">
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false" ></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                </div>
            </div>
        </div>
    </a>`;

export const IframeGBook = (item: any) =>
  `<a class="iframe-Book" href="${item.volumeInfo.infoLink ?? ""}" style="text-decoration: none; color: #fff;" onclick="_.sendEvent('BookClick')" contenteditable="false">

        <div style="margin: 3px 0;border: 2px solid #2C2C2C; border-radius: 16px; width: 300px; padding :10px; display: flex; justify-content: center; align-items: center;" contenteditable="false">
            <div style="color : #F0F0F0; font-weight: 400; display: flex; text-decoration: none; justify-content: center; align-items: center;" contentEditable="false">
                <img class="image-book" style="object-fit: contain; width: 50px; margin-left : 5px; border-radius: 5px" src="${item.volumeInfo.imageLinks.thumbnail.replace("http://", "https://").replace(/zoom=1/g, "zoom=3")}" contenteditable="false"/>
                <div style="line-height: 1.5em; padding-left: 8px; padding-right: 5px; text-align: left; width : 190px; overflow: hidden; display: block; justify-content: center; align-items: center;" contenteditable="false">
                    <div class="title" style="font-weight : 700; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 14px">${item.volumeInfo.title ?? ""}</div>
                    <div class="authors" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 12px;">${item.volumeInfo.authors}</div>
                </div>
            </div>
            <div style="height: 60px; width: 2px; background: #2C2C2C" contenteditable="false" ></div>
            <div class="author-edit" style="display:flex; width: 40px ; height: 60px;justify-content: center; align-items: center;" contenteditable="false">
                <div style="height: 20px; width : 20px; display: inline-grid; justify-content: center; align-items: center;">
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false" ></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                </div>
            </div>
            <div class="Reader-see" style="display:none; width: 40px ; height: 60px; justify-content: center;">
                <img class="icon-book" style="object-fit: contain; width: 25px; height: 25px" src="${BOOK_ICON}"/>
            </div>
        </div>
    </a>`;

export const renderLinkMovie = (item: any) => {
  if (item.media_type === "tv") {
    return `https://www.themoviedb.org/tv/${item.id}`;
  } else {
    return `https://www.themoviedb.org/movie/${item.id}`;
  }
};

export const IframeMovie = (item: any) =>
  `<a class="iframe-Movie" href="${renderLinkMovie(item)}" style="text-decoration: none; color: #fff;" onclick="_.sendEvent('MovieClick')" contenteditable="false">
        <div style="margin: 3px 0;border: 2px solid #2C2C2C; border-radius: 16px; width: 300px; padding : 10px 10px 10px 10px; display: flex; justify-content: center; align-items: center;" contenteditable="false">
            <div style="color : #F0F0F0; font-weight: 400; display: flex; text-decoration: none; justify-content: center; align-items: center;" contentEditable="false">
                <img class="image-movie" style="object-fit: contain; width: 50px; margin-left : 5px; border-radius: 5px" src="https://image.tmdb.org/t/p/w500/${item.poster_path}" contenteditable="false"/>
                <div style="line-height: 1.5em; padding-left: 8px; padding-right: 5px; text-align: left; width : 190px; overflow: hidden;" contenteditable="false">
                    <div class="title" style="font-weight : 700; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 14px">${item?.title ?? item?.original_title ?? item.name ?? item.original_name}</div>
                    <div class="release-date" style="white-space: nowrap; font-size: 12px">${moment(item?.release_date).format("YYYY")}</div>
                    <div class="overview" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 12px;">${item.overview}</div>
                </div>
            </div>
            <div style="height: 60px; width: 2px; background: #2C2C2C" contenteditable="false" ></div>
            <div class="author-edit" style="display:flex; width: 40px ; height: 60px;justify-content: center; align-items: center;" contenteditable="false">
                <div style="height: 20px; width : 20px; display: inline-grid; justify-content: center; align-items: center;">
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false" ></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                </div>
            </div>
            <div class="Reader-see" style="display:none; width: 40px ; height: 60px; justify-content: center;">
                <img class="icon-movie" style="object-fit: contain; width: 25px; height: 25px" src="${MOVIE_ICON}"/>
            </div>
        </div>
    </a>`;

export const findAndReplace = (link: string) => {
  if (link.includes("artist")) return link.replace(`artist`, "embed/artist");
  else if (link.includes("playlist"))
    return link.replace(`playlist`, "embed/playlist");
  else if (link.includes("show")) return link.replace(`show`, "embed/show");
  else if (link.includes("episode"))
    return link.replace(`episode`, "embed/episode");
  else if (link.includes("album")) return link.replace(`album`, "embed/album");
  else if (link.includes("track")) return link.replace(`track`, "embed/track");
  else return link;
};

export const checkWidthIframeSpotify = (link: string) => {
  if (
    link.includes("artist") ||
    link.includes("playlist") ||
    link.includes("album") ||
    link.includes("track")
  ) {
    return 380;
  } else {
    return 232;
  }
};

export const imageMusic = (item: any) =>
  item?.album?.images[0]?.url || item?.images[0]?.url || MUSIC_ICON;

export const IframeMusic = (link: string, item?: any) =>
  `<a class="iframe-Music" href="${link}" style="text-decoration: none; color: #fff;" onclick="_.sendEvent('MusicClick')" contenteditable="false">
            <div class="author-edit author-edit-music" style="margin: 3px 0;border: 2px solid #2C2C2C; border-radius: 16px; width: 300px; height : 80px; padding :10px; display: flex; justify-content: center; align-items: center;" contenteditable="false">
                <div style="color : #F0F0F0; font-weight: 400; display: flex; text-decoration: none; justify-content: center; align-items: center;" contentEditable="false">
                    <img class="image-music" style="object-fit: contain; width: 50px; border-radius: 5px" src="${imageMusic(item ?? "")}" contenteditable="false"/>
                    <div class="view-title-music" style="line-height: 1.5em; padding-left: 8px; padding-right: 5px; text-align: left; width : 190px; overflow: hidden; display: block; justify-content: center; align-items: center;" contenteditable="false">
                    <div class="title" style="font-size: 14px; text-overflow: ellipsis; overflow: hidden; max-width : 150px; height : 50px;">${!!item ? item?.name : link}</div>
                    </div>
                </div>
                <div style="height: 50px; width: 2px; background: #2C2C2C" contenteditable="false" ></div>
                <div class="author-options" style="display:flex; width: 40px ; height: 60px;justify-content: center; align-items: center;" contenteditable="false">
                    <div style="height: 20px; width : 20px; display: inline-grid; justify-content: center; align-items: center;">
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false" ></div>
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                    </div>
                </div>
            </div>
            <div class="Reader-see Reader-see-music" style="display: none; width:100%; border-radius: 8px;overflow: hidden; ">
                <iframe 
                    class="iframe-Reader iframe-reader-music iframe-${checkWidthIframeSpotify(link)}"
                    style="caret-color: #F3BC01"
                    src="${findAndReplace(link)}" 
                    width="100%" 
                    height="${checkWidthIframeSpotify(link)}" 
                    frameBorder="0" 
                    allowtransparency="true" 
                    allow="encrypted-media">
                </iframe>
            </div>
        </a>`;

export const IframePodcast = (link: string, item?: any) =>
  `<a class="iframe-Podcast" href="${link}" style="text-decoration: none; color: #fff;" onclick="_.sendEvent('PodcastClick')" contenteditable="false">
            <div class="author-edit author-edit-music" style="margin: 3px 0;border: 2px solid #2C2C2C; border-radius: 16px; width: 300px; height : 80px; padding :10px; display: flex; justify-content: center; align-items: center;" contenteditable="false">
                <div style="color : #F0F0F0; font-weight: 400; display: flex; text-decoration: none; justify-content: center; align-items: center;" contentEditable="false">
                    <img class="image-music" style="object-fit: contain; width: 50px; border-radius: 5px" src="${item?.images[0]?.url || PODCAST_ICON}" contenteditable="false"/>
                    <div class="view-title-music" style="line-height: 1.5em; padding-left: 8px; padding-right: 5px; text-align: left; width : 190px; overflow: hidden; display: block; justify-content: center; align-items: center;" contenteditable="false">
                    <div class="title" style="font-size: 14px; text-overflow: ellipsis; overflow: hidden; max-width : 150px; height : 50px;">${item?.name || link}</div>
                    </div>
                </div>
                <div style="height: 50px; width: 2px; background: #2C2C2C" contenteditable="false" ></div>
                <div class="author-options" style="display:flex; width: 40px ; height: 60px;justify-content: center; align-items: center;" contenteditable="false">
                    <div style="height: 20px; width : 20px; display: inline-grid; justify-content: center; align-items: center;">
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false" ></div>
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                    <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                    </div>
                </div>
            </div>
            <div class="Reader-see Reader-see-music" style="display: none; width:100%; border-radius: 8px;overflow: hidden; ">
                <iframe 
                    class="iframe-Reader iframe-reader-music iframe-${checkWidthIframeSpotify(link)}"
                    style="caret-color: #F3BC01"
                    src="${findAndReplace(link)}" 
                    width="100%" 
                    height="${checkWidthIframeSpotify(link)}" 
                    frameBorder="0" 
                    allowtransparency="true" 
                    allow="encrypted-media">
                </iframe>
            </div>
        </a>`;

export const oEmbedIframely = (data: any, url: string) =>
  `<a class="embed-iframely" href="${url ?? ""}" style="text-decoration: none; color: #fff;" onclick="_.sendEvent('CustomEmbedLink')" contenteditable="false">

        <div style="margin: 3px 0;border: 2px solid #2C2C2C; border-radius: 16px; width: 300px; padding :10px; display: flex; justify-content: center; align-items: center;" contenteditable="false">
            <div class="view-embed" style="color : #F0F0F0; font-weight: 400; display: flex; text-decoration: none; justify-content: center; align-items: center;" contentEditable="false">
                <img class="image-embed" style="object-fit: contain; width: 50px; margin-left : 5px; border-radius: 5px" src="${data?.thumbnail_url ?? NOT_FOUND_ICON}" contenteditable="false"/>
                <div style="line-height: 1.5em; padding-left: 8px; padding-right: 5px; text-align: left; width : 190px; overflow: hidden; display: block; justify-content: center; align-items: center;" contenteditable="false">
                    <div class="title" style="font-weight : 700; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 14px">${data?.title ?? url}</div>
                    <div class="authors" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;font-size: 12px;">${data?.description ?? ""}</div>
                </div>
            </div>
            <div class="author-edit edit-embed" style="height: 60px; width: 2px; background: #2C2C2C" contenteditable="false" ></div>
            <div class="author-edit edit-embed" style="display:flex; width: 40px ; height: 60px;justify-content: center; align-items: center;" contenteditable="false">
                <div style="height: 20px; width : 20px; display: inline-grid; justify-content: center; align-items: center;">
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false" ></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                <div style="width: 5px; height: 5px; background: #B2B2B2; border-radius: 50%" contenteditable="false"></div>
                </div>
            </div>
            <div class="Reader-see" style="display:none; width: 40px ; height: 60px; justify-content: center;">
            </div>
        </div>
    </a>`;

export const InsertImage = (data: any) =>
  `<div class="iframe-Image" style=" display : flex; width : 100%; justify-content: center; align-items: center" contenteditable="false">
        <div style='width : 300px ; height: 300px; position: relative; border-radius: 10px; overflow: hidden; margin : 5px 0'>
            <img class="author-edit icon-delete" style="position: absolute;right: 0;width: 40px;" onclick="_.sendEvent('ImageClick')" src="${DELETE_ICON}" />
            <img class="Reader-see image-custom" style='width : 300px ; height: 300px;border-radius: 10px; overflow: hidden;' src="${data}" />   
        </div>
    </div>`;

export const InsertVideo = (url: any) => (
  `<div class="iframe-video" style=" display : flex; width : 100%; justify-content: center; align-items: center">
        <div style='width : 300px; height : 300px ; position: relative; border-radius: 10px; overflow: hidden; margin : 5px 0'>
            <img class="author-edit icon-delete-video" style="position: absolute;right: 0;width: 40px;" src="https://${DELETE_ICON}" />
            <video src="${url}" poster="${url}#t=1" controls>
            <source src="${url}" type='video/mp4'>
                No video tag support
            </video>
        </div>
    </div>`
);

export const oEmbed = (link: string) => (
  `<span class="oEmbed" contenteditable="false">[embed:${link}]</span>`
)