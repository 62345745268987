import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import KernelList from "./KernelList";
import KernelContent from "./KernelContent";

import { ReactComponent as UpArrow } from "../../assets/up_arrow.svg";
import { ReactComponent as DownArrow } from "../../assets/down_arrow.svg";

import {
  useGetAllKernelsQuery,
  useGetCollectionQuery,
  useGetKernelQuery,
  useGetKernelsForCollectionQuery,
  useGetKernelsForPrivateCollectionQuery,
  useGetUserPreviewQuery,
} from "../../api/troveApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import RoundButton from "../RoundButton";
import classNames from "classnames";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import { bookmarksCollection } from "../../content/utils";
import { Kernel } from "../../types/kernel";
import {
  youTubeImage,
  extractSelector,
} from "../../content/utils";

const KernelPage = () => {
  // There's a lot of complexity here, and it's probably not unreasonable to
  // pull out a hook that is something like `loadKernelsForCollection`, but
  // here's a summary of the possibilities:
  //  - We are looking at a kernel from a public collection. This one is the
  //  easiest path. In this case we get the kernel ID from the URL, and we query
  //  for that. When that query comes back we get the collectionId from the
  //  kernel data, and we query for that. When that comes back we get the userId
  //  from the collection data, and query for that, and when that comes back we
  //  are ready to load.
  // - We are looking at a kernel from the current user's private collection. In
  // this case, we need to hit a different endpoint to fetch the kernels, but
  // otherwise everything is still the same as above.
  // - We are looking at a kernel that has been bookmarked by the user. This means that:
  //   - The kernel will not have a collectionId
  //   - The userId is the currentUserId
  let { id } = useParams<{ id: string }>();
  const { data: kernel } = useGetKernelQuery({ id });
  const { userId: currentUserId } = useAppSelector(selectCurrentUser);
  const inBookmarkedContent = currentUserId && kernel?.collectionId === "";
  const { data: allKernels } = useGetAllKernelsQuery(
    inBookmarkedContent ? null : skipToken
  );
  const bookmarkedKernels = allKernels?.filter((k) => !k.collectionId);
  const bookmarkedCollection = inBookmarkedContent
    ? bookmarksCollection({
      kernelCount: bookmarkedKernels?.length,
      userId: currentUserId,
    })
    : undefined;

  const collectionId = kernel?.collectionId;
  const { data: collectionFromApi } = useGetCollectionQuery(
    collectionId ? { id: collectionId } : skipToken
  );
  const collection = collectionFromApi || bookmarkedCollection;

  const userId = collection?.userId;
  const { data: user } = useGetUserPreviewQuery(userId ?? skipToken);

  const { data: publicKernels } = useGetKernelsForCollectionQuery(
    collection?.public ? { collectionId: collection.id } : skipToken
  );
  // Try and fetch kernels for a private collection if this user is a
  // collaborator or the owner
  const { data: privateKernels } = useGetKernelsForPrivateCollectionQuery(
    !inBookmarkedContent &&
      collection?.public === false &&
      (collection?.collaborators.includes(user?.id || "") ||
        collection?.userId === user?.id)
      ? { collectionId: collection?.id }
      : skipToken
  );
  const kernels = compact(
    flatten([bookmarkedKernels, privateKernels, publicKernels])
  );

  const index = kernels.findIndex((k) => k.id === kernel?.id);
  const previousId = index > 0 ? kernels[index - 1]!.id : false;
  const nextId = index < kernels.length - 1 ? kernels[index + 1]!.id : false;

  const backgroundImageURL = (kernel: Kernel) => {
    return (
      kernel.imageUrls?.[0] ||
      youTubeImage(kernel.content) ||
      (
        extractSelector(
          kernel.content,
          "img:not(.author-edit)"
        ) as NodeListOf<HTMLImageElement>
      )[0]?.src ||
      collection?.coverImage
    );
  };

  return (
    <div className="with-sidebar pt-6">
      <Helmet>
        <title>{kernel?.title}</title>
        <meta property="description" content={kernel?.tagLine} />
        <meta property="og:title" content={kernel?.title} />
        <meta property="og:image" content={kernel ? backgroundImageURL(kernel) : collection?.coverImage} />
        <meta
          property="og:url"
          content={
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname
          }
        />
      </Helmet>
      <KernelList active={kernel?.id} kernels={kernels} />
      <KernelContent collection={collection} kernel={kernel} user={user} />
      <div className="absolute flex flex-col right-4 bottom-8">
        <Link to={`/kernel/${previousId ? previousId : id}`}>
          <RoundButton
            className={classNames("w-11 h-11 m-1", {
              "cursor-pointer border-dark-gray stroke-dark-gray dark:stroke-gray dark:border-gray":
                previousId,
              "border-gray stroke-gray dark:border-light-gray dark:stroke-light-gray":
                !previousId,
            })}
          >
            <UpArrow />
          </RoundButton>
        </Link>
        <Link to={`/kernel/${nextId ? nextId : id}`}>
          <RoundButton
            className={classNames("w-11 h-11 m-1", {
              "cursor-pointer border-dark-gray stroke-dark-gray  dark:border-gray dark:stroke-gray":
                nextId,
              "border-gray stroke-gray dark:border-light-gray dark:stroke-light-gray":
                !nextId,
            })}
          >
            <DownArrow />
          </RoundButton>
        </Link>
      </div>
    </div>
  );
};

export default KernelPage;
