import { User } from "firebase/auth";
import { assign, createMachine } from "xstate";

export type AuthenticationMachineContext = {
  userDetails: UserDetails | undefined;
};

interface UserDetails {
  token: string;
  user: User;
}

export type AuthenticationMachineEvent =
  | { type: "REPORT_IS_LOGGED_IN"; userDetails: UserDetails }
  | { type: "TOKEN_UPDATED"; userDetails: UserDetails }
  | { type: "REPORT_IS_LOGGED_OUT" };

const authenticationMachine = createMachine<
  AuthenticationMachineContext,
  AuthenticationMachineEvent
>(
  {
    id: "authentication",
    initial: "checkingIfLoggedIn",
    states: {
      checkingIfLoggedIn: {
        on: {
          REPORT_IS_LOGGED_IN: {
            target: "loggedIn",
            actions: ["assignUserDetailsToContext"],
          },
          REPORT_IS_LOGGED_OUT: {
            target: "loggedOut",
            actions: ["assignUserDetailsToContext"],
          },
        },
      },
      loggedIn: {
        invoke: { src: "persistUserDetails" },
        on: {
          REPORT_IS_LOGGED_OUT: {
            target: "loggedOut",
            actions: ["assignUserDetailsToContext"],
          },
          TOKEN_UPDATED: { target: "refreshingToken" },
        },
      },
      refreshingToken: {
        invoke: { src: "persistUserDetails" },
        onDone: {
          target: "loggedIn",
        },
      },
      loggedOut: {
        invoke: { src: "persistUserDetails" },
        on: {
          REPORT_IS_LOGGED_IN: {
            target: "loggedIn",
            actions: ["assignUserDetailsToContext"],
          },
        },
      },
    },
  },
  {
    actions: {
      assignUserDetailsToContext: assign((_context, event) => {
        return event.type === "REPORT_IS_LOGGED_IN" ||
          event.type === "TOKEN_UPDATED"
          ? { userDetails: event.userDetails }
          : {};
      }),
    },
    services: {
      persistUserDetails: () => {
        throw new Error("No action passed for persistUserDetails");
      },
    },
  }
);

export default authenticationMachine;
