import Button from "../Button";
import CollectionSummary from "./CollectionSummary";
import Kernels from "../Kernels";
import Loading from "../Loading";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Helmet } from "react-helmet";
import { Link, useLocation, useParams } from "react-router-dom";
import { ReactComponent as LeftChevron } from "../../assets/left_chevron.svg";
import { ReactComponent as Search } from "../../assets/search.svg";
import { resourceRequested } from "../../state/reducers/spotify";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { spotifyURLsWithCategory } from "../../content/utils";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  useGetCollectionQuery,
  useGetKernelsForCollectionQuery,
  useGetKernelsForPrivateCollectionQuery,
  useGetUserPreviewQuery,
} from "../../api/troveApi";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { matchSorter } from "match-sorter";
import { selectCurrentUser } from "../../state/reducers/auth";
import { XState } from "../../state/xstate/XStateContext";
import { Modal } from "../../state/xstate/modal";

const CollectionPage = () => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const location = useLocation<{
    backToLabel: string | undefined;
    backToLink: string | undefined;
  }>();
  const { data: collection } = useGetCollectionQuery({ id });
  const { data: user } = useGetUserPreviewQuery(
    collection?.userId || skipToken
  );
  // I should maybe write another hook that just combines these hooks someday
  const { data: publicKernels, isLoading: publicKernelsLoading } =
    useGetKernelsForCollectionQuery(
      collection?.public ? { collectionId: id } : skipToken
    );
  // Try and fetch kernels for a private collection if this user is a
  // collaborator or the owner
  const { data: privateKernels, isLoading: privateKernelsLoading } =
    useGetKernelsForPrivateCollectionQuery(
      collection?.public === false &&
        (collection?.collaborators.includes(user?.id || "") ||
          collection?.userId === user?.id)
        ? { collectionId: id }
        : skipToken
    );
  const kernels = compact(flatten([privateKernels, publicKernels]));

  const { modal } = useContext(XState);
  const { send } = modal;

  const isMobile = useMemo(() => {
    let check = false;

    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const spotify: SpotifyState = useAppSelector((state) => state.spotify);

  const { userId } = useAppSelector(selectCurrentUser);

  useEffect(() => {
    kernels?.forEach((kernel) => {
      const spotifyURL = spotifyURLsWithCategory(kernel.content);
      if (spotifyURL) {
        dispatch(resourceRequested(spotifyURL));
      }
    });
  }, [dispatch, kernels]);

  if (collection && user && !(publicKernelsLoading || privateKernelsLoading)) {
    return (
      <div className="with-sidebar">
        <Helmet>
          <title>{collection.title}</title>
          <meta property="description" content={collection.description} />
          <meta property="og:title" content={collection.title} />
          <meta property="og:image" content={collection.coverImage} />
          <meta
            property="og:url"
            content={
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname
            }
          />
        </Helmet>
        <CollectionSummary collection={collection} user={user} />
        <div
          style={{ maxWidth: 1200 }}
          className="flex flex-col px-4 md:pr-8 md:pl-0 pt-6 mt-2"
        >
          <Link to={location.state?.backToLink || `/profile/${user.username}`}>
            <LeftChevron className="inline mr-4" />
            &nbsp; Back to{" "}
            <strong>{location.state?.backToLabel || "Collections"}</strong>
          </Link>
          {userId === user.id &&
            (isMobile ? (
              <div
                className="z-1 flex items-center justify-center fixed right-6 bottom-6 w-14 h-14 rounded-full bg-gold cursor-pointer"
                onClick={() =>
                  send({ type: "SHOW_MODAL", modal: Modal.AddingContent })
                }
              >
                <Plus className="fill-dark-gray stroke-dark-gray" />
              </div>
            ) : (
              <Link to={`/collection/${collection.id}/new`}>
                <div className="z-1 flex items-center justify-center fixed right-6 bottom-6 w-14 h-14 rounded-full bg-gold cursor-pointer">
                  <Plus className="fill-dark-gray stroke-dark-gray" />
                </div>
              </Link>
            ))}
          {(kernels?.length || 0) > 0 ? (
            <>
              <div className="my-12 items-center relative hidden md:flex">
                <Search className="absolute left-4 fill-dark-gray stroke-dark-gray" />
                <input
                  className="input !pl-12"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <Kernels
                fallbackImage={collection.coverImage}
                kernels={
                  searchTerm.length > 0
                    ? matchSorter(kernels, searchTerm, {
                        keys: ["title", "description", "content"],
                      })
                    : kernels
                }
                spotify={spotify}
              />
            </>
          ) : userId === user.id ? (
            <div className="border-dashed dark:bg-neutral-800 border border-neutral-200 mt-12 my-20 py-20 rounded-2xl bg-white flex items-center text-center">
              <div className="m-auto dark:text-white text-neutral-500">
                <p>Kernels are your mini blog posts.</p>
                <p className="mb-4">Create your first one!</p>
                <Link to={`/collection/${collection.id}/new`}>
                  <Button className="bg-green text-white px-16">
                    Create Kernel
                  </Button>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default CollectionPage;
