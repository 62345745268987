import React from "react";

const Button = ({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler;
}) => (
  <div
    onClick={onClick}
    className={`text-center cursor-pointer inline-block rounded-lg bg-white dark:bg-green dark:border-green px-8 py-2 border-neutral-300 border font-bold ${className}`}
  >
    {children}
  </div>
);

export default Button;
