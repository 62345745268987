import { Link, Redirect, useLocation, useParams } from "react-router-dom";
import { ReactComponent as Plus } from "../assets/plus.svg";
import { Helmet } from "react-helmet";

import Collections from "./collections/Collections";
import ProfileSummary from "./ProfileSummary";
import { useAppSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/reducers/auth";
import Loading from "./Loading";
import {
  useGetBookmarkedContentQuery,
  useGetCollectionsForUserQuery,
  useGetSubscribersQuery,
  useGetSubscriptionsForUserQuery,
  useGetUserPreviewQuery,
} from "../api/troveApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Modal } from "../state/xstate/modal";
import { useContext, useMemo } from "react";
import { XState } from "../state/xstate/XStateContext";
import compact from "lodash/compact";
import Tab from "./Tab";
import Subscriptions from "./collections/Subscriptions";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import unidecode from "unidecode";

enum ProfileTabs {
  Collections,
  Subscribed,
}

const Profile = () => {
  const isMobile = useMemo(() => {
    let check = false;
    (function (a) {
      if (
        // eslint-disable-next-line
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
  }, []);
  const location = useLocation<any>();
  const { modal } = useContext(XState);
  const { send } = modal;
  const { username, tab: tabSlug } = useParams<{
    username: string;
    tab: string;
  }>();
  const tab =
    tabSlug === "subscribed" ? ProfileTabs.Subscribed : ProfileTabs.Collections;
  const { userId } = useAppSelector(selectCurrentUser);
  const { data: user, isLoading: userIsLoading } =
    useGetUserPreviewQuery(username);

  const isCurrentUser = userId === user?.id;
  const { data: collections, isLoading: collectionsAreLoading } =
    useGetCollectionsForUserQuery(
      user ? { id: user.id, currentUserId: userId } : skipToken
    );
  const { data: subscriptions, isLoading: subscriptionsAreLoading } =
    useGetSubscriptionsForUserQuery(userId ? null : skipToken);
  const { data: bookmarks, isLoading: bookmarksAreLoading } =
    useGetBookmarkedContentQuery(isCurrentUser ? { id: user.id } : skipToken);
  let allCollections = compact([
    bookmarks,
    ...reverse(sortBy(collections || [], (c) => c.updatedAt)),
  ]);
  const subscribers = useGetSubscribersQuery(isCurrentUser ? null : skipToken)
    ?.data?.subscribers;

  //Redirect for the old URL
  if (username.includes(".")) {
    const usernameSanitized = unidecode(username)
      .replace(/[^-a-zA-Z0-9.\s]/gi, "")
      .replace(/[^-a-zA-Z0-9]/gi, "-")
      .replace(/-{2,}/gi, "-")
      .replace(/^-/gi, "")
      .toLowerCase();

    return (
      <Redirect
        to={
          location.pathname.includes("/subscribed")
            ? `/profile/${usernameSanitized}/subscribed`
            : `/profile/${usernameSanitized}`
        }
      />
    );
  }

  if (
    bookmarksAreLoading ||
    collectionsAreLoading ||
    subscriptionsAreLoading ||
    userIsLoading ||
    !user ||
    !collections
  ) {
    return <Loading />;
  }

  return (
    <div className="with-sidebar">
      <Helmet>
        <title>{`${user.firstname} ${user.lastname}`}</title>
        <meta property="description" content={user.bio} />
        <meta property="og:description" content={user.bio} />
        <meta
          property="og:title"
          content={`${user.firstname} ${user.lastname}`}
        />
        <meta property="og:image" content={user.profilePicture} />
        <meta
          property="og:url"
          content={
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname
          }
        />
      </Helmet>
      <ProfileSummary
        isCurrentUser={isCurrentUser}
        user={user}
        subscribers={subscribers}
      />
      <div className="px-4 md:pr-8 md:pl-0 flex flex-col min-w-0">
        {/* On other pages there are breadcrumbs here, so this is like a
        placeholder. It doesn't *really* matter, but it's nice that the page
        headers stay exactly the same height as you navigate */}
        <div className="mt-2">&nbsp;</div>
        {isCurrentUser && (
          <div className="flex text-lg mb-12">
            <Link to={`/profile/${user.username}`}>
              <Tab
                active={tab === ProfileTabs.Collections}
                onClick={() => {}}
                title="Collections"
              />
            </Link>
            <Link to={`/profile/${user.username}/subscribed`}>
              <Tab
                active={tab === ProfileTabs.Subscribed}
                title="Subscribed"
                onClick={() => {}}
              />
            </Link>
          </div>
        )}
        {tab === ProfileTabs.Subscribed && <Subscriptions />}
        {tab === ProfileTabs.Collections && (
          <Collections
            user={user}
            collections={allCollections}
            subscriptions={subscriptions?.map((c) => c.id)}
            isCurrentUser={isCurrentUser}
          />
        )}
      </div>
      {userId === user.id && (
        <div
          className="z-1 flex items-center justify-center fixed right-6 bottom-6 w-14 h-14 rounded-full bg-gold cursor-pointer"
          onClick={() =>
            send({
              type: "SHOW_MODAL",
              modal: isMobile ? Modal.AddingContent : Modal.NewCollection,
            })
          }
        >
          <Plus className="fill-dark-gray stroke-dark-gray" />
        </div>
      )}
    </div>
  );
};

export default Profile;
