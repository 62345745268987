import axios from "axios";

export type Response<Type> = Promise<{ data: Type }>;

export const generateToken = async (): Promise<string> => {
  return (
    await axios.post(
      `https://accounts.spotify.com/api/token`,
      "grant_type=client_credentials",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: `Basic ${process.env.REACT_APP_SPOTIFY_TOKEN}`,
        },
      }
    )
  ).data.access_token;
};

export const spotifyApi = axios.create({
  baseURL: "https://api.spotify.com/v1/",
});

export const authedConfig = async () => {
  return {
    headers: {
      Authorization: `Bearer ${await generateToken()}`,
    },
  };
};

export const fetchResource = async (
  id: string,
  type: SpotifyResourceCategory
) => {
  return (await spotifyApi.get(`${type}/${id}?market=US`, await authedConfig()))
    .data;
};
