import classNames from "classnames";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { XStateProvider } from "./state/xstate/XStateContext";
import { persistor, store } from "./state/store";
import { useState } from "react";

import AccountWizard from "./components/accountCreation/AccountWizard";
import Analytics from "./components/Analytics";
import BookmarkedContentPage from "./components/BookmarkedContentPage";
import CollectionPage from "./components/collections/CollectionPage";
import Home from "./components/Home";
import KernelForm from "./components/kernel/KernelForm";
import KernelPage from "./components/kernel/KernelPage";
import Layout from "./components/Layout";
import Login from "./components/Login";
import ConfirmEmail from "./components/ConfirmEmail";
import CompleteYourProfile from "./components/CompleteYourProfile";
import MainNav from "./components/MainNav";
import Modal from "./components/modal";
import Profile from "./components/Profile";
import WaitForAuth from "./components/WaitForAuth";
import Welcome from "./components/Welcome";
import AccountError from "./components/AccountError";
import { MainNavActions } from "./components/MainNavActions";
import { defaultTheme, Theme, ThemeContext } from "./components/ThemePicker";
import branch from "branch-sdk";

import "./App.css";
import ExplorePage from "./components/explore/ExplorePage";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

branch.init(
  `${process.env.REACT_APP_BRANCH_KEY}`,
  { timeout: 10000 },
  () => { }
);

function App() {
  const [theme, setTheme] = useState<Theme>(defaultTheme());

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <div
        className={classNames({
          dark: theme === Theme.Dark,
          light: theme !== Theme.Dark,
        })}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Trove Collective</title>
          <link rel="canonical" href="https://www.trovecollective.co/" />
          <meta property="og:url" content="https://www.trovecollective.co/" />
          <meta property="og:type" content="website" />
          <meta
            content="Trove is the home to your thoughts, passions, hobbies. Come to share, discover, and learn."
            property="description"
          />
          <meta
            content="Trove - the future of blogging"
            property="twitter:title"
          />
          <meta content="Trove - the future of blogging" property="og:title" />
          <meta
            content="https://uploads-ssl.webflow.com/615deea09f73ef5291d8fde8/6171bf04da07417002764021_Open%20Graph%20(1).png"
            property="og:image"
          />
        </Helmet>
        <Provider store={store}>
          <PersistGate persistor={persistor}>

            <Router>
              <XStateProvider>
                <WaitForAuth>
                  <Analytics />
                  <Layout>
                    <MainNav actions={<MainNavActions />} />
                    <Switch>
                      <Route path="/account_error">
                        <AccountError />
                      </Route>
                      <Route path="/welcome">
                        <Welcome />
                      </Route>
                      <Route path="/signup">
                        <AccountWizard />
                      </Route>
                      <Route path="/login">
                        <Login />
                      </Route>
                      <Route path="/confirm_email">
                        <Modal />
                        <ConfirmEmail />
                      </Route>
                      <Route path="/complete_your_profile">
                        <CompleteYourProfile />
                      </Route>
                      <Route path="/profile/:username/:tab?">
                        <Modal />
                        <Profile />
                      </Route>
                      <Route path="/collection/:collectionId/new">
                        <Modal />
                        <KernelForm />
                      </Route>
                      <Route path="/collection/:id">
                        <Modal />
                        <CollectionPage />
                      </Route>
                      <Route path="/collection">
                        <Modal />
                        <BookmarkedContentPage />
                      </Route>
                      <Route path="/kernel/:id/edit">
                        <Modal />
                        <KernelForm />
                      </Route>
                      <Route path="/kernel/:id">
                        <Modal />
                        <KernelPage />
                      </Route>
                      <Route path="/explore">
                        <Modal />
                        <ExplorePage />
                      </Route>
                      <Route path="/">
                        <Modal />
                        <Home />
                      </Route>
                    </Switch>
                  </Layout>
                </WaitForAuth>
              </XStateProvider>
            </Router>
          </PersistGate>
        </Provider>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
