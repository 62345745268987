import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
interface ProgressBarProps {
  progress: number;
  trailColor?: string;
  strokeWidth?: number;
  bgColor?: string;
  textSize?: string;
  textColor?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  const { 
    progress = 0, 
    trailColor = '#d6d6d6', 
    bgColor = '#2465FD',
    strokeWidth = 4,
    textSize = '16px',
    textColor = '#3e98c7'
  } = props;

  return (
    <div 
      className={`absolute flex z-50 left-0 top-0 w-full h-full overflow-auto bg-neutral-800/75 first-letter:flex justify-center items-center`}
    >
      <div className='fixed w-20 h-20'>
        <CircularProgressbar 
          value={progress} 
          text={`${progress}%`}
          strokeWidth={strokeWidth}
          styles={buildStyles({
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            textSize: textSize,
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
            trailColor: trailColor,
            backgroundColor: bgColor,
            textColor: textColor
          })}
        />
      </div>
    </div>
  );
};
  
export default ProgressBar;