import { compact, uniq } from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetCollectionQuery,
  useUpdateHashtagsMutation,
} from "../../api/troveApi";
import { normalizeTag } from "../../content/tags";
import { BannerTheme } from "../../state/xstate/banner";
import { XState } from "../../state/xstate/XStateContext";
import Loading from "../Loading";

const AddTags = () => {
  const { id } = useParams<{ id: string }>();
  const { data: collection } = useGetCollectionQuery({ id });
  const [partialTag, setPartialTag] = useState<string>("");
  const [updateTags] = useUpdateHashtagsMutation();
  const { banner } = useContext(XState);

  if (!collection) {
    return <Loading />;
  }

  const update = (hashtags: string[]) => {
    updateTags({ id, hashtags }).then((result) => {
      if (result.hasOwnProperty("error")) {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            theme: BannerTheme.Error,
            content: "Something went wrong while saving your changes",
            id: String(Math.random() * 100000000),
          },
        });
      } else {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            theme: BannerTheme.Success,
            content: "Success.",
            id: "updateTagsSuccess",
          },
        });
      }
    });
  };

  return (
    <div
      className="flex flex-col rounded-xl text-center pt-8 pb-4 px-4"
      style={{ width: 600, maxWidth: "100%" }}
    >
      <div className="!text-left px-4 mb-12">
        <h1 className="font-raleway font-bold text-2xl text-left mb-12">
          Add Tags
        </h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            update(
              uniq(
                compact([partialTag, ...(collection?.hashtags || [])]).map(
                  normalizeTag
                )
              )
            );
            setPartialTag("");
          }}
        >
          <input
            className="input mb-6"
            placeholder="Type your hashtag here..."
            value={partialTag}
            onChange={(e) => setPartialTag(normalizeTag(e.target.value))}
          />
        </form>
        <h3 className="font-raleway text-sm text-left mb-6">Existing Tags</h3>
        {(collection?.hashtags || []).map((hashtag) => (
          <div
            key={normalizeTag(hashtag)}
            className="inline-flex items-center text-sm py-1.5 px-3 m-1 bg-white inline-block rounded-md border border-neutral-300 font-bold"
          >
            <span
              className="flex items-center left-0 cursor-pointer h-4 w-4 border-0"
              onClick={() => {
                update(
                  (collection.hashtags || []).filter(
                    (h) => normalizeTag(h) !== normalizeTag(hashtag)
                  )
                );
              }}
            >
              ✕
            </span>
            {normalizeTag(hashtag)}
          </div>
        ))}
      </div>
    </div>
  );
};
export default AddTags;
