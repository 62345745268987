import { CollectionExplore } from "../../types/collection";
import { ReactComponent as RightArrow } from "../../assets/ic_arr_right_green.svg";
import { ReactComponent as BellSubscribed } from "../../assets/ic_bell_subscribed.svg";
import { Link } from "react-router-dom";

interface Props {
  collection: CollectionExplore;
  onPressSubscribe: (id: string) => void;
  subscribed: boolean;
}

const ItemCollection = (props: Props) => {
  const { collection, onPressSubscribe, subscribed = false } = props;
  const user = collection.user;

  return (
    <div className="bg-white dark:bg-dark my-4 rounded-lg px-4 py-5 stroke-transparent">
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <Link
            className="grow font-inter text-dark dark:text-light font-bold text-xl pr-1"
            to={`/collection/${collection.id}`}
          >
            {collection.title}
          </Link>
          <div className="flex flex-row pl-1">
            <Link
              className="xs:hidden sm:flex w-9 h-9 bg-neutral-100 dark:bg-green/[0.2] rounded-md justify-center items-center mr-1"
              to={`/collection/${collection.id}`}
            >
              <RightArrow className="w-4 h-4" />
            </Link>
            <button
              className={`flex flex-nowrap py-1 px-2 ${
                subscribed ? `bg-green/[0.2]` : `bg-gold/[0.1]`
              } rounded-md justify-center items-center ml-1`}
              onClick={() => onPressSubscribe(collection.id)}
            >
              {subscribed ? (
                <>
                  <BellSubscribed className="h-5 pr-1" />
                  Subscribed
                </>
              ) : (
                <>
                  <BellSubscribed className="h-5 pr-1" />
                  Subscribe
                </>
              )}
            </button>
          </div>
        </div>
        <div className="flex flex-row mt-4">
          <Link to={`/profile/${user.username}`}>
            <img
              className="flex w-8 h-8 rounded-lg border-solid border border-light-gray dark:border-dark-gray bg-center bg-cover"
              src={user.profilePicture}
              alt=""
            />
          </Link>
          <Link
            className="flex flex-1 pl-2 font-inter font-normal text-dark dark:text-light text-sm items-center"
            to={`/profile/${user.username}`}
          >
            {user.username}
          </Link>
        </div>
        <div className="flex mt-4 font-inter text-xs text-dark dark:text-light font-normal">
          {collection.description}
        </div>
        {collection.kernels && collection.kernels.length > 0 && (
          <>
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-flow-col-1 gap-4 mt-4">
              {collection.kernels.slice(0, 3).map((kernel, index) => {
                return (
                  <Link
                    to={`/kernel/${kernel.id}`}
                    key={index}
                    className="rounded-lg border border-light-gray dark:border-dark-gray p-2"
                  >
                    <div className="flex flex-row items-center">
                      <img
                        alt=""
                        src={kernel.cover}
                        className="w-14 h-14 rounded-lg"
                      />
                      <p className="ml-2 font-inter font-bold text-sm grow overflow-hidden">
                        {kernel.title}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </>
        )}
        <Link
          className="rounded-lg xs:flex sm:hidden justify-center py-6 border cursor-pointer bg-slate-100 border-light-gray dark:bg-dark-gray dark:border-dark-gray p-2 mt-4"
          to={`/collection/${collection.id}`}
        >
          <span className="text-emerald-800 dark:text-yellow-400">
            See more
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ItemCollection;
