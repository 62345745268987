import { assign, createMachine, DoneInvokeEvent, forwardTo } from "xstate";
import { AccountDetailsMachineEvent } from "./accountDetails";

export interface AccountWizardMachineContext {
  email: string | undefined;
  errorMessage: string | null;
  firstname: string | undefined;
  lastname: string | undefined;
  // The worst naming in the whole wide world. Sorry, this is what the API
  // expects.
  not_verify_email: boolean;
  phone: string | undefined;
  referralCode: string | undefined;
}

export type AccountWizardMachineEvent =
  | { type: "NEXT" }
  | { type: "PREVIOUS" }
  | { type: "VERIFY"; code: string }
  | { type: "SUBMIT" }
  | AccountDetailsMachineEvent;

const invalidCode = (
  context: AccountWizardMachineContext,
  event: DoneInvokeEvent<any>
) => ({ errorMessage: "That isn't a valid referral code" });

const validCode = (
  context: AccountWizardMachineContext,
  event: DoneInvokeEvent<string>
) => {
  return { referralCode: event.data };
};

const accountWizardMachine = createMachine<
  AccountWizardMachineContext,
  AccountWizardMachineEvent
>({
  id: "accountWizard",
  context: {
    email: undefined,
    errorMessage: null,
    firstname: undefined,
    lastname: undefined,
    not_verify_email: false,
    phone: undefined,
    referralCode: undefined,
  },
  initial: "intro",
  states: {
    intro: {
      id: "intro",
      on: { NEXT: { target: "referralCode" } },
    },
    referralCode: {
      id: "referralCode",
      on: {
        NEXT: { target: "accountDetails" },
        VERIFY: { target: "verifying" },
      },
    },
    verifying: {
      id: "verifying",
      invoke: {
        src: "verifyCode",
        onDone: { target: "accountDetails", actions: [assign(validCode)] },
        onError: { target: "referralCode", actions: [assign(invalidCode)] },
      },
    },
    accountDetails: {
      id: "accountDetails",
      invoke: {
        id: "signIn",
        src: "accountDetails",
        data: {
          referralCode: (context: AccountWizardMachineContext) => {
            return context.referralCode;
          },
        },
        onDone: { target: "complete" },
        onError: { target: "accountDetails" },
      },
      on: {
        SIGN_IN_WITH_EMAIL: { actions: forwardTo("signIn") },
        SIGN_IN_WITH_APPLE: { actions: forwardTo("signIn") },
        SIGN_IN_WITH_GOOGLE: { actions: forwardTo("signIn") },
      },
    },
    complete: { type: "final" },
  },
});

export default accountWizardMachine;
