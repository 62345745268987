import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CacheContent } from "../../types/kernel";

export interface ContentState {
  content: CacheContent[];
}

const initialState: ContentState = {
  content: [],
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    cachingContent: (
      state: ContentState,
      action: PayloadAction<CacheContent>
    ) => {
      const index =
        state.content.length > 0
          ? state.content.findIndex((item) => item.id === action.payload.id)
          : -1;
      if (index === -1) {
        state.content = [...state.content, action.payload];
      } else {
        state.content[index] = action.payload;
      }
    },
    removeCache: (
      state: ContentState,
      action: PayloadAction<{ id: string }>
    ) => {
      const index =
        state.content.length > 0
          ? state.content.findIndex((item) => item.id === action.payload.id)
          : -1;
      if (index !== -1) {
        state.content = state.content.filter(
          (item) => item.id !== action.payload.id
        );
      }
    },
    clearCache: (state: ContentState, action: PayloadAction<void>) => {
      state.content = [];
    },
  },
});

export const { cachingContent, removeCache, clearCache } = contentSlice.actions;

export default contentSlice.reducer;
