import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

type AuthState = {
  userId: string | null;
  token: string | null;
  loaded: boolean;
};

const slice = createSlice({
  name: "auth",
  initialState: { userId: null, token: null, loaded: false } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { userId, token },
      }: PayloadAction<{ userId: string | null; token: string | null }>
    ) => {
      state.userId = userId;
      state.token = token;
      state.loaded = true;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState): AuthState => state.auth;
