import Button from "../Button";
import { ReactComponent as RightArrow } from "../../assets/right_arrow.svg";
import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";
import { Modal } from "../../state/xstate/modal";
import { signOut } from "../../api/firebase";
import { useHistory } from "react-router-dom";

const Settings = () => {
  const { modal } = useContext(XState);
  const { send } = modal;
  const history = useHistory();

  return (
    <div
      className="flex flex-col rounded-xl text-center px-8 pb-12 py-6"
      style={{ width: 600, maxWidth: "100%" }}
    >
      <h1 className="font-raleway font-bold text-lg text-left mb-10">
        Settings
      </h1>
      <a href="mailto:admin@trovecollective.co">
        <Button className="!text-left bg-very-light-gray mb-2 flex justify-between items-center">
          Send Feedback (email admin@trovecollective.co)
          <RightArrow />
        </Button>
      </a>
      <Button
        className="!text-left bg-very-light-gray mb-2 flex justify-between items-center"
        onClick={async () =>
          send({ type: "SHOW_MODAL", modal: Modal.ReportAbuse })
        }
      >
        Report Abuse
        <RightArrow />
      </Button>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://www.trovecollective.co/#footer"
      >
        <Button className="!text-left bg-very-light-gray mb-2 flex justify-between items-center">
          Trove Collective's Policies
          <RightArrow />
        </Button>
      </a>
      <Button
        className="!text-left bg-very-light-gray mb-2 flex justify-between items-center"
        onClick={async () => {
          await signOut();
          history.push("/login");
        }}
      >
        Signout
        <RightArrow />
      </Button>
    </div>
  );
};

export default Settings;
