import classNames from "classnames";
import { Link } from "react-router-dom";

import { Kernel } from "../../types/kernel";

interface KernelListProps {
  active?: string;
  kernels?: Kernel[];
}

const KernelList = ({ active, kernels }: KernelListProps) => (
  <div className="px-8 sm:overflow-y-auto thin-scrollbar">
    {kernels
      ? kernels.map(({ id, title }) => {
          return (
            <Link key={id} to={`/kernel/${id}`}>
              <div
                className={classNames(
                  "rounded-xl font-semibold px-4 py-3 my-2",
                  {
                    "dark:bg-light-gray bg-gray": active === id,
                  }
                )}
              >
                {title}
              </div>
            </Link>
          );
        })
      : ""}
  </div>
);

export default KernelList;
