import { stripEmoji } from "../../content/utils";

const Categories = ({ categories }: { categories: string[] }) =>
  categories.length ? (
    <div className="inline-block whitespace-nowrap dark:bg-neutral-800 bg-neutral-200 rounded-md py-1 px-1 m-px font-bold mr-3">
      {categories.map((category, i) => (
        <div className="inline" key={category}>
          <span className="p-1">{stripEmoji(category)}</span>
          {i < categories.length - 1 && " ⸱ "}
        </div>
      ))}
    </div>
  ) : (
    <div />
  );

export default Categories;
