interface Props {
  visible: boolean;
  onClose: () => void;
  onConfirmed: () => void;
}

const ConfirmDraftFallback = (props: Props) => {
  const { onClose, visible, onConfirmed } = props;
  return visible ? <div
    className="flex z-[999] fixed left-0 top-0 w-full h-full bg-[#00000066] justify-center items-center"
    onKeyDown={(e) => {
      if (e.key === "Escape") {
        onClose();
      }
    }}
  >
    <div className="flex flex-col bg-white text-dark py-6 px-8 rounded-lg">
      <p
        className="text-right text-base"
        onClick={() => onClose()}
      >
        ✕
      </p>
      <p className="text-center text-lg pt-5 pb-10 px-2 font-inter font-medium">
        Would you like to continue with your previous draft?
      </p>
      <div className="flex flex-row font-inter font-semibold text-sm items-center justify-around">
        <p
          className="rounded-lg bg-green px-4 py-2 text-center mr-1 text-white"
          onClick={onConfirmed}
        >
          Yes, continue with draft
        </p>
        <p
          className="rounded-lg border px-4 py-2 ml-1  text-center"
          onClick={() => onClose()}
        >
          No, discard draft changes
        </p>
      </div>
    </div>
  </div> : null
}

export default ConfirmDraftFallback;