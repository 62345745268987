import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ReactNode } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
  useGetCategoriesForUserQuery,
  useGetUserByIdQuery,
} from "../api/troveApi";
import { useAuth } from "../hooks/auth";
import { useAppSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/reducers/auth";

// This file will block the page from displaying anything until after we have
// loaded the auth state (even if that state will end up being "logged out"). It
// also serves to redirect the user based on certain criteria:
// - If they have not validated their email, they get sent to a page telling
// them to do that.
// - If they have not selected their favorite categories it will take them there
// - If they have not uploaded a profile picture or put in a bio it will
// redirect them there.

const WaitForAuth = ({ children }: { children: ReactNode }) => {
  const { state } = useAuth();
  const { userId, loaded } = useAppSelector(selectCurrentUser);
  const { data: profile, error } = useGetUserByIdQuery(userId || skipToken);
  const { error: categoriesError } = useGetCategoriesForUserQuery(
    userId || skipToken
  );
  const location = useLocation();

  if (
    (error as { status: number } | undefined)?.status === 404 &&
    location.pathname !== "/account_error"
  ) {
    return <Redirect to="/account_error" />;
  }

  if (
    loaded &&
    !userId &&
    ["/confirm_email", "complete_your_profile"].includes(location.pathname)
  ) {
    return <Redirect to="/login" />;
  }

  if (
    state.context.userDetails?.user.emailVerified === false &&
    location.pathname !== "/confirm_email"
  ) {
    // This user needs to confirm their email before they can do anything else
    return <Redirect to="/confirm_email" />;
  }

  if (
    state.context.userDetails?.user.emailVerified === false &&
    location.pathname === "/confirm_email"
  ) {
    return <>{children}</>;
  }

  if (
    (categoriesError as { status: number } | undefined)?.status === 404 &&
    location.pathname !== "/complete_your_profile"
  ) {
    // This user needs to set their categories before they can do anything else
    return <Redirect to="/complete_your_profile" />;
  }

  if (
    !categoriesError &&
    profile?.bio &&
    profile?.profilePicture &&
    location.pathname === "/complete_your_profile"
  ) {
    return <Redirect to="/explore" />;
  }

  if (
    profile &&
    (!profile.bio || !profile.profilePicture) &&
    location.pathname !== "/complete_your_profile"
  ) {
    // This user needs to set their bio and profile picture before they can do anything else
    return <Redirect to="/complete_your_profile" />;
  }

  if (state.matches("checkingIfLoggedIn")) {
    // We are still loading auth, don't show anything yet
    return null;
  }

  return <>{children}</>;
};

export default WaitForAuth;
