import { MutableRefObject, ReactNode, useEffect, useRef } from "react";

import "./ModalKernelForm.css";

interface ModalProps {
  children: ReactNode;
  toggleModal: (visible?: boolean) => void;
  visible: boolean;
  className?: string;
}

const Modal = (props: ModalProps) => {
  const frame: MutableRefObject<HTMLDivElement | null> = useRef(null);

  useEffect(() => {
    if (props.visible) {
      frame.current?.focus();
    }
  });

  if (props.visible) {
    return (
      <div
        ref={frame}
        className={`popup`}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            props.toggleModal(false);
          }
        }}
      >
        <div
          className={`modal-custom-kernel text-dark-gray ${props?.className}`}
        >
          <div className="content">
            {props.children}
            <button className="close" onClick={() => props.toggleModal(false)}>
              ✕
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Modal;
