import AvatarLink from "../user/AvatarLink";
import Button from "../Button";
import Categories from "./Categories";
import CollectionCoverImage from "./CollectionCoverImage";
import CollectionSubscribeButton from "./CollectionSubscribeButton";
import RoundButton from "../RoundButton";
import { Collection } from "../../types/collection";
import { ReactComponent as Plus } from "../../assets/plus.svg";
import { User } from "../../types/user";
import { collaborators, isBookmarks } from "../../content/utils";
import { selectCurrentUser } from "../../state/reducers/auth";
import { useAppSelector } from "../../state/hooks";
import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";
import { Modal } from "../../state/xstate/modal";
import { normalizeTag } from "../../content/tags";
import CollectionMenu from "./CollectionMenu";

interface CollectionSummaryProps {
  collection: Collection;
  user: User;
}

const CollectionSummary = ({ collection, user }: CollectionSummaryProps) => {
  const { userId } = useAppSelector(selectCurrentUser);
  const { modal } = useContext(XState);
  const { send } = modal;

  const isBookmarked = isBookmarks(collection);

  return (
    <div className="px-8 sm:overflow-y-auto thin-scrollbar">
      <CollectionCoverImage
        collection={collection}
        className="h-24 w-24 my-8 rounded-xl bg-cover"
        size="small"
      />
      {!isBookmarked && (
        <div className="flex items-center text-xs font-raleway mb-4">
          <AvatarLink userId={user.id} />
        </div>
      )}
      <h1 className="font-raleway font-bold text-2xl">{collection.title}</h1>
      <div className="my-4">
        <Categories categories={collection.categories} />
        {!isBookmarked && collection.public ? (
          <>
            <strong>{collection.nbSubscribers}</strong> Subscribers
          </>
        ) : (
          <strong>Private</strong>
        )}
      </div>
      <div>
        <p>{collection.description}</p>
      </div>
      {!isBookmarked && (
        <>
          <div className="my-4 items-center hidden md:flex">
            {userId === user.id ? (
              <Button className="flex-grow mr-1">Share</Button>
            ) : (
              <CollectionSubscribeButton
                className="flex-grow mr-1"
                collection={collection}
              />
            )}
            <RoundButton className="cursor-pointer">
              <CollectionMenu
                className="w-full h-full p-1"
                collection={collection}
              />
            </RoundButton>
          </div>
          <>
            {/* Theoretically this can't be empty, but I could see a world where
            we exclude the viewing user, in which case it could be, and that
            version works just fine both ways without much extra complexity */}
            {collaborators(collection).length > 0 && (
              <div className="collaborators hidden md:block">
                <h3 className="font-raleway font-bold text-xl mb-4">
                  Collaborators
                </h3>
                {collaborators(collection).map((userId: string) => (
                  <div className="inline-block" key={userId}>
                    <AvatarLink hideName={true} userId={userId} />
                  </div>
                ))}
              </div>
            )}
            <div className="tags hidden md:block">
              <h3 className="font-raleway font-bold text-xl mt-6 mb-2">Tags</h3>
              {collection.hashtags?.map((tag) => (
                <div
                  key={normalizeTag(tag)}
                  className="group relative py-1.5 px-4 m-2 dark:bg-neutral-800 bg-white inline-block rounded-md border border-neutral-300 dark:border-neutral-800 font-bold"
                >
                  <div className="flex items-center">{normalizeTag(tag)}</div>
                </div>
              ))}
              {(userId && collection.collaborators.includes(userId)) ||
                (collection.userId === userId && (
                  <RoundButton
                    className="cursor-pointer"
                    onClick={() =>
                      send({ type: "SHOW_MODAL", modal: Modal.AddTags })
                    }
                  >
                    <Plus className="w-3 h-3" />
                  </RoundButton>
                ))}
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default CollectionSummary;
