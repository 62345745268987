import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ReactComponent as Search } from "../../assets/search.svg";
import compact from "lodash/compact";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import { matchSorter } from "match-sorter";
import { useState } from "react";
import { useGetSubscriptionsForUserQuery } from "../../api/troveApi";
import { stripEmoji } from "../../content/utils";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import { Row } from "./Collection";
import Loading from "../Loading";
import classNames from "classnames";

const Profile = () => {
  const { userId } = useAppSelector(selectCurrentUser);

  let { data: subscriptions, isLoading: subscriptionsAreLoading } =
    useGetSubscriptionsForUserQuery(userId ? null : skipToken);
  const [searchTerm, setSearchTerm] = useState("");
  const allCategories = compact(
    uniq(flatten(subscriptions?.map((c) => c.categories)))
  );

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const toggleCategory = (category: string) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(
        selectedCategories.filter((h) => stripEmoji(h) !== stripEmoji(category))
      );
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  if (selectedCategories.length) {
    subscriptions = (subscriptions || []).filter((c) =>
      c.categories?.some((h) => selectedCategories.includes(h))
    );
  }
  if (searchTerm.length) {
    subscriptions = matchSorter(subscriptions || [], searchTerm, {
      keys: ["title", "description"],
    });
  }

  if (subscriptionsAreLoading || !subscriptions) {
    return <Loading />;
  }

  return (
    <div
      className="collections sm:overflow-y-auto thin-scrollbar px-4 md:pr-8 md:pl-0"
      style={{ maxWidth: 1200 }}
    >
      <div className="flex flex-col mb-12">
        <div className="flex items-center relative mb-8 p-1">
          <Search className="absolute left-4 fill-dark-gray stroke-dark-gray" />
          <input
            className="input !pl-12 shrink-none"
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {Boolean(allCategories.length) && (
          <div className="mb-8">
            {allCategories.map((category) => {
              const selected = selectedCategories.includes(category);
              return (
                <div
                  key={stripEmoji(category)}
                  onClick={() => toggleCategory(category)}
                  className={classNames(
                    "inline-flex items-center text-sm py-1.5 px-3 m-1  inline-block rounded-md border font-bold cursor-pointer",
                    {
                      "bg-white border-neutral-300 dark:bg-neutral-800 dark:border-neutral-600":
                        !selected,
                      "bg-black text-white border-black": selected,
                    }
                  )}
                >
                  {stripEmoji(category)}
                </div>
              );
            })}
          </div>
        )}
        <div>
          {subscriptions!.map((subscription) => (
            <Row
              key={subscription.id}
              collection={{ ...subscription, userId: "" }}
              user={subscription.user}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
