import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";
import EnterContent from "./EnterContent";
import PickCategories from "./PickCategories";

const CollectionWizard = () => {
  const { collectionWizard } = useContext(XState);

  return (
    <>
      <PickCategories service={collectionWizard} />
      <EnterContent service={collectionWizard} />
    </>
  );
};

export default CollectionWizard;
