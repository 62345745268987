// This is only specific to going from subscriptions -> collections right now,
// but there's no reason it couldn't get more interesting someday, so I've tried
// to keep it pretty generic

const labelForPath = (path: string): string | undefined => {
  if (path.endsWith("subscribed")) {
    return "Subscribed";
  }
};

export const backToState = (location: {
  pathname: string;
}): { backToLink: string; backToLabel: string } | undefined => {
  const label = labelForPath(location.pathname);
  if (label) {
    return {
      backToLink: location.pathname,
      backToLabel: label,
    };
  }
};
