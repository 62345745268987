import classNames from "classnames";
import uniq from "lodash/uniq";
import Button from "../Button";
import { useActor } from "@xstate/react";
import { Interpreter } from "xstate";
import {
  CollectionWizardMachineContext,
  CollectionWizardMachineEvent,
} from "../../state/xstate/collectionWizard";

export const CATEGORIES = [
  { emoji: "⛺", title: "Outdoors" },
  { emoji: "✂️", title: "DIY & Crafts" },
  { emoji: "✈️", title: "Travel" },
  { emoji: "✍🏻", title: "Writing" },
  { emoji: "🌱", title: "Gardening" },
  { emoji: "🍳", title: "Recipes" },
  { emoji: "🍽️", title: "Restaurants" },
  { emoji: "🍿", title: "TV & Film" },
  { emoji: "🎙️", title: "Podcasts" },
  { emoji: "🎨", title: "Arts" },
  { emoji: "🎹", title: "Music" },
  { emoji: "🏈", title: "Sports & Games" },
  { emoji: "🏔️", title: "Sustainability" },
  { emoji: "🏥", title: "Health" },
  { emoji: "🐶", title: "Animals" },
  { emoji: "👚", title: "Style" },
  { emoji: "👪", title: "Parenting" },
  { emoji: "💌", title: "Social Causes" },
  { emoji: "💵", title: "Finance" },
  { emoji: "💼", title: "Business" },
  { emoji: "📚", title: "Reading" },
  { emoji: "🔬", title: "Science" },
  { emoji: "🔷", title: "Other" },
  { emoji: "🖌️", title: "Design" },
  { emoji: "😂", title: "Humor" },
  { emoji: "🛀", title: "Wellness" },
  { emoji: "🛰️", title: "Technology" },
  { emoji: "🥊", title: "Fitness" },
  { emoji: "🥘", title: "Food & Drink" },
];

export const fullCategory = ({
  emoji,
  title,
}: {
  emoji: string;
  title: string;
}): string => `${emoji} ${title}`;

const PickCategories = ({
  service,
}: {
  service: Interpreter<
    CollectionWizardMachineContext,
    any,
    CollectionWizardMachineEvent
  >;
}) => {
  const [state, send] = useActor(service);

  const categories = state.context.categories;

  if (!state.matches("pickingCategories")) {
    return null;
  }
  return (
    <div
      className="p-8 pb-2 text-center"
      style={{ maxWidth: "100%", width: 800 }}
    >
      <h2 className="font-raleway text-left text-xl mb-12">
        {state.context.id ? "Edit" : "Create"} Collection
      </h2>
      <p className="text-lg mb-8">
        Choose up to <strong>2 categories</strong> that best describe this
        collection
      </p>
      <div className="mb-12">
        {state.context.errorMessage && (
          <div className="absolute top-8 left-0 w-full ">
            <div className="inline-block bg-red-600 text-white p-4 rounded-lg text-lg">
              {state.context.errorMessage}
            </div>
          </div>
        )}
        {CATEGORIES.map(({ emoji, title }) => {
          const full = fullCategory({ emoji, title });
          const selected = categories.includes(full);
          return (
            <div
              className={classNames(
                "text-center cursor-pointer rounded-lg bg-white border-gray border font-bold inline-block m-1.5 px-4 py-3",
                { "text-white bg-green": selected }
              )}
              key={title}
              onClick={() => {
                if (selected) {
                  send({
                    type: "CHANGE_CATEGORIES",
                    info: { categories: categories.filter((c) => c !== full) },
                  });
                } else {
                  send({
                    type: "CHANGE_CATEGORIES",
                    info: {
                      categories: uniq([...categories, full]).slice(0, 2),
                    },
                  });
                }
              }}
            >
              <span className="pr-2">{emoji}</span>
              {title}
            </div>
          );
        })}
      </div>
      <div className="text-right">
        <div className="absolute translate-x-1/2 right-1/2 flex-grow bottom-6 flex">
          <div className="h-2 w-2 m-1 rounded-full bg-green" />
          <div
            className="cursor-pointer h-2 w-2 m-1 rounded-full bg-gray"
            onClick={() => send({ type: "VALIDATE_CATEGORIES" })}
          />
        </div>
        <div>
          <Button
            onClick={() => send("CANCEL")}
            className="text-center cursor-pointer rounded-lg bg-white border-gray border font-bold inline-block m-1.5 px-4 py-2"
          >
            Cancel
          </Button>
          <Button
            className="bg-green text-white text-center cursor-pointer rounded-lg font-bold inline-block m-1.5 px-4 py-2"
            onClick={() => send({ type: "VALIDATE_CATEGORIES" })}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PickCategories;
