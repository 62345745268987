import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as BookmarkFilled } from "../../assets/bookmark_filled.svg";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { ReactComponent as LeftChevron } from "../../assets/left_chevron.svg";

import { Collection } from "../../types/collection";
import { Kernel } from "../../types/kernel";
import { User } from "../../types/user";

import "./KernelContent.css";
import Loading from "../Loading";
import AvatarLink from "../user/AvatarLink";
import {
  extractSrcScriptFromHtml,
  reformatBrokenHTMLElements,
} from "../../content/parsers";
import KernelMenu from "./KernelMenu";
import { isBookmarks } from "../../content/utils";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";

const KernelContent = ({
  collection,
  kernel,
  user,
}: {
  collection?: Collection;
  kernel?: Kernel;
  user?: User;
}) => {
  const { userId: currentUserId } = useAppSelector(selectCurrentUser);
  const [onLoaded, setLoaded] = useState<boolean>(false);
  const [urls, setUrls] = useState<string[]>([]);

  useEffect(() => {
    if (kernel) {
      const urls = extractSrcScriptFromHtml(kernel.content);
      setUrls(urls);
    }
  }, [kernel]);

  useEffect(() => {
    if (onLoaded && collection && kernel && user) {
      (window as any).iframely?.load();
      (window as any).instgrm?.Embeds?.process();
    }
  }, [onLoaded, collection, kernel, user]);

  useEffect(() => {
    if (urls.length > 0) {
      const scriptArr: HTMLScriptElement[] = [];
      urls.forEach((url) => {
        const script = document.createElement("script");
        script.src = url;
        script.async = true;
        script.onload = () => setLoaded(true);
        script.defer = true;
        document.body.appendChild(script);
        scriptArr.push(script);
      });
      return () => {
        scriptArr.forEach((script) => {
          document.body.removeChild(script);
        });
      };
    }
  }, [urls, collection, kernel, user]);

  if (collection && kernel && user) {
    return (
      <div
        className="flex flex-col mx-4 sm:ml-0 md:mr-20 sm:overflow-y-auto thin-scrollbar max-w-full"
        style={{ maxWidth: 1200 }}
      >
        <div className="mb-12 mt-2">
          <Link
            to={`/collection/${isBookmarks(collection) ? "" : collection.id}`}
          >
            <LeftChevron className="inline dark:fill-gold mr-4" />
            &nbsp; <span className="dark:text-gold">{collection.title}</span>
          </Link>
        </div>
        <div>
          <h1 className="text-4xl font-bold font-raleway py-1">
            {kernel.title}
          </h1>
          <div className="flex items-center justify-between mt-4 mb-8 mr-4">
            <AvatarLink userId={user.id} className="h-6 w-6" />
            <div className="flex items-center space-x-4">
              {user.id === currentUserId && (
                <Link to={`/kernel/${kernel.id}/edit`} className="mr-4">
                  <Pencil
                    className="stroke-transparent fill-black dark:fill-white"
                    width="24"
                    height="24"
                  />
                </Link>
              )}
              <KernelMenu kernel={kernel} />
            </div>
          </div>
          <div
            className="content-container pb-8 ql-editor"
            dangerouslySetInnerHTML={{
              __html: reformatBrokenHTMLElements(kernel.content).body.outerHTML,
            }}
          />
          {kernel.savedFrom && (
            <>
              <div className="flex items-center border-t border-neutral-300 dark:border-white">
                <BookmarkFilled className="dark:fill-gold stroke-transparent m-4" />
                Content was originally branched from {kernel.savedFrom.username}
                's {kernel.savedFrom.collectionTitle}
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default KernelContent;
