import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";

const EmailExpired = () => {
  const { modal } = useContext(XState);

  return (
    <div
      className="flex flex-col rounded-xl text-center pt-8 pb-4 px-4"
      style={{ width: 600, maxWidth: "100%" }}
    >
      <h1 className="text-2xl text-dark-gray mt-8">Email Link Expired</h1>
      <div className="text-xl text-dark-gray bold font-bold" style={{ marginTop: "100px" }}>
        Please close and click ‘resend’ email
      </div>
      <div className="text-right" style={{ marginTop: "40px" }}>
        <button
          className="bg-green text-white text-center text-xs cursor-pointer rounded-lg font-bold inline-block m-1.5 px-4 py-2"
          onClick={() => { modal.send("HIDE_MODAL") }}
        >
          CLOSE
        </button>
      </div>
    </div>
  );
}

export default EmailExpired;