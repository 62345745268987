import { useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import {
  generateId,
  signIn,
  signInWithApple,
  signInWithGoogle,
  signInWithLinkedIn,
} from "../api/firebase";
import { useAuth } from "../hooks/auth";
import { selectCurrentUser } from "../state/reducers/auth";
import { useAppSelector } from "../state/hooks";
import { ReactComponent as GoogleIcon } from "../assets/google.svg";
import { ReactComponent as AppleIcon } from "../assets/apple.svg";
import { ReactComponent as LinkedInIcon } from "../assets/linkedin.svg";
import _AppleSignin from "react-apple-signin-auth";
import { sha256 } from "js-sha256";
import LoginLayout from "./LoginLayout";
import branch from "branch-sdk";
const AppleSignin = _AppleSignin as any;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation<any>();
  const [signInLinkedIn, setSignInLinkedIn] = useState<boolean>(
    location.state?.signInLinkedIn ?? true
  );

  useEffect(() => {
    branch.data((err: any, data: any) => {
      console.log("signInWithLinkedIn", signInLinkedIn);
      if (data?.data_parsed!.token && signInLinkedIn) {
        console.log("======signInWithLinkedIn======");
        setSignInLinkedIn(false);
        signInWithLinkedIn(data?.data_parsed?.token);
      }
    });
  }, [signInLinkedIn]);

  useAuth();

  const { userId } = useAppSelector(selectCurrentUser);
  const [nonce] = useState(generateId());

  if (userId) {
    return <Redirect to="/" />;
  }

  return (
    <LoginLayout>
      <div className="relative flex flex-col m-auto md:ml-40 w-full md:w-80 h-full items-center justify-center z-10 px-4">
        <h1 className="font-raleway text-2xl font-bold">Login to Trove</h1>
        <div className="py-8 flex items-center">
          <button
            onClick={signInWithGoogle}
            className="bg-white fill-black rounded-full w-8 h-8 mx-2"
          >
            <GoogleIcon
              className="fill-black"
              style={{ width: 32, height: 32 }}
            />
          </button>
          <a
            href={`${process.env.REACT_APP_TROVE_API_BASE_URL}/auth/linkedin`}
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-white fill-black rounded-full w-8 h-8 mx-2">
              <LinkedInIcon style={{ width: 12, height: 12, margin: "auto" }} />
            </button>
          </a>
          <AppleSignin
            authOptions={{
              clientId: "net.trovecollective.android-web",
              redirectURI: window.location.href,
              scope: "email name",
              nonce: sha256(nonce),
              usePopup: true,
            }}
            onSuccess={(response: { authorization: { id_token: string } }) => {
              signInWithApple(response.authorization.id_token, nonce);
            }}
            render={(props: any) => (
              <button
                className="bg-white fill-black rounded-full w-8 h-8 mx-2"
                {...props}
              >
                <AppleIcon style={{ width: 24, height: 24, margin: "auto" }} />
              </button>
            )}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            signIn(email, password);
          }}
        >
          <input
            className="w-full rounded-lg p-4 my-1 text-black input"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            className="w-full rounded-lg p-4 my-1 text-black input"
            defaultValue={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
          />
          <button className="p-4 mt-8 w-full bg-gold text-black rounded-xl">
            Login
          </button>
        </form>
        <div className="m-8">
          <Link to="/signup">Create an Account</Link>
        </div>
      </div>
    </LoginLayout>
  );
};

export default Login;
