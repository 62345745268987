import { useEffect } from "react";
import { signOut } from "../api/firebase";
import LoginLayout from "./LoginLayout";
import { ReactComponent as LogoLight } from "../assets/logo_light.svg";
import ModalWrapper from "./modal/ModalWrapper";
import { Link } from "react-router-dom";

const AccountError = () => {
  useEffect(() => {
    console.log("SIGNING OUT");
    signOut();
  }, []);

  return (
    <LoginLayout>
      <ModalWrapper showing={true} onClose={() => {}}>
        <div
          className="flex flex-col rounded-xl px-8 py-24"
          style={{ width: 800, maxWidth: "100%" }}
        >
          <div className="m-auto bg-white p-8 rounded-full">
            <LogoLight style={{ width: 75 }} />
          </div>
          <p
            className="prose text-center pt-8 pb-20 mx-auto text-lg font-bold"
            style={{ maxWidth: 550 }}
          >
            Something seems to have gone a bit awry. We apologize! Please
            re-login and that will hopefully get you back on track.
          </p>
          <div className="flex items-center justify-center">
            <Link to="/login">
              <button className="bg-green text-white px-4 py-2 rounded-lg">
                Login
              </button>
            </Link>
          </div>
        </div>
      </ModalWrapper>
    </LoginLayout>
  );
};

export default AccountError;
