import { ReactNode, useRef, useState } from "react";
import heic2any from "heic2any";
import { arrayBufferToBase64 } from "../../content/images";
import LoadingSpinner from "../LoadingSpinner";
import {
  resizeFile
} from "../../content/utils";

const ChangeAvatar = ({
  children,
  onChange,
}: {
  children: ReactNode;
  onChange: ({
    data,
    mime,
    dataURL,
  }: {
    data: string;
    mime: string;
    dataURL: string;
  }) => void;
  svgClassName?: string | undefined;
}) => {
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <div className="max-w-fit m-auto cursor-pointer">
      {loadingUpload && <LoadingSpinner />}
      <div onClick={() => fileInput.current?.click()}>{children}</div>
      <input
        ref={fileInput}
        accept=".jpeg,.png,.tiff,.heic,.jpg,.webp"
        className="hidden"
        type="file"
        onChange={async (e) => {
          try {
            setLoadingUpload(true);
            const file = e.target.files?.[0];
            if (!file) {
              return;
            }
            let fileImg: any = file;
            let typeImgSupport: string = '';

            if (file.type === "") {
              typeImgSupport = 'image/' + fileImg.name.split('.').slice(1);
            }
            if ((file.type || typeImgSupport) === "image/heic") {
              fileImg = await heic2any({
                blob: file,
                toType: "image/png",
              });
              await resizeFile(fileImg, 'PNG', 'blob')
                .then((res) => {
                  fileImg = res
                })
                .catch((err) => {
                  setLoadingUpload(false);
                });
            }

            const arrayBuffer = await fileImg.arrayBuffer();
            const base64 = arrayBufferToBase64(arrayBuffer);
            const fileType = fileImg.type || typeImgSupport;
            const dataURL = `data:${fileType};base64,${base64}`;
            setLoadingUpload(false);
            onChange({ data: base64, mime: fileType, dataURL: dataURL });
          } catch (error) {
            setLoadingUpload(false);
          }
        }}
      />
    </div>
  );
};
export default ChangeAvatar;


