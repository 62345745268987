import { useEffect, useState } from "react";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Link, Redirect } from "react-router-dom";
import { ReactComponent as LeftChevron } from "../assets/left_chevron.svg";
import { ReactComponent as Search } from "../assets/search.svg";

import CollectionSummary from "./collections/CollectionSummary";
import Kernels from "./Kernels";
import { useAppDispatch, useAppSelector } from "../state/hooks";

import {
  BOOKMARKED_CONTENT,
  bookmarksCollection,
  spotifyURLsWithCategory,
  stripEmoji,
} from "../content/utils";
import { resourceRequested } from "../state/reducers/spotify";
import { useGetAllKernelsQuery, useGetUserPreviewQuery } from "../api/troveApi";
import Loading from "./Loading";
import { selectCurrentUser } from "../state/reducers/auth";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { matchSorter } from "match-sorter";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";
import compact from "lodash/compact";
import classNames from "classnames";

const BookmarkedContentPage = () => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const spotify: SpotifyState = useAppSelector((state) => state.spotify);
  const { userId, loaded } = useAppSelector(selectCurrentUser);
  const { data: kernels } = useGetAllKernelsQuery(userId ? null : skipToken);
  let bookmarkedKernels = kernels?.filter((k) => !k.collectionId);
  const [searchTerm, setSearchTerm] = useState("");

  const { data: user } = useGetUserPreviewQuery(userId ?? skipToken);

  useEffect(() => {
    bookmarkedKernels?.forEach((kernel) => {
      const spotifyURL = spotifyURLsWithCategory(kernel.content);
      if (spotifyURL) {
        dispatch(resourceRequested(spotifyURL));
      }
    });
  }, [dispatch, bookmarkedKernels]);

  const allCategories = compact(
    uniq(flatten(bookmarkedKernels?.map((k) => k.categories)))
  ).map(stripEmoji);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const toggleCategory = (category: string) => {
    category = stripEmoji(category);
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  if (selectedCategories.length) {
    bookmarkedKernels = (bookmarkedKernels || []).filter((k) =>
      k.categories?.map(stripEmoji).some((c) => selectedCategories.includes(c))
    );
  }

  if (loaded && !userId) {
    return <Redirect to="/login" />;
  }

  const collection = bookmarksCollection({
    kernelCount: bookmarkedKernels?.length,
  });

  if (!user || !bookmarkedKernels) {
    return <Loading />;
  } else {
    return (
      <div className="with-sidebar">
        <CollectionSummary collection={collection} user={user} />
        <div
          style={{ maxWidth: 1200 }}
          className="flex flex-col pt-8 overflow-y-auto thin-scrollbar px-4 md:pr-8 md:pl-0"
        >
          <Link to={`/profile/${user.username}`}>
            <LeftChevron className="inline mr-4" />
            &nbsp; Back to <strong>Collections</strong>
          </Link>
          <div className="my-12 flex flex-col items-center">
            <div className="w-full flex items-center relative p-1">
              <Search className="absolute left-6 stroke-dark-gray" />
              <input
                className="input !pl-12"
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {Boolean(allCategories.length) && (
              <div className="mt-8 w-full">
                {allCategories.map((category) => {
                  category = stripEmoji(category);
                  const selected = selectedCategories.includes(category);
                  return (
                    <div
                      key={category}
                      onClick={() => toggleCategory(category)}
                      className={classNames(
                        "inline-flex items-center text-sm py-1.5 px-3 m-1  inline-block rounded-md border font-bold cursor-pointer",
                        {
                          "bg-white border-neutral-300 dark:bg-neutral-800 dark:border-neutral-600":
                            !selected,
                          "bg-black text-white border-black": selected,
                        }
                      )}
                    >
                      {category}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <Kernels
            fallbackImage={BOOKMARKED_CONTENT}
            kernels={matchSorter(bookmarkedKernels, searchTerm, {
              keys: ["title", "description", "content"],
            })}
            spotify={spotify}
          />
        </div>
      </div>
    );
  }
};

export default BookmarkedContentPage;
