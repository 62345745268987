import { ReactComponent as Bookmark } from "../assets/bookmark.svg";
import { ReactComponent as BookmarkFilled } from "../assets/bookmark_filled.svg";
import { QueryStatus, skipToken } from "@reduxjs/toolkit/dist/query";
import { compact, find, flatten, some, sortBy } from "lodash";
import { useContext, useState } from "react";
import {
  useDeleteKernelMutation,
  useGetAllKernelsQuery,
  useGetCollectionsForUserQuery,
  useSaveKernelMutation,
} from "../api/troveApi";
import { bookmarksCollection } from "../content/utils";
import { useAppSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/reducers/auth";
import Menu, { MenuState } from "./Menu";
import { Kernel } from "../types/kernel";
import { XState } from "../state/xstate/XStateContext";
import { Modal } from "../state/xstate/modal";
import classNames from "classnames";

const BookmarkButton = ({ kernel }: { kernel: Kernel }) => {
  const { modal } = useContext(XState);
  const [menu, setMenu] = useState<MenuState>({
    open: false,
    x: undefined,
    y: undefined,
  });
  const { userId: currentUserId } = useAppSelector(selectCurrentUser);
  const { data: allKernels, isFetching: kernelsLoading } =
    useGetAllKernelsQuery(currentUserId ? null : skipToken);
  const bookmarked = some(
    allKernels,
    (k) => k.savedFrom?.kernelId === kernel.id
  );
  const { data: collectionsFromApi, isFetching: collectionsLoading } =
    useGetCollectionsForUserQuery(
      currentUserId ? { id: currentUserId, currentUserId } : skipToken
    );
  const collections = compact(
    flatten([bookmarksCollection({}), collectionsFromApi])
  );
  const [saveKernel, { status: saveStatus }] = useSaveKernelMutation();
  const [deleteKernel, { status: deleteStatus }] = useDeleteKernelMutation();

  const loading =
    kernelsLoading ||
    collectionsLoading ||
    saveStatus === QueryStatus.pending ||
    deleteStatus === QueryStatus.pending;

  if (!currentUserId) {
    return (
      <Bookmark
        className="dark:fill-white dark:stroke-white cursor-pointer"
        onClick={() => modal.send({ type: "SHOW_MODAL", modal: Modal.Welcome })}
      />
    );
  }

  if (collections.map((c) => c.id).includes(kernel.collectionId)) {
    // This collection belongs to the user, so they can't save it
    return null;
  }

  return (
    <>
      {bookmarked ? (
        <BookmarkFilled
          className="dark:fill-gold dark:stroke-gold cursor-pointer"
          onClick={(e) => setMenu({ open: !menu.open, x: e.pageX, y: e.pageY })}
        />
      ) : (
        <Bookmark
          className="dark:fill-white dark:stroke-white cursor-pointer"
          onClick={(e) => setMenu({ open: !menu.open, x: e.pageX, y: e.pageY })}
        />
      )}
      {menu.open ? (
        <Menu
          className="font-bold"
          x={menu.x}
          y={menu.y}
          close={() => setMenu({ open: false, x: undefined, y: undefined })}
          items={sortBy(collections, (c) => c.title).map((c) => ({
            children: (
              <div
                className={classNames(
                  "flex items-center cursor-pointer justify-between",
                  { "opacity-20": loading }
                )}
              >
                <div className="mr-4">{c.title}</div>
                <input
                  checked={some(
                    allKernels,
                    (k) =>
                      k.collectionId === c.id &&
                      k.savedFrom?.kernelId === kernel.id
                  )}
                  className="mr-2"
                  onChange={() => {}}
                  type="checkbox"
                />
              </div>
            ),
            onClick: () => {
              if (loading) {
                return;
              }
              const bookmarkedKernel = find(
                allKernels,
                (k) =>
                  k.collectionId === c.id && k.savedFrom?.kernelId === kernel.id
              );
              if (bookmarkedKernel) {
                deleteKernel({ kernelId: bookmarkedKernel.id });
              } else {
                saveKernel({
                  kernelId: kernel.id,
                  collectionId: c.id,
                });
              }
            },
          }))}
        />
      ) : null}
    </>
  );
};

export default BookmarkButton;
