import { uniqBy } from "lodash";
import { ReactNode } from "react";
import { actions, assign, createMachine } from "xstate";
const { send } = actions;

const BANNER_TIMEOUT = 5000;

export enum BannerTheme {
  Error,
  Information,
  Success,
}

export type Banner = {
  id: string;
  content: ReactNode;
  theme: BannerTheme;
};

interface ModalMachineContext {
  banners: Banner[];
}

export type ModalMachineEvent =
  | { type: "ADD_BANNER"; banner: Banner; timeout?: number }
  | { type: "DISMISS_BANNER"; bannerId: string }
  | { type: "DISMISS_ALL" };

const bannerMachine = createMachine<ModalMachineContext, ModalMachineEvent>({
  id: "modal",
  initial: "idle",
  states: {
    idle: {
      on: {
        ADD_BANNER: {
          actions: [
            assign({
              banners: (context, event) =>
                uniqBy([event.banner, ...context.banners], (b) => b.id),
            }),
            send(
              (_context, event) => ({
                type: "DISMISS_BANNER",
                bannerId: event.banner.id,
              }),
              {
                delay: (_context, event) => event.timeout || BANNER_TIMEOUT,
              }
            ),
          ],
        },
        DISMISS_BANNER: {
          actions: assign({
            banners: (context, event) =>
              [...context.banners].filter((b) => b.id !== event.bannerId),
          }),
        },
        DISMISS_ALL: { actions: assign({ banners: (_context) => [] }) },
      },
    },
  },
});

export default bannerMachine;
