import Books from "../../assets/Books.png";
import Camera from "../../assets/Camera.png";
import Compass from "../../assets/Compass.png";
import Globe from "../../assets/Globe.png";
import Link from "../../assets/Link.png";
import Microphone from "../../assets/Microphone.png";
import Music from "../../assets/Music.png";
import Play from "../../assets/Play.png";
import Text from "../../assets/Text.png";

import Books_active from "../../assets/Books_active.png";
import Camera_active from "../../assets/Camera_active.png";
import Compass_active from "../../assets/Compass_active.png";
import Globe_active from "../../assets/Globe_active.png";
import Link_active from "../../assets/Link_active.png";
import Microphone_active from "../../assets/Microphone_active.png";
import Music_active from "../../assets/Music_active.png";
import Play_active from "../../assets/Play_active.png";
import Text_active from "../../assets/Text_active.png";
import save_kernel from "../../assets/save-kernel.png";
import FAB from "../../assets/FAB.png";

const arrayBtnEditor = [
  {
    type: "Text",
    image: Text,
    image_active: Text_active,
    text: "Text",
    class: "img_text",
  },
  {
    type: "Image",
    image: Camera,
    image_active: Camera_active,
    text: "Image/Video",
    class: "img_image",
  },
  {
    type: "Link",
    image: Link,
    image_active: Link_active,
    text: "Link",
    class: "img_link",
  },
  {
    type: "Location",
    image: Compass,
    image_active: Compass_active,
    text: "Location",
    class: "img_location",
  },
  {
    type: "Book",
    image: Books,
    image_active: Books_active,
    text: "Book",
    class: "img_book",
  },
  {
    type: "Music",
    image: Music,
    image_active: Music_active,
    text: "Music",
    class: "img_music",
  },
  {
    type: "Podcast",
    image: Microphone,
    image_active: Microphone_active,
    text: "Podcast",
    class: "img_podcast",
  },
  {
    type: "TV/Movie",
    image: Globe,
    image_active: Globe_active,
    text: "TV / Movie",
    class: "img_movie",
  },
  {
    type: "YouTube",
    image: Play,
    image_active: Play_active,
    text: "YouTube",
    class: "img_youtube",
  },
];

export { arrayBtnEditor, save_kernel, FAB };
