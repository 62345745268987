import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";

const ModalExplore = () => {
  const { modal } = useContext(XState);
  return (
    <div
      className="flex flex-col rounded-xl px-8 py-6 !text-left"
      style={{ width: 600, maxWidth: "100%" }}
    >
      <h1 className="font-raleway font-bold text-2xl">Hello there!</h1>
      <div>
        <div className="text-5xl text-left my-3">👋</div>
        <div className="mb-4 text-left">We are so glad you are enjoying the Trove experience!</div>
        <div className="text-left">In order to savor this content - and to build your own Trove</div>
        <div className="text-left">- download our app & create an account using this code: </div>
      </div>
      <div className="flex justify-center h-11 mt-7">
        <button
          onClick={() => modal.send("HIDE_MODAL")}
          className="px-4 py-3 bg-black text-white rounded font-bold text-xs"
        >
          <a href="https://www.trovecollective.co/join" rel="noreferrer" target="_blank">
            Join Waitlist
          </a>
        </button>
      </div>
    </div>
  );
};

export default ModalExplore;
