import { useSelector } from "@xstate/react";
import { useContext } from "react";
import { Modal } from "../../state/xstate/modal";
import { XState } from "../../state/xstate/XStateContext";
import CollectionWizard from "../newCollection/CollectionWizard";
import Welcome from "./Welcome";
import ModalWrapper from "./ModalWrapper";
import EditProfile from "../editProfile/EditProfile";
import Settings from "./Settings";
import ReportAbuse from "./ReportAbuse";
import InviteFriends from "./InviteFriends";
import AddTags from "./AddTags";
import Banners from "../Banners";
import EmailExpired from "./EmailExpired";
import ModalExplore from "./ModalExplore";
import AddingContent from "./AddingContent";

const ModalComponent = () => {
  const { modal } = useContext(XState);
  const currentModal = useSelector(modal, (state) => state.context.modal);

  return (
    <>
      <ModalWrapper
        showing={currentModal !== Modal.None}
        onClose={() => modal.send("HIDE_MODAL")}
        modalName={currentModal}
      >
        {currentModal === Modal.AddingContent && <AddingContent />}
        {currentModal === Modal.AddTags && <AddTags />}
        {currentModal === Modal.EditProfile && <EditProfile />}
        {currentModal === Modal.InviteFriends && <InviteFriends />}
        {currentModal === Modal.NewCollection && <CollectionWizard />}
        {currentModal === Modal.ReportAbuse && <ReportAbuse />}
        {currentModal === Modal.Settings && <Settings />}
        {currentModal === Modal.Welcome && <Welcome />}
        {currentModal === Modal.EmailExpired && <EmailExpired />}
        {currentModal === Modal.ModalExplore && <ModalExplore />}
      </ModalWrapper>
      {currentModal === Modal.None && <Banners />}
    </>
  );
};

export default ModalComponent;
