import { ReactComponent as LogoDark } from "../assets/logo_dark.svg";
import { ReactComponent as LogoLight } from "../assets/logo_light.svg";
import { ReactComponent as BrandNameLight } from "../assets/brand_name_light.svg";
import { ReactComponent as BrandNameDark } from "../assets/brand_name_dark.svg";
import { Theme, ThemeContext } from "./ThemePicker";
import { useContext } from "react";

const Logo = () => {
  const { theme } = useContext(ThemeContext);
  return theme === Theme.Light ? (
    <>
      <LogoLight style={{ height: 24, width: 24 }} />
      <BrandNameLight className="pl-2" style={{ height: 18, width: "auto" }} />
    </>
  ) : (
    <>
      <LogoDark style={{ height: 24, width: 24 }} />
      <BrandNameDark
        className="pl-2 stroke-transparent"
        style={{ height: 18, width: "auto" }}
      />
    </>
  );
};

export default Logo;
