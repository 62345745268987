import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import { ReactComponent as Ellipse } from "../../assets/ellipse.svg";
import { useContext, useState } from "react";
import Menu, { MenuState } from "../Menu";
import { useDeleteCollectionMutation } from "../../api/troveApi";
import { Collection } from "../../types/collection";
import { isBookmarks } from "../../content/utils";
import { useHistory, useLocation } from "react-router-dom";
import EditCollectionWizard from "./EditCollectionPage";
import classNames from "classnames";
import { Modal } from "../../state/xstate/modal";
import { XState } from "../../state/xstate/XStateContext";

const CollectionMenu = ({
  className,
  collection,
}: {
  className?: string;
  collection: Collection;
}) => {
  const location = useLocation();
  const history = useHistory();
  const { userId: currentUserId } = useAppSelector(selectCurrentUser);
  const [menu, setMenu] = useState<MenuState>({
    open: false,
    x: undefined,
    y: undefined,
  });
  const { modal } = useContext(XState);
  const { send } = modal;

  const [deleteCollection] = useDeleteCollectionMutation();
  const [editing, setEditing] = useState(false);

  if (isBookmarks(collection)) {
    return null;
  }

  return (
    <>
      <div>
        <>
          <Ellipse
            className={classNames("dark:fill-white cursor-pointer", className)}
            onClick={(e) => {
              setMenu(
                menu.open
                  ? { x: undefined, y: undefined, open: false }
                  : { x: e.clientX, y: e.clientY, open: true }
              );
            }}
          />
          {menu.open && (
            <Menu
              className="w-56 font-bold"
              x={menu.x}
              y={menu.y}
              close={() => setMenu({ open: false, x: undefined, y: undefined })}
              items={
                collection.userId === currentUserId
                  ? [
                      {
                        children: <>Edit Collection</>,
                        onClick: () => {
                          setEditing(true);
                          setMenu({
                            open: false,
                            x: undefined,
                            y: undefined,
                          });
                        },
                      },
                      {
                        children: <span>Delete collection</span>,
                        onClick: async () => {
                          if (
                            window.confirm(
                              `Are you sure you want to delete ${collection.title}?`
                            )
                          ) {
                            await deleteCollection({
                              id: collection.id,
                            }).unwrap();
                            if (
                              location.pathname ===
                              `/collection/${collection.id}`
                            ) {
                              history.push(`/`);
                            }
                          }
                        },
                      },
                    ]
                  : [
                      {
                        children: <>Report Collection</>,
                        onClick: () =>
                          send({
                            type: "SHOW_MODAL",
                            modal: Modal.ReportAbuse,
                          }),
                      },
                    ]
              }
            />
          )}
        </>
      </div>
      {editing && (
        <EditCollectionWizard
          collection={collection}
          onDone={() => setEditing(false)}
        />
      )}
    </>
  );
};

export default CollectionMenu;
