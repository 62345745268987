/* eslint-disable */
import { useContext, useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import Quill, { Delta, DeltaOperation } from "quill";
import "./KernelForm.css";
import { Link, Redirect, useHistory, useParams } from "react-router-dom";
import useWindowDimensions from "../../utils/WindowDimensions";
import Modal from "../modal/ModalKernelForm";
import * as kernelFormApi from "../../api/kernelFormApi";
import { arrayBtnEditor, save_kernel, FAB } from "./data";
import {
  IframeYoutube,
  IframeLocation,
  IframeGBook,
  IframeMovie,
  IframeMusic,
  IframePodcast,
  oEmbedIframely,
  InsertImage,
  LOCATION_ICON,
  InsertVideo,
  oEmbed,
  validateYouTubeUrl,
} from "./customIframe";
import $ from "jquery";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import Loading from "./../Loading";
import AlertSubscribers from "./AlertSubscribers";
import { CacheContent, PostKernel } from "../../types/kernel";
import {
  useCreateKernelMutation,
  useShareKernelMutation,
  useUpdateKernelMutation,
  useGetLocationsMutation,
  useGetKernelQuery,
  useGetCollectionQuery,
  useGetBookmarkedContentQuery,
  useGetUrlUploadVideoMutation,
} from "../../api/troveApi";
import { BannerTheme } from "../../state/xstate/banner";
import { XState } from "../../state/xstate/XStateContext";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import heic2any from "heic2any";
import LoadingSpinner from "../LoadingSpinner";
import { Buffer } from "buffer";
import ProgressBar from '../ProgressBar';
import { validateSpotify, validateInstagram, validateTikTok, validateTwitter, validateFacebook, validURL, validateContentString } from "../../utils/validation";
import { Theme, ThemeContext } from "../ThemePicker";
import { resizeFile } from "../../content/utils";
import { debounce } from "lodash";
import { cachingContent, removeCache } from "../../state/reducers/content";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import ConfirmDraftFallback from "../modal/ConfirmDraftFallback";

export enum DataTypes {
  Book,
  Image,
  Link,
  Location,
  Music,
  Podcast,
  TvMovie,
  YouTube,
}
export interface DataHtml {
  type: DataTypes;
  source: string;
}

const BlockEmbed = Quill.import("blots/block/embed");
const ITEM_HTML = "itemHtml";
class ItemHTML extends BlockEmbed {
  static create(data: any) {
    const node = super.create();
    node.setAttribute("data-value", data.value);
    node.innerHTML += data.value;
    return node;
  }

  static value(node: any) {
    return (
      node.getAttribute("data-value") && {
        value: node.getAttribute("data-value"),
      }
    );
  }
}

ItemHTML.blotName = ITEM_HTML;
ItemHTML.tagName = "article";
Quill.register(ItemHTML, true);

var Block = Quill.import("blots/block");
Block.tagName = "div";
Quill.register(Block);

const Inline = Quill.import('blots/inline');
const LINK_BLOT = "LinkBlot";
class LinkBlot extends Inline {
  static create(data: any) {
    let node = super.create();
    node.setAttribute("href", data.value);
    node.setAttribute(
      "style",
      "color : #007AFF;width : auto; max-width: 90%; display: inline-block"
    );
    node.setAttribute("onclick", `_.sendEvent('CustomEmbedLink')`);
    node.innerHTML = data.text;
    return node;
  }

  static formats(node: any) {
    return undefined;
  }
}
LinkBlot.blotName = LINK_BLOT;
LinkBlot.tagName = "a";
LinkBlot.className = "customLink";
Quill.register(LinkBlot, true);

const CUSTOM_LINK = "customLink";
class CustomLink extends Inline {
  static create(data: any) {
    const node = super.create(data);
    node.setAttribute("href", this.sanitize(data.value));
    node.setAttribute("rel", "noopener noreferrer");
    node.setAttribute("style", "color : #007AFF;width : auto; max-width: 90%; display: inline-block");
    node.setAttribute("onclick", "_.sendEvent('CustomEmbedLink')");
    return node;
  }

  static formats(domNode: any) {
    return domNode.getAttribute("href");
  }

  static sanitize(url: string) {
    return sanitizeUrl(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
  }

  format(name: string, value: string) {
    super.format(name, value);
  }
}

const sanitizeUrl = (url: string, protocols: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  const protocol = anchor.href.slice(0, anchor.href.indexOf(":"));
  return protocols.indexOf(protocol) > -1;
}

CustomLink.blotName = CUSTOM_LINK;
CustomLink.tagName = "A";
CustomLink.className = CUSTOM_LINK;
CustomLink.SANITIZED_URL = "about:blank";
CustomLink.PROTOCOL_WHITELIST = ["http", "https", "mailto", "tel"];
Quill.register("formats/customLink", CustomLink, true);

//font size: 3 = 16px, 4 = 18px, 5 = 24px;
const fontSizeMap = new Map([
  [3, "16px"],
  [4, "18px"],
  [5, "24px"],
]);
const fontSizeStyle = Quill.import("attributors/style/size");
fontSizeStyle.whitelist = [...fontSizeMap.values()];
Quill.register(fontSizeStyle, true);

let locationVideo = "";
let dataUploadVideo: {
  path: string,
  signedUrl: string,
  url: string,
} = { path: "", signedUrl: "", url: "" };

const KernelForm = (props: any) => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const history = useHistory();
  const { theme } = useContext(ThemeContext);
  const { banner } = useContext(XState);
  const quillRef = useRef<any>(null);

  // When editing a kernel there is a kernel ID, when making a new kernel there
  // is only a collection ID
  let { id } = useParams<{ id: string | undefined }>();
  let { collectionId } = useParams<{ collectionId: string | undefined }>();
  const { userId } = useAppSelector(selectCurrentUser);

  const { data: kernel } = useGetKernelQuery(id ? { id: id, state: "parse" } : skipToken);
  collectionId = collectionId || kernel?.collectionId;

  let { data: collection } = useGetCollectionQuery(
    collectionId ? { id: collectionId } : skipToken
  );

  const { data: bookmarks } =
    useGetBookmarkedContentQuery(
      collectionId === "" ? { id: userId! } : skipToken
    );

  if (!collection) {
    collection = bookmarks;
  }

  const cachedContent: CacheContent | undefined = useAppSelector(state => {
    const id = (kernel ? kernel.id : collectionId) ?? "";
    return state.cachedContent.content.find((item) => item.id === id);
  });
  const [addLink, setAddLink] = useState<string>("Text");
  const [title, setTitle] = useState<string>(kernel?.title ?? "");
  const [search, setSearch] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [isShowToolbar, setShowToolbar] = useState<boolean>(true);
  const { height } = useWindowDimensions();
  const [state, setState] = useState({
    modalVisible: false,
    title: "Add TV/Movie",
  });
  const [link, setLink] = useState<string>("");
  const [textOption, setTextOption] = useState<string>("");
  const [data, setData] = useState([]);
  const [fontSize, setFontSize] = useState<number>(3);
  const inputImgRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
  const [enableCacheContent, setEnableCacheContent] = useState<boolean>(false);
  const [alertSubscriber, setAlertSubscriber] = useState<boolean>(false);
  const [popupExit, setPopupExit] = useState<boolean>(false);
  const [validateMsg, setValidateMsg] = useState<string>("");
  const [errorLink, setErrorLink] = useState<string>("");
  const [visibleDaftFallback, setVisibleDaftFallback] = useState<boolean>(cachedContent !== undefined);

  const chunkSize = 1024 * 1024 * 30;//30MB
  const [file, setFile] = useState<any>(null);
  const [currentChunkIndex, setCurrentChunkIndex] = useState<number>(-1);
  const [progress, setProgress] = useState<number>(0);

  const [createKernel] = useCreateKernelMutation();
  const [shareKernel] = useShareKernelMutation();
  const [updateKernel] = useUpdateKernelMutation();
  const [getLocation] = useGetLocationsMutation();
  const [getUrlUpLoadVideo] = useGetUrlUploadVideoMutation();

  const debounceCacheContent = debounce((data: string, title: string) => {
    const contentId = kernel ? kernel.id : collectionId;
    contentId &&
      enableCacheContent &&
      !visibleDaftFallback &&
      (validateContentString(data) || validateContentString(title)) &&
      dispatch(cachingContent({
        id: contentId,
        data: formatContent(data),
        title: title,
      }));
  }, 200);

  const removeCachedContent = () => {
    cachedContent && dispatch(removeCache({
      id: cachedContent.id
    }));
  }

  useEffect(() => {
    setTitle(kernel?.title || title);
  }, [kernel]);

  const toggleModal = (visible?: boolean) => {
    if (visible === undefined) {
      visible = !state.modalVisible;
    }
    setState({ ...state, modalVisible: false, title: `Add TV/Movie` });
    setAddLink("Text");
    setShowToolbar(true);
    setLoadingInsert(false);
    setLink("");
    setTextOption("");
    setErrorLink("");
  };

  $("#react-quill-editor a").click(function () {
    let url = $(this).attr("href");
    if (url?.includes("http") || url?.includes("https")) {
      window.open(url);
    } else {
      window.open("http://" + url);
    }
    return false;
  });

  const handleSelectLink = (type: string) => {
    if (type === addLink) {
      setAddLink("");
      setShowToolbar(false);
    } else {
      type === "Text" && setShowToolbar(true);
      setAddLink(type);
    }
    //add new image
    if (type === "Image") {
      inputImgRef.current?.click();
      return;
    } else if (type !== "Text") {
      setState({ ...state, modalVisible: true, title: `Add ${type}` });
    }
  };

  const insertEmbed = (data: DataHtml) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const index = editor.selection ? editor.selection.savedRange.index : 0;
      const format = editor.getFormat();
      const isListFormat =
        format.list === "ordered" || format.list === "bullet";
      if (isListFormat) {
        editor.editor.removeFormat(index, index, "user");
      }
      editor.insertEmbed(index, ITEM_HTML, { value: data.source }, "user");
      const newEditor = quillRef.current?.getEditor();
      const newIndex = newEditor.selection ? newEditor.selection.savedRange.index : 0;
      newEditor.setSelection(newIndex + 2, "user");
    }
  };

  const insertEmbedLink = (data: string, text: string) => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const index =
        editor && editor.selection ? editor.selection.savedRange.index : 0;
      editor.insertEmbed(
        index,
        LINK_BLOT,
        { value: data, text: text },
        "user"
      );
      editor.setSelection(index + text.length, "silent");
    }
  };

  const onChange = (
    content: string,
    delta: any,
    source: string,
    editor: any
  ) => {
    setContent(content);
    debounceCacheContent(content, title);
  };

  const getChildren = (element: HTMLElement): HTMLElement[] => {
    const result: HTMLElement[] = [];
    if (!element.childNodes && !element.children) {
      return [];
    }

    if (!element.hasChildNodes() && !element.children) {
      return [element];
    }

    if (
      (element.className && typeof element.className !== "object" &&
        (element.className.includes("iframe") ||
          element.className.includes("customLink"))) ||
      (element.tagName &&
        (element.tagName.includes("OL") || element.tagName.includes("UL")))
    ) {
      return [element];
    }

    const childrenArr =
      element.childNodes.length > 1 &&
        element.childNodes.length > element.children.length
        ? element.childNodes
        : element.children;

    ([...childrenArr] as HTMLElement[]).forEach((ele) => {
      if (ele.outerHTML) {
        if (
          (ele.className && typeof ele.className !== "object" &&
            (ele.className.includes("iframe") ||
              ele.className.includes("customLink") ||
              ele.className.includes("oEmbed"))) ||
          (ele.tagName &&
            (ele.tagName.includes("OL") || ele.tagName.includes("UL")))
        ) {
          result.push(ele);
        } else {
          const temp = getChildren(ele);
          if (temp.length === 0) {
            result.push(childrenArr.length === 1 ? element : ele);
          } else {
            result.push(...temp);
          }
        }
      } else {
        result.push(ele);
      }
    });
    return result;
  };

  const convertJQueryToStrArray = (html: JQuery<HTMLElement>): string[] => {
    const result: string[] = [];
    let childContent = "";
    html.each((_i, element) => {
      let removeChild = "";
      const children = getChildren(element);
      const embedChildren = children.filter(item =>
        item.className && typeof item.className !== "object"
        && (item.className.includes("iframe") || item.className.includes("oEmbed"))
      );
      const isComplexChildren = embedChildren.length !== 0 && embedChildren.length < children.length;
      if (children.length > 0) {
        children.forEach((ele) => {
          if (ele.className
            && typeof ele.className !== "object"
            && (ele.className.includes("iframe") || ele.className.includes("oEmbed"))
          ) {
            if (childContent) {
              result.push(childContent);
              childContent = "";
            }
            removeChild += ele.outerHTML;
            result.push(ele.tagName && ele.tagName !== "DIV" ? `<div>${ele.outerHTML}</div>` : ele.outerHTML);
            return;
          }
          if (isComplexChildren) {
            childContent += ele.outerHTML ?? ele.textContent;
          }
        });
      } else if (element.className
        && typeof element.className !== "object"
        && element.className.includes("oEmbed")) {
        if (childContent) {
          result.push(childContent);
          childContent = "";
        }
        removeChild += element.outerHTML;
        result.push(element.tagName && element.tagName !== "DIV" ? `<div>${element.outerHTML}</div>` : element.outerHTML);
      }
      if (!isComplexChildren) {
        childContent += element.outerHTML ?? element.textContent;
      }
      if (removeChild.length !== 0) {
        childContent = childContent.replace(removeChild, "");
      }
      if (childContent.length !== 0) {
        childContent = `<div>${childContent}</div>`;
      }
    });
    result.push(childContent);
    return result;
  };

  const replaceFormat = (
    content: string,
    arrFormat: string[],
    size: number
  ): string => {
    let result = content;
    const formattedSize = arrFormat.map((s) =>
      s
        .replace(
          `<font size="${size}">`,
          `<span style="font-size: ${fontSizeMap.get(size)}">`
        )
        .replace("</font>", "</span>")
    );
    formattedSize.forEach((value, i) => {
      result = result.replace(arrFormat[i], value);
    });
    return result;
  };

  const getIndexOfStartTag = (data: string, size: number): number => {
    return data.indexOf(`<font size="${size}">`);
  };

  const getIndexOfEndTag = (
    data: string,
    size: number,
    position: number
  ): number => {
    const index = data.indexOf(`</font>`, position);
    return index !== -1 ? index + "</font>".length : index;
  };

  const searchFormatFont = (data: string, size: number): string[] => {
    if (data === "") {
      return [];
    }
    const result: string[] = [];
    let indexStart = getIndexOfStartTag(data, size);
    if (indexStart !== -1) {
      let formatPhrase = "";
      let indexEnd = getIndexOfEndTag(data, size, indexStart);
      if (indexEnd !== -1) {
        formatPhrase = data.slice(indexStart, indexEnd);
        result.push(formatPhrase);
      }
      let nextFormat = searchFormatFont(
        data.slice(indexEnd, data.length),
        size
      );
      if (nextFormat.length !== 0) {
        result.push(...nextFormat);
      }
    }
    return result;
  };

  const formatFontSize = (data: string): string => {
    let result = data;
    const groupSize5 = searchFormatFont(data, 5);
    const groupSize4 = searchFormatFont(data, 4);
    const groupSize3 = searchFormatFont(data, 3);
    if (groupSize5) {
      result = replaceFormat(result, groupSize5, 5);
    }
    if (groupSize4) {
      result = replaceFormat(result, groupSize4, 4);
    }
    if (groupSize3) {
      result = replaceFormat(result, groupSize3, 3);
    }
    return result;
  };

  const removeUselessTag = (html: string): string => {
    let cleanedHtml = html.replace(/[\n]/g, "").replace("<div></div>", "");
    if (cleanedHtml.includes("<div></div>")) {
      cleanedHtml = removeUselessTag(cleanedHtml);
    }
    return cleanedHtml;
  }

  const loadDataKernel = (html: string) => {
    const editor = quillRef.current?.getEditor();
    if (!editor) {
      return;
    }
    if (content.length > 0) {
      editor.setSelection(0, "silent");
      editor.setContents("");
    }
    const cleanedHtml = removeUselessTag(html.trim());
    const jQueryHTMLElement = $(formatFontSize(cleanedHtml));
    const arrContent = convertJQueryToStrArray(jQueryHTMLElement);
    arrContent.forEach((content, i) => {
      let cleanedContent = removeUselessTag(content);
      const index =
        editor && editor.selection ? editor.selection.savedRange.index : 0;
      // format break line embed
      if (i < arrContent.length - 1
        && (arrContent[i + 1].includes('class="iframe-')
          || arrContent[i + 1].includes("<img")
          || arrContent[i + 1].includes("oEmbed"))
      ) {
        const lastChild = $(cleanedContent).children().last()[0];
        if (lastChild && (lastChild.innerHTML.includes("<br>") || (lastChild.tagName && lastChild.tagName === "BR"))) {
          const lastIndex = cleanedContent.lastIndexOf("<br>");
          cleanedContent = cleanedContent.substring(0, lastIndex + "<br>".length)
            + "\n"
            + cleanedContent.substring(lastIndex + "<br>".length, cleanedContent.length);
        }
      }
      if (cleanedContent.includes('class="iframe-')
        || cleanedContent.includes("<img")
        || cleanedContent.includes("oEmbed")) {
        editor.insertEmbed(index, ITEM_HTML, { value: cleanedContent }, "user");
      } else {
        editor.clipboard.dangerouslyPasteHTML(index, cleanedContent, "user");
      }
      const newEditor = quillRef.current?.getEditor();
      newEditor.setSelection(newEditor?.getContents()?.length() ?? 0, "silent");
    });
    editor.history.clear();
    //Delay 1s to make sure the content is loaded.
    setTimeout(() => {
      setEnableCacheContent(true);
    }, 1000)
  };

  const handleLinkIframely = async (url: string) => {
    setLoadingInsert(true);
    const dataIframe = await kernelFormApi.oEmbedIframely(url);
    if (dataIframe && dataIframe?.data?.html) {
      if (validateSpotify(url)) {
        const item = {
          name: dataIframe?.data?.title ?? url,
          images: [
            {
              url: dataIframe?.data?.thumbnail_url || undefined
            }
          ],
        }
        if (url.includes('artist') || url.includes('playlist')) {
          insertEmbed({
            type: DataTypes.Link,
            source: IframeMusic(url, item)
          });
        } else {
          insertEmbed({
            type: DataTypes.Link,
            source: IframePodcast(url, item)
          });
        }
      } else if (validateYouTubeUrl(url)) {
        const item = {
          snippet: {
            title: dataIframe?.data?.title ?? data
          }
        }
        insertEmbed({
          type: DataTypes.Link,
          source: IframeYoutube(url, item),
        });
      } else if (validateInstagram(url) || validateTikTok(url)
        || validateTwitter(url) || validateFacebook(url)
      ) {
        insertEmbed({
          type: DataTypes.Link,
          source: oEmbed(url),
        });
      } else {
        insertEmbed({
          type: DataTypes.Link,
          source: oEmbedIframely(dataIframe?.data, url),
        });
      }
    }
    setLoadingInsert(false);
    return dataIframe
  }

  const onEditorChange = (eventName: any, args: any) => {
    const editor = quillRef.current?.editor;
    if (editor) {
      switch (eventName) {
        case 'text-change':
          if (args) {
            const { ops } = args as Delta;
            ops?.forEach(op => {
              if (((ops.length <= 2 || ops.length === 3) && ops[0].retain)
                && op.attributes
                && op.attributes.customLink
                && op.insert
                && op.insert === op.attributes.customLink
              ) {
                const url = op.insert ?? op.attributes.customLink;
                handleLinkIframely(url).then(res => {
                  if (res && res.data?.html) {
                    const newOps: DeltaOperation[] = [];
                    if (ops[0] && ops[0].retain) {
                      newOps.push({
                        retain: ops[0].retain
                      });
                    }
                    newOps.push({
                      delete: url.length + "\n".length,
                    });
                    editor.updateContents({
                      ops: newOps
                    });
                  }
                }).catch(e => {
                  setLoadingInsert(false);
                });
              }
            });
          }
          break;
        case 'selection-change':
          if (args) {
            const format = editor.getFormat(args);
            if (!format.size && args.length === 0) {
              try {
                setFontSize(3);
                if (editor.getLength() && editor.getLength() > 1) {
                  editor.format("size", fontSizeMap.get(3));
                }
                editor.setSelection(args);
              } catch (error) { }
            }
          }
          break;
        default:
          break;
      }
    }
  };

  const [retry, setRetry] = useState<boolean>(false);

  const changeHeaderToSize = (opTemp: any, size: any) => {
    delete opTemp.attributes.header;
    opTemp.attributes = { ...opTemp.attributes, ...{ size: size } };
    return opTemp;
  }

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.editor;
      editor?.on('editor-change', onEditorChange);

      editor?.clipboard.addMatcher(Node.ELEMENT_NODE, (node: any, delta: Delta) => {
        const newOps: DeltaOperation[] = [];
        ($(node) as JQuery<HTMLElement>).each((i, ele) => {
          if (ele.className && typeof ele.className !== "object" && ele.className === 'customLink') {
            newOps.push({
              attributes: {
                customLink: {
                  value: ele.getAttribute("href"),
                  text: ele.textContent,
                }
              },
              insert: ele.textContent,
            });
          }
        });

        if (delta.ops) {
          delta.ops.forEach(op => {
            if (op?.attributes?.header) {
              let opTemp: any = op;
              if (opTemp.attributes.header === 1) {
                opTemp = changeHeaderToSize(opTemp, fontSizeMap.get(5))
                newOps.push(opTemp);
                return;
              } else if (opTemp.attributes.header === 2) {
                opTemp = changeHeaderToSize(opTemp, fontSizeMap.get(4))
                newOps.push(opTemp);
                return;
              } else {
                opTemp = changeHeaderToSize(opTemp, fontSizeMap.get(3))
                newOps.push(opTemp);
                return;
              }
            }
            if (op && op.insert && op.insert.image) {
              newOps.push({
                insert: {
                  itemHtml: {
                    value: InsertImage(op.insert.image),
                  }
                }
              });
              return;
            }
            if (op && op.insert && op.attributes && op.attributes.link) {
              newOps.push({
                attributes: {
                  customLink: {
                    value: op.attributes.link,
                    text: op.insert,
                  }
                },
                insert: op.insert,
              });
              return;
            }
            ($(node) as JQuery<HTMLElement>).each((_i, ele) => {
              if (ele.style && ele.style.textDecorationLine === "underline") {
                newOps.push({
                  ...op,
                  attributes: {
                    ...op.attributes,
                    underline: true,
                  },
                });
              }
            });
          });
        }
        if (newOps.length !== 0) {
          delta.ops = [...newOps]
        }

        //remove attributes not supported in editor
        delta.ops?.forEach(op => {
          if (op.attributes) {
            delete op.attributes.block;
            delete op.attributes.background;
            delete op.attributes.color;
            delete op.attributes["code-block"];
          }
        });
        return delta;
      });

      editor?.clipboard.addMatcher(Node.TEXT_NODE, (node: any, delta: Delta) => {
        const newOps: DeltaOperation[] = [];
        if (delta.ops) {
          delta.ops.forEach(op => {
            if (op && op.insert && !(op.attributes && op.attributes.customLink) && validURL(op.insert)) {
              newOps.push({
                attributes: {
                  customLink: {
                    value: op.insert,
                    text: op.insert,
                  }
                },
                insert: op.insert,
              });
              return;
            }
          });
        }

        if (!(node.data && node.data.replace(/\s/g, '').length)) {
          newOps.push({
            insert: node.data
          });
        }

        if (newOps.length != 0) {
          delta.ops = [...newOps];
        }
        return delta;
      });

      return () => editor?.off('editor-change', onEditorChange);
    } else {
      if (attempts > 10) {
        throw "Could not find the editor component after several seconds. Something is broken.";
      }
      setTimeout(() => {
        setRetry(!retry);
      }, 500)
    }
  }, [retry, setRetry]);

  //search only when user stops typing
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(search);
      setTimeout(() => setLoading(false), 1500);
    }, 1500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    if (!quillRef.current) {
      if (attempts > 10) {
        throw "Could not find the editor component after several seconds. Something is broken.";
      }
      setTimeout(() => setAttempts(attempts + 1), 500);
    } else {
      if (cachedContent) {
        setVisibleDaftFallback(true);
      }
      if (kernel) {
        loadDataKernel(kernel.content);
      } else {
        setEnableCacheContent(true);
      }
    }
  }, [kernel, attempts, setAttempts]);

  const CustomToolbar = (isShow: boolean) => {
    return (
      <div
        id="toolbar"
        className="toolbar-custom"
        style={{ display: isShow ? "block" : "none" }}
      >
        <button
          className="ql-size custom-btn-toolbar"
          value={fontSizeMap.get(5)}
          onClick={() => setFontSize(fontSize === 5 ? 3 : 5)}
        >
          <div className="text-toolbar">H1</div>
        </button>
        <button
          className="ql-size custom-btn-toolbar"
          value={fontSizeMap.get(4)}
          onClick={() => setFontSize(fontSize === 4 ? 3 : 4)}
        >
          <div className="text-toolbar">H2</div>
        </button>
        <button
          className={`ql-size custom-btn-toolbar ${fontSize === 3 ? "ql-active" : ""
            }`}
          value={fontSizeMap.get(3)}
          onClick={() => fontSize !== 3 && setFontSize(3)}
        >
          <div className="text-toolbar">Body</div>
        </button>
        <button className="ql-bold custom-btn-toolbar" />
        <button className="ql-italic custom-btn-toolbar" />
        <button className="ql-underline custom-btn-toolbar" />
        <button className="ql-list custom-btn-toolbar" value="ordered" />
        <button className="ql-list custom-btn-toolbar" value="bullet" />
      </div>
    );
  };

  const handleSearch = async (text: string) => {
    switch (addLink) {
      case "Link":
        break;
      case "TV/Movie":
        if (text.trim() === "") return setData([]);
        let movies: any = await kernelFormApi.searchMovie(text);
        setData(movies.data);
        break;
      case "Book":
        if (text.trim() === "") return setData([]);
        let books: any = await kernelFormApi.searchBook(text);
        setData(books.data);
        break;
      case "Location":
        if (text.trim() === "") return setData([]);
        try {
          let locations: any = await getLocation({ text });
          if (locations?.data?.results) setData(locations.data.results);
          else setData([]);
        } catch (e) {
          setData([]);
        }
        break;
      case "Music":
        if (text.trim() === "") return setData([]);
        let musics: any = await kernelFormApi.searchMusic(text);
        setData(musics.data);
        break;
      case "Podcast":
        if (text.trim() === "") return setData([]);
        let podcast: any = await kernelFormApi.searchPodcast(text);
        setData(podcast.data);
        break;
      case "YouTube":
        if (text.trim() === "") return setData([]);
        let arrYoutube: any = await kernelFormApi.searchYoutube(text);
        setData(arrYoutube.data);
        break;
    }
  };

  const toBase64 = (file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (currentChunkIndex !== -1) {
      readAndUploadCurrentChunk();
    }
  }, [currentChunkIndex]);

  const readAndUploadCurrentChunk = () => {
    if (!file) {
      onUploadVideoFailed();
      return;
    }
    const reader = new FileReader();
    const from = currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = e => uploadChunk(e);
    reader.readAsDataURL(blob);
  }
  const uploadChunk = async (readerEvent: ProgressEvent<FileReader>) => {
    if (!file) {
      onUploadVideoFailed();
      return;
    }
    const data = readerEvent.target?.result;
    if (data) {
      const fileSize = file.size;
      const base64 = (data as String).split(",")[1];
      const buffer = Buffer.from(base64, "base64");
      const chunks = Math.ceil(fileSize / chunkSize) - 1;
      const isLastChunk = currentChunkIndex === chunks;
      const current = currentChunkIndex * chunkSize;
      try {
        const response = await kernelFormApi.chunkMultipleUpload(locationVideo, fileSize, current, buffer, updateProgressBar);
        if (response.status === 200) {
          if (isLastChunk) {
            onUploadVideoSuccess(dataUploadVideo.url);
          } else {
            setCurrentChunkIndex(currentChunkIndex + 1);
          }
        }
      } catch (error: any) {
        if (error && error.response && error.response.status === 308) {
          setCurrentChunkIndex(currentChunkIndex + 1);
        } else {
          onUploadVideoFailed();
        }
      }
    }
  };

  const updateProgressBar = (data: any) => {
    setProgress(data)
  }

  const uploadVideo = async (file: any) => {
    if (file) {
      setFile(file);
      const fileSize = file.size;
      try {
        const getUrlUpLoadVideoRes: any = await getUrlUpLoadVideo({ fileSize: fileSize });
        if (!getUrlUpLoadVideoRes.hasOwnProperty("error")) {
          dataUploadVideo = getUrlUpLoadVideoRes.data;
          const initUploadVideoRes = await kernelFormApi.initUploadVideo("video/mov", dataUploadVideo.signedUrl);
          locationVideo = initUploadVideoRes;
          if (fileSize < chunkSize) {
            const base64 = await toBase64(file);
            if (base64) {
              const buffer: Buffer = Buffer.from((base64 as String).split(",")[1], "base64");
              await kernelFormApi.chunkSingleUpload(locationVideo, buffer, updateProgressBar);
              onUploadVideoSuccess(dataUploadVideo.url);
            } else {
              onUploadVideoFailed();
            }
          } else {
            setCurrentChunkIndex(0);
          }
        } else {
          onUploadVideoFailed(getUrlUpLoadVideoRes.error.status === 400 ? "File too large!" : "Upload video failed!");
        }
      } catch (error) {
        onUploadVideoFailed();
      }
    } else {
      onUploadVideoFailed();
    }
  };

  const onUploadVideoFailed = (message: string = "Upload video failed!") => {
    setCurrentChunkIndex(-1);
    setLoadingInsert(false);
    updateProgressBar(0);
    setTimeout(() => toggleModal(), 100);
    banner.send({
      type: "ADD_BANNER",
      banner: {
        theme: BannerTheme.Error,
        content: message,
        id: "uploadVideo",
      },
    });
  }

  const onUploadVideoSuccess = (videoUrl: string) => {
    setCurrentChunkIndex(-1);
    setLoadingInsert(false);
    updateProgressBar(0);
    setTimeout(() => toggleModal(), 100);
    insertEmbed({
      type: DataTypes.Image,
      source: InsertVideo(videoUrl),
    });
    banner.send({
      type: "ADD_BANNER",
      banner: {
        theme: BannerTheme.Success,
        content: "Upload video success!",
        id: "uploadVideo",
      },
    });
  }

  const onImageChange = async (event: any) => {
    if (event) {
      let file = event.target.files[0];
      if (file.type.includes("video/")) {
        updateProgressBar(1);
        uploadVideo(file);
      } else {
        let typeImgSupport: string = '';
        if (file.type === "") {
          typeImgSupport = 'image/' + file.name.split('.').slice(1);
        }
        setLoadingInsert(true);
        if ((file.type || typeImgSupport) === "image/heic") {
          file = await heic2any({
            blob: file,
            toType: "image/jpg",
          });
        }
        await resizeFile(file, 'PNG', 'base64')
          .then((res) => {
            insertEmbed({
              type: DataTypes.Image,
              source: InsertImage(res),
            });
            setLoadingInsert(false);
            setTimeout(() => toggleModal(), 100);
          })
          .catch((err) => {
            setLoadingInsert(false);
            setTimeout(() => toggleModal(), 100);
          });
      }
    }
  };

  useEffect(() => {
    if (!state.modalVisible) {
      setSearch("");
      setData([]);
    }
  }, [state.modalVisible]);

  const decodeHTMLEntities = (str: string) => {
    var text = $("<textarea />").html(str).text();
    return text;
  };

  const renderDataYoutube = (dataYoutube: any) => {
    return (
      <div className="container-search youtube-search">
        {dataYoutube.map((item: any, index: number) => {
          if (
            !item ||
            !item.id ||
            !item.id.videoId ||
            !item.snippet ||
            !item.snippet.title
          )
            return null;
          return (
            <div
              key={index}
              className="item-search"
              onClick={() => handleInsertIfame(item)}
            >
              <div className="image-search">
                <img
                  src={item.snippet?.thumbnails.default?.url}
                  alt={item.snippet?.title ?? ""}
                />
              </div>
              <div className="overview-modal">
                <div className="title">
                  {decodeHTMLEntities(item.snippet?.title ?? "")}
                </div>
                <div className="description">
                  {decodeHTMLEntities(item.snippet?.description ?? "")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderDataLocation = (locations: any) => {
    return (
      <div className="container-search location-search">
        {locations.map((item: any, index: number) => {
          if (!item || !item.name || !item.formatted_address) return null;
          return (
            <div
              key={index}
              className="item-search"
              onClick={() => handleInsertIfame(item)}
            >
              <div className="image-search">
                <img
                  src={
                    item?.photos?.[0]?.photo_reference
                      ? `https://maps.googleapis.com/maps/api/place/photo?photoreference=${item?.photos[0]?.photo_reference}&maxheight=50&maxwidth=50&key=${process.env.REACT_APP_KEY_FIREBASE}`
                      : LOCATION_ICON
                  }
                  alt={item.name ?? ""}
                />{" "}
              </div>
              {item.rating ? (
                <div className="overview-modal">
                  <div className="title">{item.name ?? ""}</div>
                  <div className="description">
                    {item.formatted_address ?? ""}
                  </div>
                  {item?.types?.length !== 0 ? (
                    <div className="description address">
                      {item.formatted_address ?? ""}
                    </div>
                  ) : null}
                </div>
              ) : (
                <div className="overview-modal">
                  <div className="title">{item.name ?? ""}</div>
                  <div className="description">
                    {item.formatted_address ?? ""}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const renderDataBook = (locations: any) => {
    return (
      <div className="container-search book-search">
        {locations.map((item: any, index: number) => {
          if (
            !item ||
            !item.volumeInfo ||
            !item.volumeInfo?.title ||
            !item.volumeInfo?.authors
          )
            return null;
          return (
            <div
              key={index}
              className="item-search"
              onClick={() => handleInsertIfame(item)}
            >
              <div className="image-search">
                <img
                  src={
                    item.volumeInfo?.imageLinks?.thumbnail?.replace(
                      /zoom=1/g,
                      "zoom=3"
                    ) ?? null
                  }
                  alt={item.volumeInfo?.title ?? ""}
                />
              </div>
              <div className="overview-modal">
                <div className="title">{item.volumeInfo?.title ?? ""}</div>
                <div className="description">
                  {item.volumeInfo?.authors?.[0] ?? ""}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderDataTvMovie = (TvMovie: any) => {
    return (
      <div className="container-search TvMovie-search">
        {TvMovie.map((item: any, index: number) => {
          const title =
            item?.title ??
            item?.original_title ??
            item.name ??
            item.original_name;
          if (!item || !item.poster_path || !title) return null;
          return (
            <div
              key={index}
              className="item-search"
              onClick={() => handleInsertIfame(item)}
            >
              <div className="image-search">
                <img
                  src={`https://image.tmdb.org/t/p/w500/${item.poster_path}`}
                  alt=""
                />
              </div>
              <div className="overview-modal">
                <div className="title">{title ?? ""}</div>
                <div className="releaseDate">
                  {moment(item?.release_date).format("YYYY") ?? ""}
                </div>
                <div className="overView">{item.overview ?? ""}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const renderDataMusicPodcast = (Musics: any) => {
    return (
      <div className="container-search Musics-search">
        {Musics.map((item: any, index: number) => {
          if (
            !item ||
            !item.external_urls ||
            !item.name ||
            !item.external_urls.spotify
          )
            return null;
          return (
            <div
              key={index}
              className="item-search"
              onClick={() => handleInsertIfame(item)}
            >
              <div className="image-search">
                <img
                  src={item?.album?.images[0]?.url ?? item?.images[0]?.url}
                  alt={item.name ?? ""}
                />
              </div>
              <div className="overview-modal">
                <div className="title">{item.name ?? ""}</div>
                <div className="description">{item.type ?? ""}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleInsertIfame = (item: any) => {
    if (addLink === "YouTube") {
      const link = "https://www.youtube.com/watch?v=" + item.id.videoId;
      insertEmbed({
        type: DataTypes.YouTube,
        source: IframeYoutube(link, item),
      });
    }
    if (addLink === "Location") {
      insertEmbed({
        type: DataTypes.Location,
        source: IframeLocation(item),
      });
    }
    if (addLink === "Book") {
      insertEmbed({
        type: DataTypes.Book,
        source: IframeGBook(item),
      });
    }
    if (addLink === "TV/Movie") {
      insertEmbed({
        type: DataTypes.TvMovie,
        source: IframeMovie(item),
      });
    }
    if (addLink === "Music") {
      const linkMusic = item?.external_urls?.spotify ?? "#";
      insertEmbed({
        type: DataTypes.Music,
        source: IframeMusic(linkMusic, item),
      });
    }
    if (addLink === "Podcast") {
      const linkPodcast = item?.external_urls?.spotify ?? "#";
      insertEmbed({
        type: DataTypes.Podcast,
        source: IframePodcast(linkPodcast, item),
      });
    }
    setTimeout(() => {
      toggleModal();
    }, 100);
  };

  const onChangeTitle = (str: string) => {
    setTitle(str);
    validTitle(str);
    debounceCacheContent(content, str);
  }

  const validTitle = (str: string) => {
    if (str.length === 0) {
      setValidateMsg("Kernel title is required");
      return;
    }
    if (str.trim().length < 3) {
      setValidateMsg("Kernel title should contain at least 3 characters");
      return;
    }
    setValidateMsg("");
  };

  const handleAddLink = async (
    customLink: string = "",
    customText: string = ""
  ) => {
    const url = customLink.length !== 0 ? customLink : link;
    const txtOpt = customText.length !== 0 ? customText : textOption;
    if (validURL(url) && textOption === "") {
      setLoadingInsert(true);
      const dataIframe = await kernelFormApi.oEmbedIframely(url);
      if (dataIframe && dataIframe?.data?.html) {
        if (validateSpotify(url)) {
          const item = {
            name: dataIframe?.data?.title ?? url,
            images: [
              {
                url: dataIframe?.data?.thumbnail_url || undefined
              }
            ],
          }
          if (url.includes('artist') || url.includes('playlist')) {
            insertEmbed({
              type: DataTypes.Link,
              source: IframeMusic(url, item)
            });
          } else {
            insertEmbed({
              type: DataTypes.Link,
              source: IframePodcast(url, item)
            });
          }
        } else if (validateYouTubeUrl(url)) {
          const item = {
            snippet: {
              title: dataIframe?.data?.title ?? data
            }
          }
          insertEmbed({
            type: DataTypes.Link,
            source: IframeYoutube(url, item),
          });
        } else if (validateInstagram(url) || validateTikTok(url)
          || validateTwitter(url) || validateFacebook(url)
        ) {
          insertEmbed({
            type: DataTypes.Link,
            source: oEmbed(url),
          });
        } else {
          insertEmbed({
            type: DataTypes.Link,
            source: oEmbedIframely(dataIframe?.data, url),
          });
        }
      } else {
        insertEmbedLink(url, txtOpt.length === 0 ? url : txtOpt);
      }
      setLoadingInsert(false);
    } else if (validURL(url)) {
      insertEmbedLink(url, txtOpt !== "" ? txtOpt : url);
    } else {
      setErrorLink("The url is invalid");
      return;
    }
    setTimeout(() => {
      toggleModal();
    }, 100);
  };

  const formatContent = (content: string): string => {
    let formattedContent = "";
    $(content).each((_i, ele) => {
      if (ele.tagName === "ARTICLE") {
        ele.removeAttribute("data-value");
      }
      if (ele.children) {
        ([...ele.children] as HTMLElement[]).forEach((element) => {
          if (
            element.tagName &&
            element.tagName === "A" &&
            element.className &&
            typeof element.className !== "object" &&
            element.className === "customLink"
          ) {
            element.setAttribute("contenteditable", "false");
          }
        });
      }
      formattedContent += ele.outerHTML;
    });

    return formattedContent
      .replace(/<article>/g, "")
      .replace(/<\/article>/g, "")
      .replace(/[\n]/g, "")
      .trim();
  }

  const handleSubmit = async (
    arrID: string[],
    message: string,
    alertSubscribers: boolean
  ) => {
    const formattedContent = formatContent(content);
    setAlertSubscriber(false);
    const params: PostKernel = {
      content: formattedContent,
      title,
      tagLine: title,
      collectionId: collectionId!,
      shareUpdate: false,
      noteReceivers: [],
      note: "",
    };
    if (id) {
      updateKernel({
        kernel: {
          ...params,
          shareUpdate: true,
        },
        kernelId: id,
      }).then((result: any) => {
        if (result.hasOwnProperty("error")) {
          banner.send({
            type: "ADD_BANNER",
            banner: {
              theme: BannerTheme.Error,
              content: "Something went wrong while saving your kernel",
              id: "addKernelError",
            },
          });
        } else {
          removeCachedContent();
          if (alertSubscribers) {
            shareKernel({
              shareKernel: {
                notes: message ?? "",
                userIds: arrID,
                type: "UPDATE",
                alertSubscriber: alertSubscribers,
              },
              kernelId: result.data.id,
            });
          }
          banner.send({
            type: "ADD_BANNER",
            banner: {
              theme: BannerTheme.Success,
              content: "Your Trove just got that much more meaningful! 🎉",
              id: "addKernelSuccess",
            },
          });
          history.push(`/kernel/${kernel?.id}`);
        }
      });
    } else {
      createKernel(params).then((result: any) => {
        if (result.hasOwnProperty("error")) {
          if (result?.error?.status === 400) {
            banner.send({
              type: "ADD_BANNER",
              banner: {
                theme: BannerTheme.Error,
                content: "You reach maximum of kernels per collection.",
                id: "maxKernelsError",
              },
            });
          } else {
            banner.send({
              type: "ADD_BANNER",
              banner: {
                theme: BannerTheme.Error,
                content: "Something went wrong while saving your kernel",
                id: "saveKernelError",
              },
            });
          }
        } else {
          removeCachedContent();
          if (alertSubscribers) {
            shareKernel({
              shareKernel: {
                notes: message ?? "",
                userIds: arrID,
                type: "CREATE",
                alertSubscriber: alertSubscribers,
              },
              kernelId: result.data.id,
            });
          }
          banner.send({
            type: "ADD_BANNER",
            banner: {
              theme: BannerTheme.Success,
              content: "Your Trove just got that much more meaningful! 🎉",
              id: "addKernelSuccess",
            },
          });
          history.push(`/kernel/${result.data.id}`);
        }
      });
    }
  };

  if (!userId || !collection) {
    return <Loading />;
  }

  // If this user is trying to view the edit page for someone else's kernel we
  // should redirect them to the normal view page
  if (kernel && kernel.creatorId !== userId) {
    return <Redirect to={`/kernel/${kernel.id}`} />;
  }
  if (!collectionId && !kernel) {
    return <Redirect to={`/`} />;
  }

  return (
    <div>
      {loadingInsert && <LoadingSpinner />}
      {progress ? <ProgressBar progress={progress} /> : null}
      <Modal
        className={`Search ${addLink === "Link" ? "Search-link" : ""}`}
        toggleModal={toggleModal}
        visible={state.modalVisible}
      >
        <div className="title-modal">{state.title}</div>
        {addLink !== "Link" ? (
          <>
            <div className="title-link">{addLink}</div>
            <input
              className="search-modal"
              placeholder="Search"
              type="text"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setLoading(true);
              }}
            />
          </>
        ) : (
          <div className="container-search-link">
            <div className="title-link">URL</div>
            <input
              className="search-modal"
              placeholder="Enter Link"
              type="text"
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                setErrorLink("");
              }}
            />
            {errorLink ? (
              <div className="validation-message-input">{errorLink}</div>
            ) : null}
            <div className="title-link">Optional text</div>
            <input
              className="search-modal"
              placeholder="Description"
              type="text"
              value={textOption}
              onChange={(e) => setTextOption(e.target.value)}
            />
            <div className="footer-exit">
              <div className="btn-cancel" onClick={() => toggleModal()}>
                Cancel
              </div>
              <div
                className="btn-add"
                onClick={() => {
                  handleAddLink();
                }}
              >
                Add
              </div>
            </div>
          </div>
        )}
        {addLink !== "Link" && (
          <div className="container-data-search">
            {loading && <div className="loader" />}
            {data?.length !== 0 && addLink === "YouTube"
              ? renderDataYoutube(data)
              : null}
            {data?.length !== 0 && addLink === "Location"
              ? renderDataLocation(data)
              : null}
            {data?.length !== 0 && addLink === "Book"
              ? renderDataBook(data)
              : null}
            {data?.length !== 0 && addLink === "TV/Movie"
              ? renderDataTvMovie(data)
              : null}
            {data?.length !== 0 && addLink === "Music"
              ? renderDataMusicPodcast(data)
              : null}
            {data?.length !== 0 && addLink === "Podcast"
              ? renderDataMusicPodcast(data)
              : null}
          </div>
        )}
      </Modal>

      <Modal
        className="Exit"
        toggleModal={() => setPopupExit(false)}
        visible={popupExit}
      >
        <div className="title-modal">{"Exit without saving?"}</div>
        <div className="Content-exit">
          <div className="title-exit">Are you sure you want to exit?</div>
          <div className="description-exit">
            {" "}
            Leaving the page now will not save any changes or updates made.
          </div>
        </div>
        <div className="footer-exit">
          <div className="btn-cancel" onClick={() => setPopupExit(false)}>
            Cancel
          </div>
          <div className="btn-exit">
            <Link
              to={
                kernel?.id
                  ? `kernel/${kernel.id}`
                  : `collection/${collection?.id}`
              }
              onClick={removeCachedContent}
            >
              Exit
            </Link>
          </div>
        </div>
      </Modal>
      <ConfirmDraftFallback
        visible={visibleDaftFallback}
        onClose={() => {
          setVisibleDaftFallback(false);
          removeCachedContent();
        }}
        onConfirmed={() => {
          setVisibleDaftFallback(false);
          if (cachedContent) {
            loadDataKernel(cachedContent.data);
            setTitle(cachedContent.title);
          }
        }}
      />
      {alertSubscriber && (
        <AlertSubscribers
          isShow={alertSubscriber}
          toggleModal={() => setAlertSubscriber(false)}
          callBackSaveShare={handleSubmit}
        />
      )}

      <div
        className="kernel-form container-fluid"
        style={{ height: height - 56 }}
      >
        <div className="flex flex-col md:flex-row">
          <div className="md:w-4/12 w-full">
            <input
              type={"file"}
              name="image"
              ref={inputImgRef}
              style={{ display: "none" }}
              accept="image/png, image/gif, image/jpeg, image/heic, video/mp4, .mov"
              onChange={onImageChange}
            />
            <div className="container_iframe">
              {arrayBtnEditor.map((item, ind) => {
                return (
                  <div
                    key={ind}
                    className={`ic_action ${item.class} ${item.type === addLink ? "active" : ""
                      }`}
                    onClick={() => handleSelectLink(item?.type)}
                  >
                    <img
                      className="icon_iframe"
                      alt="Text"
                      src={
                        theme === Theme.Light
                          ? (item.type === addLink ? item.image : item.image_active)
                          : (item.type === addLink ? item.image_active : item.image)
                      }
                    />
                    <div className="text_iframe">{item.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="md:w-8/12 w-full">
            <div className="breadcrumb-kernel">
              <div className="breadcrumb-header">Collections /</div>
              <div
                className="breadcrumb-title"
                onClick={() => history.goBack()}
              >
                {collection?.title}
              </div>
            </div>

            <input
              maxLength={60}
              className="title_kernel"
              placeholder="Title"
              type="text"
              value={title}
              onChange={(e) => onChangeTitle(e.target.value)}
            />
            <div className="validation-message">{validateMsg}</div>
            {/* Height : header-trove = 80, breadcrumb = 50, input title = 50*, overlay = 60 */}
            <ReactQuill
              style={{ height: height - 80 - 50 - 50 - 60, width: "100%" }}
              id="react-quill-editor"
              className="react-quill-custom"
              ref={quillRef}
              modules={{
                toolbar: {
                  container: "#toolbar",
                },
              }}
              placeholder="Start composing content..."
              onChange={onChange}
            />

            {CustomToolbar(isShowToolbar)}
            <div
              className={`container-exit`}
              onClick={() => setPopupExit(true)}
            >
              <img className="icon_exit" alt="Exit" src={FAB} />
            </div>
            <div
              className={`container-save`}
              onClick={() =>
                validateMsg.length === 0 &&
                validateContentString(title) &&
                validateContentString(content) &&
                setAlertSubscriber(true)
              }
            >
              <img
                className={
                  (validateMsg.length === 0 &&
                    validateContentString(title)) &&
                    validateContentString(content)
                    ? "icon_exit"
                    : "icon_exit disabled"
                }
                alt="Save"
                src={save_kernel}
              />
            </div>
          </div>
          {isShowToolbar && <div className={`overlay`}></div>}
        </div>
      </div>
    </div>
  );
};

export default KernelForm;
