import { useActor } from "@xstate/react";
import { useHistory } from "react-router-dom";
import { Interpreter } from "xstate";
import {
  AccountWizardMachineContext,
  AccountWizardMachineEvent,
} from "../../state/xstate/accountWizard";
import ModalWrapper from "../modal/ModalWrapper";

const Intro = ({
  service,
}: {
  service: Interpreter<
    AccountWizardMachineContext,
    any,
    AccountWizardMachineEvent
  >;
}) => {
  const [state, send] = useActor(service);
  const history = useHistory();
  if (!state.matches("intro")) {
    return null;
  }
  return (
    <ModalWrapper showing={true} onClose={() => history.push("/welcome")}>
      <div className="flex flex-col rounded-xl text-center py-12 px-4 md:p-[96px_128px] md:max-w-[720px]">
        <h1 className="font-bold text-2xl font-raleway">
          We are so glad you found Trove Collective — where authentic content
          sharing and discovery thrives!
        </h1>
        <p className="mt-6">
          Right now we are only able to add accounts via referrals. If you don’t
          have a referral code, please join our waitlist and we will add you as
          soon as possible!
        </p>
        <div className="mt-12">
          <div>
            <button
              className="bg-gold text-black p-4 rounded mt-4"
              style={{ width: 320, maxWidth: "100%" }}
              onClick={() => send("NEXT")}
            >
              I have a referral code
            </button>
          </div>
          <div>
            <a
              href="https://trovecollective.us7.list-manage.com/subscribe?u=3a0b4611662724b01b54aefac&id=5785ea09ae&utm_source=webapp"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="bg-green text-white p-4 rounded mt-4"
                style={{ width: 320, maxWidth: "100%" }}
              >
                Join our waitlist
              </button>
            </a>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Intro;
