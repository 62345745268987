import Button from "../Button";
import { ReactComponent as Copy } from "../../assets/copy.svg";
import { useGetReferralCodesQuery } from "../../api/troveApi";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";
import { BannerTheme } from "../../state/xstate/banner";

const Settings = () => {
  const { userId } = useAppSelector(selectCurrentUser);
  const { data: referralCodes } = useGetReferralCodesQuery(userId || skipToken);
  const { banner } = useContext(XState);
  const { send } = banner;

  return (
    <div
      className="flex flex-col rounded-xl text-center px-8 pb-12 py-6"
      style={{ width: 600, maxWidth: "100%" }}
    >
      <h1 className="font-raleway font-bold text-lg text-left mb-10">
        Invite Friends
      </h1>
      <p className="mb-8">
        Invite 10 friends! Send a code to each friend, and when they sign up
        they can start sharing meaningful and inspiring content. Let’s create a
        truly authentic social platform together! 💪
      </p>
      {referralCodes?.map(({ code }) => (
        <Button
          key={code}
          className="!text-left bg-very-light-gray mb-2 flex justify-between items-center"
          onClick={() => {
            navigator.clipboard.writeText(code).then(() =>
              send({
                type: "ADD_BANNER",
                banner: {
                  id: code,
                  content: "Copied to clipboard",
                  theme: BannerTheme.Information,
                },
              })
            );
          }}
        >
          {code}
          <Copy className="fill-transparent" />
        </Button>
      ))}
    </div>
  );
};

export default Settings;
