import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Error {
  message: string;
  code: string;
}

export interface ErrorsState {
  errors: Error[];
}

const initialState: ErrorsState = {
  errors: [],
};

export const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    newError: (state: ErrorsState, action: PayloadAction<Error>) => {
      state.errors = [...state.errors, action.payload];
    },
  },
});

export const { newError } = errorsSlice.actions;

export default errorsSlice.reducer;
