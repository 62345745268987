import { useCallback, useContext, useRef, useState, useEffect } from "react";
import { fetchExploreCollections, fetchLimitExploreCollections } from "../../api/algoliaApi";
import {
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetHashtagsMutation,
  useGetPublicHashtagsMutation,
  useGetSubscriptionsUserMutation,
} from "../../api/troveApi";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import { Modal } from "../../state/xstate/modal";
import { XState } from "../../state/xstate/XStateContext";
import { CollectionExplore, Subscription } from "../../types/collection";
import { SearchResults } from "../../types/search";
import { MAX_ITEM_PER_PAGE } from "../../utils/constants";
import LoadingSpinner from "../LoadingSpinner";
import FilterHashtags from "./FilterHashtags";
import { InfinityScroll } from "./InfinityScroll";
import ItemCollection from "./ItemCollection";

interface Props {
  categoriesForUser: string[],
  selectedCategory: string,
}

const ExploreCollections = (props: Props) => {
  const { selectedCategory, categoriesForUser } = props;
  const { userId } = useAppSelector(selectCurrentUser);
  const { modal } = useContext(XState);
  const { send } = modal;
  const [getHashtags] = useGetHashtagsMutation();
  const [getPublicHashtags] = useGetPublicHashtagsMutation();
  const [createSubscription] = useCreateSubscriptionMutation();
  const [deleteSubscription] = useDeleteSubscriptionMutation();
  const [getSubscriptionsUser] = useGetSubscriptionsUserMutation();

  const [collections, setCollections] = useState<SearchResults<CollectionExplore>>({
    hits: [],
    page: 0,
    nbHits: 0,
    nbPages: 0,
    hitsPerPage: 0,
    exhaustiveNbHits: true,
  });
  const [hashtagsData, setHashtagsData] = useState<string[]>([]);
  const [filteredCollections, setFilteredCollections] = useState<CollectionExplore[]>([]);
  const [hashtagsAPI, setHashtagsAPI] = useState<string[]>([]);
  const [publicHashtagsAPI, setPublicHashtagsAPI] = useState<string[]>([]);
  const [hashtagSelected, setHashtagSelected] = useState<string>("");
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const infinityRef = useRef<any>(null);

  const getCollections = (categories: string[], page: number, onLoadSuccess: () => void, onLoadFailure: () => void) => {
    fetchExploreCollections(
      page,
      userId ?? "",
      hashtagSelected,
      categories,
    ).then(res => {
      setLoading(false);
      if (res.value) {
        onLoadSuccess();
        const result = res.value;
        setCollections({
          ...result,
          hits: page === 0 ? [
            ...result.hits,
          ] : [
            ...collections.hits,
            ...result.hits,
          ]
        });
      } else {
        onLoadFailure();
      }
    }).catch(_err => {
      onLoadFailure();
      setLoading(false);
    });
  }

  const filterCollections = (categories: string[], page: number, onLoadSuccess: () => void, onLoadFailure: () => void) => {
    if (filteredCollections.length === 0 && page === 0) {
      fetchLimitExploreCollections(
        100,
        userId ?? "",
        categories
      ).then(res => {
        setLoading(false);
        if (res.value) {
          const result = res.value;
          const filteredData = result.hits.filter(item => item.hashtags && item.hashtags.includes(hashtagSelected));
          setFilteredCollections(filteredData);
          const nbPages = Math.trunc(filteredData.length / MAX_ITEM_PER_PAGE);
          setCollections({
            ...result,
            nbPages: nbPages,
            hits: [
              ...filteredData.splice(0, MAX_ITEM_PER_PAGE),
            ]
          });
          onLoadSuccess();
        } else {
          onLoadFailure();
        }
      }).catch(_err => {
        onLoadFailure();
        setLoading(false);
      })
    } else {
      setCollections({
        ...collections,
        hits: [
          ...collections.hits,
          ...filteredCollections.splice(page * MAX_ITEM_PER_PAGE, (page + 1) * MAX_ITEM_PER_PAGE),
        ]
      });
      onLoadSuccess();
      setLoading(false);
    }
  }

  const onLoad = (page: number, onLoadSuccess: () => void, onLoadFailure: () => void) => {
    let categories: string[] = [selectedCategory];
    if (selectedCategory.includes("Latest")) {
      categories = [];
    } else if (selectedCategory.includes("ForYou")) {
      categories = [...categoriesForUser];
    }
    if (categories.length === 0) {
      if (hashtagSelected.length === 0) {
        getCollections(categories, page, onLoadSuccess, onLoadFailure);
      } else {
        filterCollections(categories, page, onLoadSuccess, onLoadFailure);
      }
    } else {
      getCollections(categories, page, onLoadSuccess, onLoadFailure);
    }
  }

  const onPressSubscribe = (id: string) => {
    if (userId) {
      setLoading(true);
      const subscribed = subscriptions && subscriptions.findIndex(e => e.id === id) !== -1
      if (subscribed) {
        deleteSubscription(id).then((res: any) => {
          if (!res.hasOwnProperty("error")) {
            refreshSubscriptions()
          } else {
            setLoading(false);
          }
        }).catch(_err => {
          setLoading(false);
        });
      } else {
        createSubscription(id).then((res: any) => {
          if (!res.hasOwnProperty("error")) {
            refreshSubscriptions()
          } else {
            setLoading(false);
          }
        }).catch(_err => {
          setLoading(false);
        });
      }
    } else {
      send({ type: "SHOW_MODAL", modal: Modal.ModalExplore });
    }
  }

  const refreshSubscriptions = useCallback(() => {
    getSubscriptionsUser().then((res: any) => {
      if (!res.hasOwnProperty("error")) {
        setSubscriptions(res.data ?? []);
      }
      setLoading(false);
    }).catch(_err => {
      setLoading(false);
    });
  }, [getSubscriptionsUser, setSubscriptions]);

  useEffect(() => {
    if (userId) {
      getHashtags({
        type: selectedCategory,
        limit: 24,
      }).then((res: any) => {
        if (res.hasOwnProperty("error")) {
          setHashtagsAPI([]);
        } else {
          setHashtagsAPI(res.data ?? []);
        }
      }).catch(_err => {
        setHashtagsAPI([]);
      });
      refreshSubscriptions();
    } else {
      getPublicHashtags({
        type: selectedCategory,
        limit: 24,
      }).then((res: any) => {
        if (res.hasOwnProperty("error")) {
          setPublicHashtagsAPI([]);
        } else {
          setPublicHashtagsAPI(res.data ?? []);
        }
      }).catch(_err => {
        setPublicHashtagsAPI([]);
      });
    }
    setHashtagSelected("");
  }, [userId, getPublicHashtags, getHashtags, refreshSubscriptions, selectedCategory]);

  useEffect(() => {
    if (userId && hashtagsAPI.length > 0) {
      setHashtagsData(hashtagsAPI);
    } else if (publicHashtagsAPI.length > 0) {
      setHashtagsData(publicHashtagsAPI);
    } else {
      setHashtagsData([]);
    }
  }, [hashtagsAPI, publicHashtagsAPI, userId]);

  useEffect(() => {
    if (hashtagSelected.length === 0) {
      setFilteredCollections([]);
    }
    if (infinityRef.current) {
      infinityRef.current.reloadData();
    }
  }, [hashtagSelected, selectedCategory]);

  return <div
    style={{ maxWidth: 1200 }}
    className="flex flex-col md:pr-8 md:pl-0 sm:pt-6 mt-2"
    id="collections"
  >
    {loading && <LoadingSpinner />}
    {hashtagsData.length > 0 && <FilterHashtags
      hashtags={hashtagsData}
      onChangeHashtag={(hashtag) => {
        setLoading(true);
        setHashtagSelected(hashtag);
      }}
    />}
    <InfinityScroll
      containerStyle={"grow overflow-y-auto overflow-x-hidden thin-scrollbar pb-8 px-4 mt-4 md:pr-8 md:pl-0"}
      ref={infinityRef}
      onLoad={onLoad}
      data={collections.hits}
      initialPage={0}
      enableLoadMore
      hasNext={collections.page < collections.nbPages}
      renderItem={(item: any, _index: any) =>
        <ItemCollection
          onPressSubscribe={onPressSubscribe}
          key={item.id}
          collection={item}
          subscribed={subscriptions ? subscriptions.findIndex(e => e.id === item.id) !== -1 : false}
        />
      }
    />
  </div>
}

export default ExploreCollections;