import { useAppSelector } from "../../state/hooks";

import Button from "../Button";
import { selectCurrentUser } from "../../state/reducers/auth";
import { Collection } from "../../types/collection";
import {
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetSubscriptionsForUserQuery,
} from "../../api/troveApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";
import { Modal } from "../../state/xstate/modal";

const CollectionSubscribeButton = ({
  className,
  collection,
}: {
  className: string;
  collection: Collection;
}) => {
  const { userId } = useAppSelector(selectCurrentUser);
  const { modal } = useContext(XState);

  const { data: subscriptions } = useGetSubscriptionsForUserQuery(
    userId ? null : skipToken
  );
  const subscribed = subscriptions?.some((s) => s.id === collection.id);

  const [createSubscription] = useCreateSubscriptionMutation();
  const [deleteSubscription] = useDeleteSubscriptionMutation();

  const onClick = () => {
    if (subscribed) {
      deleteSubscription(collection.id);
    } else {
      createSubscription(collection.id);
    }
  };

  if (!userId) {
    return (
      <Button
        className={className}
        onClick={() => modal.send({ type: "SHOW_MODAL", modal: Modal.Welcome })}
      >
        Subscribe
      </Button>
    );
  }

  return (
    <Button onClick={onClick}>
      {subscribed ? "Unsubscribe" : "Subscribe"}
    </Button>
  );
};

export default CollectionSubscribeButton;
