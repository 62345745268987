import { useContext, useEffect } from "react";
import { auth } from "../api/firebase";
import { useActor } from "@xstate/react";
import { XState } from "../state/xstate/XStateContext";

export const useAuth = () => {
  const { authentication: authService } = useContext(XState);
  const [state, send] = useActor(authService);

  useEffect(() => {
    const unsubscribeFromAuthStateChanged = auth.onAuthStateChanged((user) => {
      if (user) {
        user?.getIdToken().then((token) => {
          send({
            type: "REPORT_IS_LOGGED_IN",
            userDetails: { token, user },
          });
          (window as any).analytics.identify(user.uid, { email: user.email });
        });
      } else {
        send({ type: "REPORT_IS_LOGGED_OUT" });
        (window as any).analytics.reset();
      }
    });
    const unsubscribeFromIdTokenChanged = auth.onIdTokenChanged((user) => {
      if (user) {
        send({
          type: "TOKEN_UPDATED",
          // @ts-ignore
          userDetails: { token: user.accessToken, user },
        });
      } else {
        send({ type: "REPORT_IS_LOGGED_OUT" });
        (window as any).analytics.reset();
      }
    });

    const unsubscribe = () => {
      unsubscribeFromAuthStateChanged();
      unsubscribeFromIdTokenChanged();
    };

    return unsubscribe;
  }, [send]);

  return { state, send };
};
