import './LoadingSpinner.css';

const LoadingSpinner = () => (
    <div
        className={`popup-loading-spinner`}
    >
        <div className="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default LoadingSpinner