import { useSelector } from "@xstate/react";
import { useContext } from "react";
import { BannerTheme } from "../state/xstate/banner";
import { XState } from "../state/xstate/XStateContext";
import { Transition } from "@headlessui/react";
import classNames from "classnames";

const BannerComponent = () => {
  const { banner } = useContext(XState);
  const banners = useSelector(banner, (state) => state.context.banners);
  const { send } = banner;

  return (
    <div className="z-20 fixed flex flex-col justify-center w-10/12 max-w-lg left-0 right-0 m-auto bottom-4">
      {banners.map((banner) => (
        <Transition
          appear={true}
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          key={banner.id}
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={true}
        >
          <div
            className={classNames("rounded-lg w-full my-2 transition-all", {
              "bg-neutral-800": [
                BannerTheme.Information,
                BannerTheme.Success,
              ].includes(banner.theme),
              "bg-red-600": [BannerTheme.Error].includes(banner.theme),
            })}
          >
            <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
              <div className="flex justify-between items-center text-white font-bold text-base">
                {banner.content}
                <div className="flex items-center">
                  <button
                    type="button"
                    className="flex h-6 w-6 justify-center items-center rounded-md focus:outline-none focus:ring-2 focus:ring-gold"
                    onClick={() =>
                      send({ type: "DISMISS_BANNER", bannerId: banner.id })
                    }
                  >
                    <span className="sr-only">Dismiss</span>
                    <span
                      className="text-white flex items-center"
                      aria-hidden="true"
                    >
                      ✕
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      ))}
    </div>
  );
};

export default BannerComponent;
