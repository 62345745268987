import { ReactNode } from "react";
import Banners from "./Banners";

const LoginLayout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      {children}
      <Banners />
      <div className="invisible md:visible">
        <img
          alt=""
          style={{ top: 0, right: "39%", zIndex: 6 }}
          className="rounded-xl absolute"
          src="/images/unsplash__-fitCXT_uQ.png"
        />
        <img
          alt=""
          style={{ top: "25%", right: "30%", zIndex: 4 }}
          className="rounded-xl absolute"
          src="/images/unsplash_7x8F4DTjZ2s.png"
        />
        <img
          alt=""
          style={{ bottom: 0, right: "7.8%", zIndex: 1 }}
          className="rounded-xl absolute"
          src="/images/unsplash_mzBtcVkoP6I.png"
        />
        <img
          alt=""
          style={{ top: 0, right: "3.7%", zIndex: 6 }}
          className="rounded-xl absolute"
          src="/images/unsplash_SSrV93gHS64.png"
        />
        <img
          alt=""
          style={{ top: "31.5%", right: 0, zIndex: 4 }}
          className="rounded-xl absolute"
          src="/images/unsplash_szG9kzMLcPE.png"
        />
        <img
          alt=""
          style={{ top: "6%", right: "12.4%", zIndex: 5 }}
          className="rounded-xl absolute"
          src="/images/unsplash_VD7cu7bKHmk.png"
        />
        <img
          alt=""
          style={{ top: "67.5%", right: "3.7%", zIndex: 8 }}
          className="rounded-xl absolute"
          src="/images/unsplash_wNRpvRmpRnI.png"
        />
        <img
          alt=""
          style={{ top: "51.8%", right: "26.8%", zIndex: 2 }}
          className="rounded-xl absolute"
          src="/images/unsplash_XgJ6i-WJrpo.png"
        />
        <img
          alt=""
          style={{ top: "57.8%", right: "31.2%", zIndex: 1 }}
          className="rounded-xl absolute"
          src="/images/unsplash_ZrsVVKG9vks.png"
        />
      </div>
    </div>
  );
};
export default LoginLayout;
