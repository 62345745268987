import { Redirect } from "react-router-dom";
import { useAppSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/reducers/auth";
import { useGetUserByIdQuery } from "../api/troveApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Loading from "./Loading";

const Home = () => {
  const { userId, loaded } = useAppSelector(selectCurrentUser);
  const { data: profile } = useGetUserByIdQuery(userId || skipToken);

  if (!loaded) {
    return <Loading />;
  }

  if (!userId) {
    return <Redirect to="/welcome" />;
  } else {
    if (profile) {
      return <Redirect to={`/profile/${profile.username}`} />;
    } else {
      return <Loading />;
    }
  }
};

export default Home;
