import axios from "axios";

export type Response<Type> = Promise<{ data: Type }>;

export const troveApi = axios.create({
  baseURL: process.env.REACT_APP_TROVE_API_BASE_URL,
});

export const createSubscription = (id: string): Response<null> => {
  return troveApi.post(`/subscribe-collections/${id}`);
};
