import { useState } from "react";
import { useLocation } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import Modal from "../modal/ModalKernelForm";
import { useGetListUserAlertQuery } from "../../api/troveApi";
import { selectCurrentUser } from "../../state/reducers/auth";
import { useAppSelector } from "../../state/hooks";
import { ReactComponent as Search } from "../../assets/search.svg";
import "./AlertSubscribers.css";
import ic_alert from "../../assets/ic_alert.png";
import ic_alertActive from "../../assets/ic_alertActive.png";
import ic_select from "../../assets/ic_select.png";
import ic_selected from "../../assets/ic_selected.png";
import { User } from "../../types/user";

interface AlertSubscribersProps {
  isShow: boolean;
  toggleModal: () => void;
  callBackSaveShare: (
    arrIdUserSelected: string[],
    message: string,
    alertSubscribers: boolean
  ) => void;
}

const AlertSubscribers = (props: AlertSubscribersProps) => {
  const [message, setMessage] = useState<string>("");
  const location = useLocation<any>();
  const { userId } = useAppSelector(selectCurrentUser);
  const collectionId = location?.state?.collectionId;
  const { data: listUser } = useGetListUserAlertQuery(
    userId ? collectionId : skipToken
  );
  const [inputSearch, setInputSearch] = useState<string>("");
  const [alertSubscribers, setAlertSubscribers] = useState<boolean>(false);
  const [arrIdUserSelected, setArrIdUserSelected] = useState<string[]>([]);

  const filter = (k: User): boolean => {
    const lowerCaseSearch = inputSearch.toLowerCase();
    return (k?.firstname + " " + k?.lastname)
      .toLowerCase()
      .includes(lowerCaseSearch);
  };

  const handleSelectUserAlert = (item: User) => {
    const findIndex = arrIdUserSelected.findIndex((it) => it === item.id);
    if (findIndex >= 0) {
      setArrIdUserSelected(arrIdUserSelected.filter((it) => it !== item.id));
    } else {
      setArrIdUserSelected([...arrIdUserSelected, item.id]);
    }
  };

  return (
    <Modal
      className="Alert-subscriber"
      toggleModal={props.toggleModal}
      visible={props.isShow}
    >
      <div className="title-modal">{"Alert Subscribers"}</div>
      <div className="view-alert">
        <div
          className="icon-alert"
          onClick={() => setAlertSubscribers(!alertSubscribers)}
        >
          <img
            className="icon-alert"
            alt="Text"
            src={alertSubscribers ? ic_alertActive : ic_alert}
          />
        </div>
        <div className="title-alert">Alert subscribers</div>
      </div>
      <div className="title-link">Message</div>
      <input
        disabled={!alertSubscribers}
        className={`search-modal ${!alertSubscribers ? "disabled" : ""}`}
        placeholder="Include a message with the update."
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <div className="flex items-center relative mt-4">
        <Search className="absolute left-4 fill-dark-gray-alert" />
        <input
          className="input !pl-12 search-modal"
          placeholder="Search"
          value={inputSearch}
          onChange={(e) => setInputSearch(e.target.value)}
        />
      </div>

      <div className="list-user-alert">
        {listUser &&
          listUser.length > 0 &&
          listUser?.filter(filter).map((item, ind) => {
            return (
              <div key={ind} className="user-alert ">
                <div className={"wrapInfoItem"}>
                  <img
                    className="avt-alert"
                    src={item.profilePicture}
                    alt={item?.firstname + item?.lastname}
                  />
                  <div className="name-user">
                    {(item?.firstname || "") + " " + (item?.lastname || "")}
                  </div>
                </div>
                <div
                  className="icon-select"
                  onClick={() => handleSelectUserAlert(item)}
                >
                  <img
                    className="icon-select"
                    alt="Text"
                    src={
                      arrIdUserSelected?.includes(item?.id)
                        ? ic_selected
                        : ic_select
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div className="footer-exit">
        <div className="btn-cancel" onClick={() => props.toggleModal()}>
          Cancel
        </div>
        <div
          className="btn-add"
          onClick={() =>
            props.callBackSaveShare(
              arrIdUserSelected,
              message,
              alertSubscribers
            )
          }
        >
          Save
        </div>
      </div>
    </Modal>
  );
};

export default AlertSubscribers;
