// This file is here because the HTML that the editor puts out is truly,
// horrible horrendous. It's so bad. And it's all inline styled and messed up
// and broken looking and really just terrible. Anyways, the methods in this
// file are meant to parse that terrible HTML, and try to get it into a state
// where it's actually manageable, and you can do things with it.

import classNames from "classnames";
import ReactDOMServer from "react-dom/server";

const ContentBlock = ({
  className,
  title,
  subtitle,
  body,
  image,
  link,
}: {
  body?: string;
  className?: string;
  subtitle: string;
  title: string;
  image: string;
  link: string;
}) => (
  <a href={link} className="!no-underline">
    <div
      className={classNames(
        "flex items-center dark:bg-light-gray bg-white rounded-lg p-4 my-4 max-w-full",
        className
      )}
    >
      {/*I know it looks funny to have text styles on an image, but our
      Instagram integration is super broken because nobody can write working
      software, so the alt text shows a lot, and I'm trying to make it look less
      like crap*/}
      <img
        alt={title}
        className="w-16 mr-4 rounded text-light-gray truncate shrink-0"
        src={image}
      />
      <div className="flex flex-col overflow-hidden flex-shrink">
        <h2 className="text-xl text-dark-gray dark:text-white font-bold truncate">
          {title}
        </h2>
        <h3 className="text-lg text-light-gray dark:text-neutral-300 truncate">
          {subtitle}
        </h3>
        <p className="text-lg text-light-gray dark:text-neutral-300 truncate">
          {body}
        </p>
      </div>
    </div>
  </a>
);

export const assert = (maybe: string | undefined | null): string => {
  if (maybe === null || maybe === undefined) {
    throw new Error("Failed to parse content");
  }
  return maybe;
};

export const extractOembedDetailsFromDomNode = (
  domNode: Element
): { description: string; image: string; title: string; url: string } => {
  const url = assert(domNode.getAttribute("href"));
  const title = assert(domNode.getElementsByClassName("title")[0]?.textContent);
  const description = assert(
    domNode.getElementsByClassName("authors")[0]?.textContent
  );
  const image = assert(
    domNode.getElementsByClassName("image-embed")[0]?.getAttribute("src")
  );

  return { url, title, description, image };
};

export const extractBookDetailsFromDomNode = (
  domNode: Element
): { authors: string; image: string; title: string; url: string } => {
  const url = assert(domNode.getAttribute("href"));
  const title = assert(domNode.getElementsByClassName("title")[0]?.textContent);
  const authors = assert(
    domNode.getElementsByClassName("authors")[0]?.textContent
  );
  const image = assert(
    domNode.getElementsByClassName("image-book")[0]?.getAttribute("src")
  );

  return { url, title, authors, image };
};

export const extractMovieDetailsFromDomNode = (
  domNode: Element
): {
  image: string;
  overview: string;
  releaseDate: string;
  title: string;
  url: string;
} => {
  const url = assert(domNode.getAttribute("href"));
  const title = assert(domNode.getElementsByClassName("title")[0]?.textContent);
  const releaseDate = assert(
    domNode.getElementsByClassName("release-date")[0]?.textContent
  );
  const overview = assert(
    domNode.getElementsByClassName("overview")[0]?.textContent
  );
  const image = assert(
    domNode.getElementsByClassName("image-movie")[0]?.getAttribute("src")
  );

  return { image, title, url, overview, releaseDate };
};

export const extractLocationDetailsFromDomNode = (
  domNode: Element
): {
  address: string;
  image: string;
  name: string;
  rating: string;
  url: string;
  placeId: string;
} => {
  const name = assert(domNode.getElementsByClassName("name")[0]?.textContent);
  // We use a broken URL in production because everyone is bad at their jobs
  const brokenUrl = assert(domNode?.getAttribute("href"));
  const placeId = assert(brokenUrl.match(/place_id:(.*)/)![1]);
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    name
  )}&query_place_id=${encodeURIComponent(placeId)}
`;
  // Nope, the code below is not wrong, the original HTML is just completely
  // wrong, full of typos, and really just genuinely, impressively bad.
  const rating = assert(
    domNode.getElementsByClassName("address")[0]?.textContent
  );
  const address = assert(
    domNode.getElementsByClassName("types")[0]?.textContent
  );
  const image = assert(
    domNode.getElementsByClassName("image-location")[0]?.getAttribute("src")
  );

  return { address, image, name, placeId, rating, url };
};

export const extractVideoDetailsFromDomNode = (
  domNode: Element
): { url: string } => ({
  url: assert(domNode.getElementsByTagName("video")[0]?.src),
});

const getClassFromFontSize = (fontSize: string): string => {
  const asNumber = Number(fontSize);
  if (asNumber > 4) {
    return "text-2xl pb-4";
  } else if (asNumber > 3) {
    return "text-lg pb-4";
  } else {
    return "";
  }
};

// This method pull broken stuff out and tries to reformat it to be less broken.
export const reformatBrokenHTMLElements = (html: string): Document => {
  const doc: Document = new DOMParser().parseFromString(html, "text/html");

  Array.from(doc.getElementsByTagName("font")).forEach((element) => {
    const newElement = doc.createElement("span");
    newElement.innerHTML = element.innerHTML;
    newElement.setAttribute(
      "class",
      getClassFromFontSize(element.getAttribute("size")!)
    );
    element.replaceWith(newElement);
  });
  Array.from(doc.getElementsByTagName("h2")).forEach((element) => {
    const newElement = doc.createElement("span");
    newElement.innerHTML = element.innerHTML;
    newElement.setAttribute("class", getClassFromFontSize("4"));
    element.replaceWith(newElement);
  });
  Array.from(doc.getElementsByTagName("h1")).forEach((element) => {
    const newElement = doc.createElement("span");
    newElement.innerHTML = element.innerHTML;
    newElement.setAttribute("class", getClassFromFontSize("5"));
    element.replaceWith(newElement);
  });
  Array.from(doc.getElementsByClassName("iframe-Location")).forEach(
    (element) => {
      const details = extractLocationDetailsFromDomNode(element);
      element.replaceWith(
        new DOMParser().parseFromString(
          ReactDOMServer.renderToString(
            <ContentBlock
              className="iframe-Location"
              image={details.image}
              link={details.url}
              subtitle={details.rating}
              body={details.address}
              title={details.name}
            />
          ),
          "text/html"
        ).body
      );
    }
  );
  Array.from(doc.getElementsByClassName("iframe-Book")).forEach((element) => {
    const details = extractBookDetailsFromDomNode(element);
    element.replaceWith(
      new DOMParser().parseFromString(
        ReactDOMServer.renderToString(
          <ContentBlock
            className="iframe-Book"
            image={details.image}
            link={details.url}
            subtitle={details.authors}
            title={details.title}
          />
        ),
        "text/html"
      ).body
    );
  });
  Array.from(doc.getElementsByClassName("iframe-Movie")).forEach((element) => {
    const details = extractMovieDetailsFromDomNode(element);
    element.replaceWith(
      new DOMParser().parseFromString(
        ReactDOMServer.renderToString(
          <ContentBlock
            className="iframe-Movie"
            image={details.image}
            link={details.url}
            subtitle={details.releaseDate}
            body={details.overview}
            title={details.title}
          />
        ),
        "text/html"
      ).body
    );
  });
  Array.from(doc.getElementsByClassName("embed-iframely")).forEach(
    (element) => {
      const details = extractOembedDetailsFromDomNode(element);
      element.replaceWith(
        new DOMParser().parseFromString(
          ReactDOMServer.renderToString(
            <ContentBlock
              image={details.image}
              link={details.url}
              subtitle={details.description}
              title={details.title}
            />
          ),
          "text/html"
        ).body
      );
    }
  );

  Array.from(doc.getElementsByClassName("iframe-embed")).forEach((element) => {
    const details = extractOembedDetailsFromDomNode(element);
    element.replaceWith(
      new DOMParser().parseFromString(
        ReactDOMServer.renderToString(
          <ContentBlock
            image={details.image}
            link={details.url}
            subtitle={details.description}
            title={details.title}
          />
        ),
        "text/html"
      ).body
    );
  });

  Array.from(doc.getElementsByClassName("iframe-video")).forEach((element) => {
    const details = extractVideoDetailsFromDomNode(element);
    element.replaceWith(
      new DOMParser().parseFromString(
        ReactDOMServer.renderToString(
          <video
            className="m-auto"
            controls
            src={details.url}
            poster={`${details.url}/ik-thumbnail.jpg`}
            width="300"
          />
        ),
        "text/html"
      ).body
    );
  });

  return doc;
};

export const extractSrcScriptFromHtml = (html: string): string[] => {
  const result: string[] = [];
  const doc: Document = new DOMParser().parseFromString(html, "text/html");
  Array.from(doc.getElementsByTagName("script")).forEach((script) => {
    const src = script.getAttribute("src");
    if (src && src.length > 0 && !result.includes(src)) {
      result.push(src);
    }
  });
  return result;
};
