import { useEffect, useState } from "react";
import classNames from "classnames";
import { ReactComponent as DownArrow } from "../../assets/ic_arr_down.svg";
import { ReactComponent as UpArrow } from "../../assets/ic_arr_up.svg";
import ThemePicker from "../ThemePicker";
interface Props {
  categoriesFromApi: string[],
  listCategoriesFromApi: string[],
  categoryActive: string,
  onChangeCategory: (category: string) => void
}

export const lasted = '💬 Latest';
const foryou = '👀 For You';

const YourCategories = (props: Props) => {
  let {
    categoriesFromApi: categoriesFromApiProps,
    listCategoriesFromApi: listCategoriesFromApiProps,
    categoryActive = lasted,
    onChangeCategory
  } = props;

  const [showMore, setShowMore] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    if (categoriesFromApiProps.length > 0 && listCategoriesFromApiProps.length > 0) {
      let categoriesFromApiClone = [...categoriesFromApiProps];
      let listCategoriesFromApiClone = [...listCategoriesFromApiProps];
      let tempCategories = categoriesFromApiClone.sort(compare).concat(listCategoriesFromApiClone.filter((c) => categoriesFromApiClone.indexOf(c) === -1).sort(compare));
      tempCategories = [foryou, lasted, ...tempCategories];
      setCategories(tempCategories);
    } else {
      const sorted = [...listCategoriesFromApiProps].sort(compare);
      setCategories([lasted, ...sorted]);
    }
  }, [categoriesFromApiProps, listCategoriesFromApiProps]);


  const compare = (item: any, anotherItem: any) => {
    const thisItem = item.split(' ').slice(1).toString().replace(/,/g, ' ').toUpperCase();
    const thatItem = anotherItem.split(' ').slice(1).toString().replace(/,/g, ' ').toUpperCase();
    let comparison = 0;
    if (thisItem > thatItem) {
      comparison = 1;
    } else if (thisItem < thatItem) {
      comparison = -1;
    }
    return comparison;
  }

  const ItemCategoriesPC = (item: string, index: number) => {
    return (
      <div
        className={classNames(
          "rounded-xl font-semibold px-4 py-3 my-2 w-48 min-w-max cursor-pointer",
          {
            "dark:bg-white bg-black dark:text-black text-white": categoryActive === item,
          }
        )}
        key={index}
        onClick={() => onChangeCategory(item)}
      >
        <span className="pr-2 w-4">{item}</span>
      </div>
    )
  }

  const ItemCategoriesMobi = (item: string, index: number) => {
    return (
      <div
        className="font-semibold px-2 py-3 w-48 min-w-max cursor-pointer flex justify-center flex-col"
        key={index}
      >
        <div
          onClick={() => onChangeCategory(item)}
          className="flex justify-center w-full pb-2"
        >
          <span className="px-2">{item}</span>
        </div>
        <div
          className={classNames(
            {
              "w-full h-1 bg-orange-300 rounded-t-2xl": categoryActive === item,
            }
          )}>
        </div>
      </div>
    )
  }

  return (
    <div className="px-4 sm:px-8">
      <div className="text-2xl font-bold pt-6 sm:pt-9">Explore</div>
      <div className="text-sm">New insights  and inspirations</div>
      <div className="text-base font-semibold pt-5 pb-2">Your categories</div>
      <div className="xs:flex sm:hidden overflow-x-auto overflow-y-hidden w-full hidden-scrollbar font-['Inter']" style={{ maxHeight: "calc(100vh - 280px" }}>
        {categories && categories.map((itemCategory, index) => {
          return ItemCategoriesMobi(itemCategory, index)
        })}
      </div>
      <div className="xs:hidden sm:block sm:overflow-y-auto thin-scrollbar font-['Inter']" style={{ maxHeight: "calc(100vh - 280px" }}>
        {categories ?
          (showMore ?
            categories.map((itemCategory, index) => {
              return ItemCategoriesPC(itemCategory, index)
            })
            :
            categories.slice(0, 8).map((itemCategory, index) => {
              return ItemCategoriesPC(itemCategory, index)
            })
          )
          :
          null
        }
      </div>
      {
        categories.length > 0 ?
          <div
            onClick={() => {
              setShowMore(!showMore)
            }}
            className='xs:hidden sm:block cursor-pointer px-4 pt-2'
          >
            {showMore ?
              <span className="flex flex-row items-center">
                See less
                <UpArrow className="w-4 h-5 pl-2" />
              </span>
              :
              <span className="flex flex-row items-center">
                See more
                <DownArrow className="w-4 h-5 pl-2" />
              </span>
            }
          </div>
          :
          null
      }
      <div className="fixed bottom-2 left-10 xs:hidden sm:block">
        <ThemePicker />
      </div>
    </div>
  )
}

export default YourCategories;