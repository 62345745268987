import { ReactComponent as Sun } from "../assets/sun.svg";
import { ReactComponent as Moon } from "../assets/moon.svg";
import { createContext, useContext } from "react";

export enum Theme {
  Light,
  Dark,
}

const defaultFromLocalStorage = (): Theme | null => {
  const serializedTheme = localStorage.getItem("theme");
  return serializedTheme ? Number(serializedTheme) : null;
};

const defaultFromBrowser = (): Theme | null => {
  if (window.matchMedia("(prefers-color-scheme: light)").matches) {
    return Theme.Light;
  } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
    return Theme.Dark;
  }
  return null;
};

export const defaultTheme = (): Theme => {
  return defaultFromLocalStorage() || defaultFromBrowser() || Theme.Light;
};

const ThemePicker = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div
      className="fill-neutral-400 stroke-neutral-400 dark:fill-neutral-300 dark:stroke-neutral-300 cursor-pointer flex items-center"
      onClick={() => {
        const newTheme = theme === Theme.Light ? Theme.Dark : Theme.Light;
        localStorage.setItem("theme", String(newTheme));
        setTheme(newTheme);
      }}
    >
      {theme === Theme.Dark ? <Sun width="16" /> : <Moon width="16" />}
    </div>
  );
};

export const VerbalThemePicker = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  return (
    <div
      className="fill-neutral-400 stroke-neutral-400 dark:fill-neutral-300 dark:stroke-neutral-300 cursor-pointer flex items-center whitespace-nowrap"
      onClick={() => {
        const newTheme = theme === Theme.Light ? Theme.Dark : Theme.Light;
        localStorage.setItem("theme", String(newTheme));
        setTheme(newTheme);
      }}
    >
      {theme === Theme.Dark ? "Light mode" : "Dark mode"}
    </div>
  );
};

export const ThemeContext = createContext<{
  theme: Theme;
  setTheme: (theme: Theme) => void;
}>({ theme: defaultTheme(), setTheme: () => {} });

export default ThemePicker;
