import { useAppSelector } from "../../state/hooks";
import { Kernel } from "../../types/kernel";
import { selectCurrentUser } from "../../state/reducers/auth";
import { ReactComponent as Ellipse } from "../../assets/ellipse.svg";
import { useState } from "react";
import Menu, { MenuState } from "../Menu";
import { useHistory, useLocation } from "react-router-dom";
import BookmarkButton from "../BookmarkButton";
import { useDeleteKernelMutation } from "../../api/troveApi";
import MoveKernelMenu from "./MoveKernelMenu";

enum KernelMenuState {
  Closed,
  KernelMenu,
  MoveKernelMenu,
}

const KernelMenu = ({ kernel }: { kernel: Kernel }) => {
  const { userId: currentUserId } = useAppSelector(selectCurrentUser);
  // We are approaching the complexity level where Xstate might be useful
  const [menu, setMenu] = useState<
    Omit<MenuState, "open"> & { state: KernelMenuState }
  >({
    state: KernelMenuState.Closed,
    x: undefined,
    y: undefined,
  });

  const location = useLocation();
  const history = useHistory();
  const [deleteKernel] = useDeleteKernelMutation();

  return (
    <div className="items-center hidden md:flex">
      {kernel.creatorId === currentUserId ? (
        <>
          <Ellipse
            className="dark:fill-white cursor-pointer"
            onClick={(e) => {
              if (menu.state === KernelMenuState.Closed) {
                setMenu({
                  x: e.pageX,
                  y: e.pageY,
                  state: KernelMenuState.KernelMenu,
                });
              } else {
                setMenu({
                  x: undefined,
                  y: undefined,
                  state: KernelMenuState.Closed,
                });
              }
            }}
          />
          {menu.state === KernelMenuState.KernelMenu && (
            <Menu
              className="w-56 font-bold"
              x={menu.x!}
              y={menu.y!}
              close={() =>
                setMenu({
                  state: KernelMenuState.Closed,
                  x: undefined,
                  y: undefined,
                })
              }
              items={[
                {
                  children: "Edit kernel",
                  onClick: () => {
                    history.push(`/kernel/${kernel?.id}/edit`);
                  },
                },
                {
                  children: "Move kernel",
                  close: false,
                  onClick: (e) => {
                    setMenu({
                      ...menu,
                      state: KernelMenuState.MoveKernelMenu,
                    });
                  },
                },
                {
                  children: <span>Delete kernel</span>,
                  onClick: () => {
                    if (
                      window.confirm(
                        `Are you sure you want to delete ${kernel.title}?`
                      )
                    ) {
                      deleteKernel({ kernelId: kernel.id }).then(() => {
                        if (location.pathname === `/kernel/${kernel.id}`) {
                          history.push(`/collection/${kernel.collectionId}`);
                        }
                      });
                    }
                  },
                },
              ]}
            />
          )}

          {menu.state === KernelMenuState.MoveKernelMenu && (
            <MoveKernelMenu
              close={() => {
                setMenu({
                  state: KernelMenuState.Closed,
                  x: undefined,
                  y: undefined,
                });
              }}
              x={menu.x!}
              y={menu.y!}
              kernel={kernel}
            />
          )}
        </>
      ) : (
        <BookmarkButton kernel={kernel} />
      )}
    </div>
  );
};

export default KernelMenu;
