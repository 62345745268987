import { Link, Redirect } from "react-router-dom";
import { useAppSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/reducers/auth";
import LoginLayout from "./LoginLayout";

const Welcome = () => {
  const { userId } = useAppSelector(selectCurrentUser);

  if (userId) {
    return <Redirect to="/" />;
  }

  return (
    <LoginLayout>
      <div className="relative flex flex-col m-auto md:ml-40 w-full md:w-80 h-full items-center justify-center z-10 px-4">
        <h1 className="font-raleway text-2xl font-bold">Welcome to Trove!</h1>
        <Link className="w-full" to="/signup">
          <button className="p-4 mt-8 w-full bg-gold text-black rounded-xl">
            Sign up
          </button>
        </Link>
        <Link className="w-full" to={{
            pathname: `/login`,
            state: {
              signInLinkedIn: false
            }
          }}>
          <button className="p-4 mt-4 w-full bg-white text-black rounded-xl">
            Already have an account
          </button>
        </Link>
      </div>
    </LoginLayout>
  );
};

export default Welcome;
