import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/reducers/auth";

declare global {
  interface Window {
    analytics: any;
  }
}

const Analytics = () => {
  const location = useLocation();
  const { userId: currentUserId, loaded } = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (loaded) {
      window.analytics.page(location.pathname, {
        client: "web",
        logged_in: !!currentUserId,
        current_user_id: currentUserId,
      });
    }
  }, [location, loaded, currentUserId]);

  return null;
};

export default Analytics;
