import { MouseEventHandler } from "react";
import { User } from "../../types/user";

const PersonalLinksForm = ({
  changes,
  current,
  submit,
  update,
}: {
  changes: Partial<User>;
  current: User;
  submit: MouseEventHandler<EventTarget & HTMLFormElement>;
  update: (changes: Partial<User>) => void;
}) => {
  return (
    <form
      className="overflow-y-scroll flex flex-col px-6 pb-4 bg-very-light-gray"
      onSubmit={submit}
    >
      <input type="submit" style={{ display: "none" }} />
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          Website
        </label>
        <input
          defaultValue={current.web}
          className="input text-sm"
          name="website"
          onChange={(e) => update({ ...changes, web: e.target.value })}
        />
      </div>
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          Instagram
        </label>
        <input
          defaultValue={current.instagram}
          className="input text-sm"
          name="instagram"
          onChange={(e) => update({ ...changes, instagram: e.target.value })}
        />
      </div>
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          Twitter
        </label>
        <input
          defaultValue={current.twitter}
          className="input text-sm"
          name="twitter"
          onChange={(e) => update({ ...changes, twitter: e.target.value })}
        />
      </div>
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          LinkedIn (personal)
        </label>
        <input
          defaultValue={current.linkedin}
          className="input text-sm"
          name="linkedIn"
          onChange={(e) => update({ ...changes, linkedin: e.target.value })}
        />
      </div>
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          LinkedIn (professional)
        </label>
        <input
          defaultValue={current.professionalLinkedIn}
          className="input text-sm"
          name="professionalLinkedIn"
          onChange={(e) =>
            update({ ...changes, professionalLinkedIn: e.target.value })
          }
        />
      </div>
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          Discord
        </label>
        <input
          defaultValue={current.discord}
          className="input text-sm"
          name="discord"
          onChange={(e) => update({ ...changes, discord: e.target.value })}
        />
      </div>
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          Pinterest
        </label>
        <input
          defaultValue={current.pinterest}
          className="input text-sm"
          name="pinterest"
          onChange={(e) => update({ ...changes, pinterest: e.target.value })}
        />
      </div>
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          Youtube
        </label>
        <input
          defaultValue={current.youtube}
          className="input text-sm"
          name="youtube"
          onChange={(e) => update({ ...changes, youtube: e.target.value })}
        />
      </div>
    </form>
  );
};

export default PersonalLinksForm;
