import classNames from "classnames";
import { Collection } from "../../types/collection";
import { User } from "../../types/user";
import CollectionComponent from "./Collection";
import Loading from "../Loading";

interface CollectionsProps {
  collections: Collection[] | undefined;
  isCurrentUser: boolean;
  subscriptions: string[] | undefined;
  user: User;
}

const Collections = ({
  collections,
  isCurrentUser,
  user,
}: CollectionsProps) => {
  if (!collections) {
    return <Loading />;
  }

  const { change_layout: useTileLayout } = user;

  return (
    <div
      className="collections sm:overflow-y-auto thin-scrollbar px-4 md:pr-8 md:pl-0"
      style={{ maxWidth: 1200 }}
    >
      <div
        className={classNames({
          "flex flex-wrap": useTileLayout,
          rows: !useTileLayout,
        })}
      >
        {collections.map((collection: Collection) => (
          <CollectionComponent
            key={collection.id}
            collection={collection}
            isCurrentUser={isCurrentUser}
            tile={useTileLayout}
            user={user}
          />
        ))}
      </div>
    </div>
  );
};

export default Collections;
