import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";
import Intro from "./Intro";
import ReferralCode from "./ReferralCode";
import AccountDetails from "./AccountDetails";
import LoginLayout from "../LoginLayout";
import { Redirect } from "react-router-dom";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";

const AccountWizard = () => {
  const { accountWizard } = useContext(XState);
  const { userId } = useAppSelector(selectCurrentUser);

  if (userId) {
    return <Redirect to="/" />;
  }

  return (
    <LoginLayout>
      <Intro service={accountWizard} />
      <ReferralCode service={accountWizard} />
      <AccountDetails service={accountWizard} />
    </LoginLayout>
  );
};

export default AccountWizard;
