import createSagaMiddleware from "redux-saga";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { troveApi } from "../api/troveApi";
import auth from "./reducers/auth";
import errors from "./reducers/errors";
import spotify from "./reducers/spotify";
import { saga } from "../api/sagas";
import content from "./reducers/content";

const persistConfig = {
  key: "content",
  storage,
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = combineReducers({
  auth,
  errors,
  spotify,
  cachedContent: persistReducer(persistConfig, content),
  [troveApi.reducerPath]: troveApi.reducer,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(troveApi.middleware, sagaMiddleware),
});

setupListeners(store.dispatch);
sagaMiddleware.run(saga);

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
