import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { User, UserPreview } from "../types/user";
import { Collection, Subscription } from "../types/collection";
import { RootState } from "../state/store";
import { Kernel, PostKernel, ShareKernel } from "../types/kernel";
import { bookmarksCollection } from "../content/utils";
import compact from "lodash/compact";
import jwt_decode from "jwt-decode";
import { auth } from "./firebase";

export const troveApi = createApi({
  reducerPath: "troveApi",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_TROVE_API_BASE_URL,
      prepareHeaders: async (headers, { getState }) => {
        let token = (getState() as RootState).auth.token;
        if (token) {
          // @ts-ignore
          const expiry: number = jwt_decode(token).exp;
          if (Date.now() / 1000 > expiry) {
            // This will trigger a refresh, and then the observer in
            // hooks/auth.ts will see the token change and update the state
            // properly.
            console.log("REFRESH_TOKEN");
            token = await auth.currentUser?.getIdToken(true)!;
          }
          headers.set("authorization", `Bearer ${token}`);
        }

        return headers;
      },
    }),
    { maxRetries: 3 }
  ),
  tagTypes: ["Collections", "Subscriptions", "Users", "Kernels", "Categories"],
  endpoints: (builder) => ({
    getUserPreview: builder.query<UserPreview, string>({
      query: (nameOrId) => `users/${nameOrId}/preview`,
      providesTags: ["Users"],
    }),
    getUserById: builder.query<User, string>({
      query: (id) => `users/${id}`,
      providesTags: ["Users"],
    }),
    updateUser: builder.mutation<
      User,
      Omit<User, "profilePicture"> & {
        profilePicture: { data: string; mime: string } | undefined;
      }
    >({
      query: (
        user: User & { profilePicture: { data: string; mime: string } }
      ) => ({
        url: `users`,
        method: "PUT",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    createUser: builder.mutation<
      User,
      Omit<User, "profilePicture"> & {
        profilePicture: { data: string; mime: string } | undefined;
        referralCode: string;
      }
    >({
      query: (user) => ({
        url: `users`,
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["Users"],
    }),
    createCollection: builder.mutation<
      Collection,
      Omit<
        Collection,
        "collaborators" | "id" | "kernelCount" | "nbSubscribers" | "updatedAt"
      >
    >({
      query: (collection: Collection) => ({
        url: "collections",
        method: "POST",
        body: collection,
      }),
      invalidatesTags: ["Collections"],
    }),
    updateCollection: builder.mutation<Collection, Partial<Collection>>({
      query: (collection: Collection) => ({
        url: `collections/${collection.id}`,
        method: "PUT",
        body: collection,
      }),
      invalidatesTags: ["Collections", "Kernels"],
    }),
    deleteCollection: builder.mutation<void, { id: string }>({
      query: ({ id }: { id: string }) => ({
        method: "DELETE",
        url: `collections/${id}`,
      }),
      invalidatesTags: ["Kernels", "Collections"],
    }),
    updateHashtags: builder.mutation<
      string[],
      { id: string; hashtags: string[] }
    >({
      query: ({ id, hashtags }) => ({
        url: `collections/${id}/hashtags`,
        method: "PUT",
        body: hashtags,
      }),
      onQueryStarted: async ({ id, hashtags }, { dispatch }) => {
        dispatch(
          troveApi.util.updateQueryData(
            "getCollection",
            { id },
            (collection) => ({
              ...collection,
              hashtags,
            })
          )
        );
      },
      invalidatesTags: ["Collections"],
    }),
    getCollectionsForUser: builder.query<
      Collection[],
      { id: string; currentUserId?: string | null }
    >({
      query: ({ id, currentUserId }) =>
        // If we are authed then we hit the endpoint that requires auth because
        // sometimes it returns extra kernels (like private ones) that the other
        // endpoint would not normally return
        currentUserId ? `collections?userId=${id}` : `users/${id}/collections`,
      providesTags: ["Collections"],
    }),
    getReferralCodes: builder.query<{ code: string }[], string>({
      query: (userId: string) => `users/${userId}/referral-codes`,
    }),
    getCollection: builder.query<Collection, { id: string }>({
      query: ({ id }) => `collections/${id}`,
      providesTags: ["Collections"],
    }),
    getSubscribers: builder.query<{ subscribers: any[] }, null>({
      query: () => `/users/subscribers`,
    }),
    getBookmarkedContent: builder.query<Collection, { id: string }>({
      query: () => `kernels`,
      transformResponse: (
        kernels: Kernel[],
        _meta: any,
        { id }: { id: string }
      ) =>
        bookmarksCollection({
          // The only way to tell that something is *bookmarked* content is that
          // it has no collection
          kernelCount: kernels.filter((k) => !k.collectionId).length,
          userId: id,
        }),
      providesTags: ["Kernels"],
    }),
    getKernel: builder.query<Kernel, { id: string; state?: string }>({
      query: ({ id, state }) =>
        state ? `kernels/${id}?state=${state}` : `kernels/${id}`,
      providesTags: ["Kernels"],
    }),
    getKernelsForCollection: builder.query<Kernel[], { collectionId: string }>({
      query: ({ collectionId }) => `collections/${collectionId}/kernels`,
      providesTags: ["Kernels"],
    }),
    getKernelsForPrivateCollection: builder.query<
      Kernel[],
      { collectionId: string }
    >({
      query: ({ collectionId }) => `kernels?collectionId=${collectionId}`,
      providesTags: ["Kernels"],
    }),
    getAllKernels: builder.query<Kernel[], null>({
      query: () => "/kernels",
      providesTags: ["Kernels"],
    }),
    deleteKernel: builder.mutation<void, { kernelId: string }>({
      query: ({ kernelId }: { kernelId: string }) => ({
        method: "DELETE",
        url: `kernels/${kernelId}`,
      }),
      invalidatesTags: ["Kernels", "Collections"],
    }),
    saveKernel: builder.mutation<
      Kernel,
      { kernelId: string; collectionId: string }
    >({
      query: ({
        collectionId,
        kernelId,
      }: {
        collectionId: string;
        kernelId: string;
      }) => ({
        method: "POST",
        url: compact([
          `kernels/${kernelId}/save`,
          collectionId ? `collectionId=${collectionId}` : null,
        ]).join("?"),
      }),
      invalidatesTags: ["Kernels", "Collections"],
    }),
    getListCategories: builder.query<string[], string>({
      query: (userId) => ({
        method: "GET",
        url: `categories`,
      }),
      providesTags: ["Categories"],
      extraOptions: { maxRetries: 0 },
    }),
    getCategoriesForUser: builder.query<string[], string>({
      query: (userId) => ({
        method: "GET",
        url: `users/${userId}/categories`,
      }),
      providesTags: ["Categories"],
      extraOptions: { maxRetries: 0 },
    }),
    updateCategoriesForUser: builder.mutation<
      string[],
      { userId: string; categories: string[] }
    >({
      query: ({ userId, categories }) => ({
        method: "POST",
        url: `users/${userId}/categories`,
        body: categories,
      }),
      extraOptions: { maxRetries: 0 },
      invalidatesTags: ["Categories"],
    }),
    getSubscriptionsForUser: builder.query<Subscription[], null>({
      query: () => `/subscribe-collections`,
      providesTags: ["Subscriptions"],
    }),
    createSubscription: builder.mutation<Collection, string>({
      query: (collectionId: string) => ({
        method: "POST",
        url: `subscribe-collections/${collectionId}`,
      }),
      onQueryStarted: async (id, { dispatch }) => {
        dispatch(
          troveApi.util.updateQueryData(
            "getSubscriptionsForUser",
            null,
            (subscriptions) => [...subscriptions, { id } as Subscription]
          )
        );
      },
      invalidatesTags: ["Subscriptions"],
    }),
    deleteSubscription: builder.mutation<Collection, string>({
      query: (collectionId: string) => ({
        method: "DELETE",
        url: `subscribe-collections/${collectionId}`,
      }),
      onQueryStarted: async (id, { dispatch }) => {
        dispatch(
          troveApi.util.updateQueryData(
            "getSubscriptionsForUser",
            null,
            (subscriptions) => subscriptions.filter((s) => s.id !== id)
          )
        );
      },
      invalidatesTags: ["Subscriptions"],
    }),
    getListUserAlert: builder.query<User[], string>({
      query: (collectionId) => `users/${collectionId}/alerts`,
      providesTags: ["Users"],
    }),
    createKernel: builder.mutation<Kernel, PostKernel>({
      query: (kernel: PostKernel) => ({
        method: "POST",
        url: "/kernels",
        body: kernel,
      }),
      invalidatesTags: ["Kernels"],
    }),
    shareKernel: builder.mutation<
      void,
      { shareKernel: ShareKernel; kernelId: string }
    >({
      query: ({
        shareKernel,
        kernelId,
      }: {
        shareKernel: ShareKernel;
        kernelId: string;
      }) => ({
        method: "POST",
        url: `/kernels/${kernelId}/share`,
        body: shareKernel,
      }),
      invalidatesTags: ["Kernels"],
    }),
    updateKernel: builder.mutation<
      Kernel,
      { kernel: PostKernel; kernelId: string }
    >({
      query: ({
        kernel,
        kernelId,
      }: {
        kernel: PostKernel;
        kernelId: string;
      }) => ({
        method: "PUT",
        url: `/kernels/${kernelId}`,
        body: kernel,
      }),
      invalidatesTags: ["Kernels"],
    }),
    getLocations: builder.mutation<any, { text: string }>({
      query: ({ text }: { text: string }) => ({
        method: "GET",
        url: `/locations?query=${text}`,
      }),
    }),
    getKernelById: builder.query<Kernel, { id: string }>({
      query: ({ id }) => ({
        method: "GET",
        url: `kernels/${id}`,
      }),
    }),
    verifyReferralCode: builder.query<boolean, string>({
      query: (code) => ({
        method: "POST",
        url: `/referral-code/validate?code=${code}`,
      }),
      extraOptions: { maxRetries: 0 },
    }),
    getUrlUploadVideo: builder.mutation<any, { fileSize: number }>({
      query: ({ fileSize }) => ({
        method: "POST",
        url: `/uploads/signed-url`,
        body: {
          mime: "video/mov",
          folder: "video",
          fileSize: fileSize,
        },
      }),
    }),
    resendEmail: builder.mutation<void, { userID: string }>({
      query: ({ userID }) => ({
        method: "POST",
        url: `/users/${userID}/email-verification`,
      }),
    }),
    getPublicHashtags: builder.mutation<
      string[],
      { type: string; limit: number }
    >({
      query: ({ type, limit }) => ({
        method: "GET",
        url: `/collections/public-hashtags?type=${type}&limit=${limit}`,
      }),
    }),
    getHashtags: builder.mutation<string[], { type: string; limit: number }>({
      query: ({ type, limit }) => ({
        method: "GET",
        url: `/collections/hashtags?type=${type}&limit=${limit}`,
      }),
    }),
    getCategories: builder.mutation<string[], void>({
      query: () => ({
        method: "GET",
        url: "/categories",
      }),
    }),
    getCategoriesUser: builder.mutation<string[], { userId: string }>({
      query: ({ userId }) => ({
        method: "GET",
        url: `/users/${userId}/categories`,
      }),
    }),
    getSubscriptionsUser: builder.mutation<Subscription[], void>({
      query: () => ({
        method: "GET",
        url: `/subscribe-collections`,
      }),
    }),
  }),
});

export const {
  useCreateCollectionMutation,
  useCreateKernelMutation,
  useCreateSubscriptionMutation,
  useCreateUserMutation,
  useDeleteCollectionMutation,
  useDeleteKernelMutation,
  useDeleteSubscriptionMutation,
  useGetAllKernelsQuery,
  useGetBookmarkedContentQuery,
  useGetListCategoriesQuery,
  useGetCategoriesForUserQuery,
  useGetCollectionQuery,
  useGetCollectionsForUserQuery,
  useGetKernelQuery,
  useGetKernelsForCollectionQuery,
  useGetKernelsForPrivateCollectionQuery,
  useGetListUserAlertQuery,
  useGetLocationsMutation,
  useGetReferralCodesQuery,
  useGetSubscribersQuery,
  useGetSubscriptionsForUserQuery,
  useGetUserByIdQuery,
  useGetUserPreviewQuery,
  useLazyGetCollectionsForUserQuery,
  useLazyVerifyReferralCodeQuery,
  useSaveKernelMutation,
  useShareKernelMutation,
  useUpdateCategoriesForUserMutation,
  useUpdateCollectionMutation,
  useUpdateHashtagsMutation,
  useUpdateKernelMutation,
  useUpdateUserMutation,
  useGetUrlUploadVideoMutation,
  useResendEmailMutation,
  useGetHashtagsMutation,
  useGetPublicHashtagsMutation,
  useGetCategoriesMutation,
  useGetCategoriesUserMutation,
  useGetSubscriptionsUserMutation,
} = troveApi;
