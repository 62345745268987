import { put, takeEvery } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { fetchResource as fetchResourceApi } from "../api/spotify_client";
import { createSubscription as createSubscriptionApi } from "../api/client";
import {
  resourceRequested as spotifyResourceRequested,
  resourceFetched as spotifyResourceFetched,
} from "../state/reducers/spotify";
import { newError } from "../state/reducers/errors";

export function* fetchSpotifyResource(
  action: PayloadAction<{ id: string; type: SpotifyResourceCategory }>
) {
  try {
    const { id, type } = action.payload;
    const resource: SpotifyResource = yield fetchResourceApi(id, type);
    yield put(spotifyResourceFetched({ type, resource }));
  } catch (error) {
    yield put(newError({ message: `${error}`, code: "api" }));
  }
}

export function* createSubscription(
  action: PayloadAction<{ collectionId: string; userId: string }>
) {
  try {
    yield createSubscriptionApi(action.payload.collectionId);
  } catch (error) {
    yield put(newError({ message: `${error}`, code: "api" }));
  }
}

export function* saga() {
  yield takeEvery(spotifyResourceRequested.type, fetchSpotifyResource);
}
