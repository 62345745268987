import { Link } from "react-router-dom";
import { useGetUserPreviewQuery } from "../../api/troveApi";
import Avatar from "./Avatar";

const AvatarLink = ({
  className,
  hideName,
  userId,
}: {
  className?: string;
  hideName?: boolean;
  userId: string;
}) => {
  const { data: user } = useGetUserPreviewQuery(userId);

  if (!user) {
    return null;
  }

  return (
    <Link
      key={user.id}
      className="font-raleway flex items-center w-fit"
      to={`/profile/${user.username}`}
    >
      <Avatar className={className} user={user} />
      {!hideName && (
        <>
          {user.firstname}&nbsp;{user.lastname}
        </>
      )}
    </Link>
  );
};

export default AvatarLink;
