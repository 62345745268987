import classNames from "classnames";

const Tab = ({
  active,
  onClick,
  title,
}: {
  active: boolean;
  onClick: () => void;
  title: string;
}) => {
  return (
    <span
      className={classNames("cursor-pointer font-raleway font-bold mx-3 pb-2", {
        "border-b-2 border-gold": active,
        "text-neutral-500": !active,
      })}
      onClick={onClick}
    >
      {title}
    </span>
  );
};

export default Tab;
