import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";

// ============================================================================
// This is from https://stackoverflow.com/a/26410127/1730004. I promise I'm not
// just writing crypto code by myself.
function byteToHex(byte: number) {
  return ("0" + byte.toString(16)).slice(-2);
}

export function generateId(len: number = 40) {
  var arr = new Uint8Array(len / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, byteToHex).join("");
}
// ============================================================================

export const config: FirebaseOptions = JSON.parse(
  process.env.REACT_APP_FIREBASE_CONFIG || "{}"
);

const app = initializeApp(config);
(window as any).app = app;

export const auth = getAuth();

// @ts-ignore
window.auth = auth;

export const signIn = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    alert(errorMessage);
    console.log(errorCode, errorMessage);
  });
};

export const signUp = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const signInWithGoogle = () => {
  return signInWithPopup(auth, new GoogleAuthProvider());
};

export const signInWithApple = (idToken: string, nonce: string) => {
  const appleProvider = new OAuthProvider("apple.com");
  appleProvider.addScope("email");
  appleProvider.addScope("name");
  const credential = appleProvider.credential({
    idToken: idToken,
    rawNonce: nonce,
  });
  signInWithCredential(auth, credential);
};

// @ts-ignore
window.signInWithApple = signInWithApple;

export const signInWithLinkedIn = (customToken: string) => {
  signInWithCustomToken(auth, customToken);
};

export const signOut = async () => await auth.signOut();
