import { useContext } from "react";
import { useUpdateCollectionMutation } from "../../api/troveApi";
import { selectCurrentUser } from "../../state/reducers/auth";
import { useInterpret } from "@xstate/react";
import collectionWizardMachine, {
  contextFromCollection,
} from "../../state/xstate/collectionWizard";
import { BannerTheme } from "../../state/xstate/banner";
import { XState } from "../../state/xstate/XStateContext";
import PickCategories from "../newCollection/PickCategories";
import EnterContent from "../newCollection/EnterContent";
import { useAppSelector } from "../../state/hooks";
import ModalWrapper from "../modal/ModalWrapper";
import { Collection } from "../../types/collection";

const EditCollectionWizard = ({
  onDone,
  collection,
}: {
  onDone: () => void;
  collection: Collection;
}) => {
  const { banner } = useContext(XState);
  const [updateCollection] = useUpdateCollectionMutation();
  const { userId } = useAppSelector(selectCurrentUser);
  const collectionWizard = useInterpret(collectionWizardMachine, {
    context: contextFromCollection(collection),
    actions: {
      done: onDone,
      success: () => {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            theme: BannerTheme.Success,
            content: <>🎉 &nbsp;&nbsp;Collection successfully updated!</>,
            id: "updateCollection",
          },
        });
      },
    },
    services: {
      createCollection: async (context) => {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            theme: BannerTheme.Information,
            content: <>Saving Collection</>,
            id: "updateCollection",
          },
        });
        await updateCollection({
          id: context.id,
          categories: context.categories,
          coverImage: context.coverImage!,
          description: context.description!,
          public: context.public,
          title: context.title!,
          userId: userId!,
        }).unwrap();
      },
    },
  });

  return (
    <ModalWrapper showing={true} onClose={onDone}>
      <PickCategories service={collectionWizard} />
      <EnterContent service={collectionWizard} />
    </ModalWrapper>
  );
};

export default EditCollectionWizard;
