import BasicInfoForm from "./BasicInfoForm";
import Button from "../Button";
import ChangeAvatar from "./ChangeAvatar";
import Loading from "../Loading";
import PersonalLinksForm from "./PersonalLinksForms";
import Tab from "../Tab";
import classNames from "classnames";
import { BannerTheme } from "../../state/xstate/banner";
import { Link } from "react-router-dom";
import { MouseEventHandler, useContext, useState } from "react";
import { ReactComponent as Pencil } from "../../assets/pencil.svg";
import { User } from "../../types/user";
import { XState } from "../../state/xstate/XStateContext";
import { selectCurrentUser } from "../../state/reducers/auth";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAppSelector } from "../../state/hooks";
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../api/troveApi";
import { pick } from "lodash";
import LoadingSpinner from "../LoadingSpinner";

enum EditProfileTab {
  BasicInfo,
  PersonalLinks,
}

const EditProfile = () => {
  const { banner, modal } = useContext(XState);
  const { send: sendModal } = modal;
  const { send: sendBanner } = banner;
  const { userId } = useAppSelector(selectCurrentUser);
  const { isLoading, data: profile } = useGetUserByIdQuery(userId || skipToken);
  const [updateUser] = useUpdateUserMutation();
  const [img, setImg] = useState<{
    data: string;
    mime: string;
    dataURL: string;
  }>();
  const [profileChanges, setProfileChanges] = useState<Partial<User>>({});
  const [tab, setTab] = useState<EditProfileTab>(EditProfileTab.BasicInfo);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);

  if (!userId) {
    return (
      <p>
        To edit your profile, first sign in:
        <Link to="/login">
          <Button>Login</Button>
        </Link>
      </p>
    );
  }

  if (isLoading || !profile) {
    return <Loading />;
  }

  const submit: MouseEventHandler<EventTarget & HTMLFormElement> = (e) => {
    e.preventDefault();

    if ((profileChanges.bio?.length || 0) > 400) {
      sendBanner("ADD_BANNER", {
        banner: {
          id: "bioTooLong",
          theme: BannerTheme.Error,
          content: "Bio must be less than 400 characters",
        },
      });
      return;
    }

    setLoadingUpload(true);
    updateUser({
      ...profile,
      ...profileChanges,
      profilePicture: img ? pick(img, ["data", "mime"]) : undefined,
    })
      .then((result) => {
        if (result.hasOwnProperty("error")) {
          sendBanner({
            type: "ADD_BANNER",
            banner: {
              content: "Something went wrong attempting to update your profile",
              id: "errorOnProfileUpdate",
              theme: BannerTheme.Error,
            },
          });
        } else {
          sendModal("HIDE_MODAL");
        }
        setLoadingUpload(false);
      })
      .catch((error) => {
        setLoadingUpload(false);
      });
  };

  return (
    <div
      className="flex flex-col text-center pt-6"
      style={{ width: 600, maxWidth: "100%", maxHeight: "75vh" }}
    >
      {loadingUpload && <LoadingSpinner />}
      <div>
        <h1 className="px-6 ml-2 font-raleway text-xl font-bold text-left">
          Edit Profile
        </h1>
        <ChangeAvatar onChange={setImg}>
          <div
            className="m-auto bg-center bg-cover h-20 w-20 rounded-lg mt-6 mb-8 flex items-center cursor-pointer"
            style={{
              backgroundImage: `url("${
                img?.dataURL || profile.profilePicture
              }")`,
            }}
          >
            <Pencil
              className={classNames(
                "m-auto h-8 w-8 p-2 backdrop-blur-sm bg-white/50 rounded-full"
              )}
            />
          </div>
        </ChangeAvatar>
        <div className="flex justify-center mb-4">
          <Tab
            title="Basic Info"
            active={tab === EditProfileTab.BasicInfo}
            onClick={() => setTab(EditProfileTab.BasicInfo)}
          />
          <Tab
            title="Personal Links"
            active={tab === EditProfileTab.PersonalLinks}
            onClick={() => setTab(EditProfileTab.PersonalLinks)}
          />
        </div>
      </div>
      {tab === EditProfileTab.BasicInfo && (
        <BasicInfoForm
          changes={profileChanges}
          current={profile}
          submit={submit}
          update={setProfileChanges}
        />
      )}
      {tab === EditProfileTab.PersonalLinks && (
        <PersonalLinksForm
          changes={profileChanges}
          current={profile}
          submit={submit}
          update={setProfileChanges}
        />
      )}
      <div className="bg-very-light-gray border-t border-neutral-300 flex justify-end sticky bottom-0 py-4 items-center rounded-b-lg px-4">
        <Button
          className="text-sm mx-2 px-4 bg-very-light-gray"
          onClick={() => sendModal("HIDE_MODAL")}
        >
          Cancel
        </Button>
        <Button
          onClick={submit}
          className="text-sm bg-dark-gray text-white px-4"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditProfile;
