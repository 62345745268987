const ReportAbuse = () => {
  return (
    <div
      className="flex flex-col rounded-xl text-center px-8 py-6 !text-left"
      style={{ width: 600, maxWidth: "100%" }}
    >
      <h1 className="font-raleway font-bold text-lg mb-10">Report Abuse</h1>
      <p className="mb-6">
        Misconduct of any kind, as outlined in our{" "}
        <a
          rel="noreferrer"
          className="underline text-blue-600"
          target="_blank"
          href="https://www.trovecollective.co/#footer"
        >
          Community Guidelines
        </a>
        , is unacceptable in the Trove Collective community. We are very sorry
        for your experience and we are thankful you are taking the time to
        report it.
      </p>
      <p className="mb-6">
        Please email us at{" "}
        <a
          href="mailto:admin@troveccollective.co"
          className="underline text-blue-600"
        >
          admin@trovecollective.co
        </a>{" "}
        with the following information:
      </p>
      <ul className="list-disc px-6 mb-6">
        <li>Screenshot of the event</li>
        <li>Username of the individual who breached the Guidelines</li>
      </ul>
      <p className="text-xs mb-6">
        Rest assured, we will do as much as we can to keep your anonymous during
        this process. If we need to disclose something regarding your identity,
        we will only do so with your permission.
      </p>
    </div>
  );
};

export default ReportAbuse;
