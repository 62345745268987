import { ReactComponent as Discord } from "../assets/discord.svg";
import { ReactComponent as Ellipse } from "../assets/ellipse.svg";
import { ReactComponent as Instagram } from "../assets/instagram.svg";
import { ReactComponent as LinkedIn } from "../assets/linkedin.svg";
import { ReactComponent as Pinterest } from "../assets/pinterest.svg";
import { ReactComponent as TikTok } from "../assets/tiktok.svg";
import { ReactComponent as Twitter } from "../assets/twitter.svg";
import { ReactComponent as Website } from "../assets/website.svg";
import { ReactComponent as YouTube } from "../assets/youtube.svg";
import { User } from "../types/user";
import RoundButton from "./RoundButton";
import Menu from "./Menu";
import { useContext, useState } from "react";
import { XState } from "../state/xstate/XStateContext";
import { Modal } from "../state/xstate/modal";
import { useUpdateUserMutation } from "../api/troveApi";
import sanitize from "sanitize-html";
import { urlify } from "../content/utils";

const ensureLink = (maybeLink: string): string =>
  maybeLink.startsWith("https://") ? maybeLink : `https://${maybeLink}`;

interface ProfileSummaryProps {
  isCurrentUser: boolean;
  user: User;
  subscribers: any[] | undefined;
}

type OpenMenu = {
  open: true;
  x: number;
  y: number;
};

type ClosedMenu = {
  open: false;
  x: number | undefined;
  y: number | undefined;
};

type MenuState = OpenMenu | ClosedMenu;

const ProfileSummary = ({
  isCurrentUser,
  user,
  subscribers,
}: ProfileSummaryProps) => {
  const [menu, setMenu] = useState<MenuState>({
    open: false,
    x: undefined,
    y: undefined,
  });
  const { modal } = useContext(XState);
  const { send } = modal;
  const [updateUser] = useUpdateUserMutation();

  return (
    <div className="px-8 sm:overflow-y-auto thin-scrollbar">
      <div
        className="bg-center bg-cover h-24 w-24 rounded-full my-8"
        style={{ backgroundImage: `url("${user.profilePicture}")` }}
      />
      <h1 className="font-raleway font-bold text-2xl">
        {user.firstname} {user.lastname}
      </h1>
      <div className="pt-2">
        <p dangerouslySetInnerHTML={{ __html: urlify(sanitize(user.bio)) }}></p>
      </div>
      {isCurrentUser && (
        <div className="text-center rounded-lg bg-white dark:bg-green border border-neutral-300 dark:border-green py-3 mt-8">
          <div className="font-bold" style={{ fontSize: 14 }}>
            {subscribers?.length || 0}
          </div>
          <div className="text-xs">Subscribers</div>
        </div>
      )}
      <div className="flex pt-8 items-center flex-wrap">
        {user.web && (
          <a href={user.web} target="_blank" rel="noreferrer">
            <RoundButton className="my-1">
              <Website />
            </RoundButton>
          </a>
        )}
        {user.instagram && (
          <a
            className="rounded-full"
            href={ensureLink(user.instagram)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <Instagram width="24" />
            </RoundButton>
          </a>
        )}
        {user.twitter && (
          <a
            className="rounded-full"
            href={ensureLink(user.twitter)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <Twitter width="16" height="16" />
            </RoundButton>
          </a>
        )}
        {user.linkedin && (
          <a
            className="rounded-full"
            href={ensureLink(user.linkedin)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <LinkedIn width="16" height="16" />
            </RoundButton>
          </a>
        )}
        {user.professionalLinkedIn && (
          <a
            className="rounded-full"
            href={ensureLink(user.professionalLinkedIn)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <LinkedIn width="16" height="16" />
            </RoundButton>
          </a>
        )}
        {user.tiktok && (
          <a
            className="rounded-full"
            href={ensureLink(user.tiktok)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <TikTok
                width="16"
                height="16"
                className="fill-black dark:fill-white"
              />
            </RoundButton>
          </a>
        )}
        {user.discord && (
          <a
            className="rounded-full"
            href={ensureLink(user.discord)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <Discord
                width="16"
                height="16"
                className="fill-black dark:fill-white"
              />
            </RoundButton>
          </a>
        )}
        {user.pinterest && (
          <a
            className="rounded-full"
            href={ensureLink(user.pinterest)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <Pinterest width="16" height="16" />
            </RoundButton>
          </a>
        )}
        {user.youtube && (
          <a
            className="rounded-full"
            href={ensureLink(user.youtube)}
            target="_blank"
            rel="noreferrer"
          >
            <RoundButton className="my-1">
              <YouTube width="16" height="16" />
            </RoundButton>
          </a>
        )}
        {isCurrentUser && (
          <RoundButton
            onClick={(e) =>
              setMenu(
                menu.open
                  ? { ...menu, open: false }
                  : { ...menu, open: true, x: e.pageX, y: e.pageY }
              )
            }
            className="cursor-pointer"
          >
            <Ellipse />
            {menu.open && (
              <Menu
                className="w-48"
                x={menu.x}
                y={menu.y}
                close={() => setMenu({ ...menu, open: false })}
                items={[
                  {
                    children: <div className="font-bold">Edit Profile</div>,
                    onClick: () => {
                      setMenu({ ...menu, open: false });
                      send({ type: "SHOW_MODAL", modal: Modal.EditProfile });
                    },
                  },
                  {
                    children: <div className="font-bold">Switch Layout</div>,
                    onClick: () => {
                      setMenu({ ...menu, open: false });
                      updateUser({
                        ...user,
                        change_layout: !user.change_layout,
                        profilePicture: undefined,
                      });
                    },
                  },
                  {
                    children: <div className="font-bold">Invite Friends</div>,
                    onClick: () => {
                      setMenu({ ...menu, open: false });
                      send({ type: "SHOW_MODAL", modal: Modal.InviteFriends });
                    },
                  },
                  {
                    children: <div className="font-bold">Settings</div>,
                    onClick: () => {
                      setMenu({ ...menu, open: false });
                      send({ type: "SHOW_MODAL", modal: Modal.Settings });
                    },
                  },
                ]}
              />
            )}
          </RoundButton>
        )}
      </div>
    </div>
  );
};

export default ProfileSummary;
