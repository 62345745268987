export const validateSpotify = (url: string) => {
  var regExp = /^(spotify:|https:\/\/[a-z]+\.spotify\.com\/)/;
  return !!regExp.test(url);
};

export const validateTikTok = (url: string) => {
  var regExp = /^(tiktok:|https:\/\/[a-z]+\.tiktok\.com\/)/;
  return !!regExp.test(url);
};

export const validateInstagram = (url: string) => {
  var regExp = /^(instagram:|https:\/\/[a-z]+\.instagram\.com\/)/;
  return !!regExp.test(url);
};

export const validateTwitter = (url: string) => {
  var regExp =
    // eslint-disable-next-line
    /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
  return !!regExp.test(url);
};

export const validateYouTubeUrl = (url: string) => {
  var regExp =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const result = url.match(regExp);
  if (result && result.length > 2) {
    return result[1];
  }
  return false;
};

export const validateFacebook = (url: string) => {
  var regExp = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
  return !!regExp.test(url);
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    /[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/gi
  );
  return pattern.test(str);
};

export const validateContentString = (content: string) => {
  const result =
    content
      .replaceAll(/<[^>]+>/g, "") //remove all html tags
      .replaceAll(/\r?\n|\r/g, "") //remove line break
      .replaceAll("&nbsp;", "") //remove non-breaking space
      .trim().length > 0;
  return result;
};
