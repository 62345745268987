import classNames from "classnames";
import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

const DEFAULT_THEME =
  "dark:bg-dark-gray dark:text-white bg-very-light-gray text-dark-gray dark:fill-white dark:stroke-white fill-dark-gray";
const COLOR_SCHEME_BY_CATEGORY: { [k: string]: string } = {
  account_error: "gradient text-white",
  login: "gradient text-white",
  welcome: "gradient text-white",
  signup: "gradient text-white",
  confirm_email: "gradient text-white",
  complete_your_profile: "gradient text-white",
};

export const firstPathSegment = (route: string): string => {
  return route.match(/\/([a-z-_]+)\/?/)?.[1] || "";
};

const themeFromRoute = (route: string): string => {
  return COLOR_SCHEME_BY_CATEGORY[firstPathSegment(route)] ?? DEFAULT_THEME;
};

const Layout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const theme = themeFromRoute(location.pathname);

  return <div className={classNames(theme, "layout")}>{children}</div>;
};

export default Layout;
