import { Link, useLocation } from "react-router-dom";
import { Collection } from "../../types/collection";
import { User } from "../../types/user";
import Avatar from "../user/Avatar";
import Categories from "./Categories";
import CollectionBell from "./CollectionBell";
import CollectionCoverImage from "./CollectionCoverImage";
import { isBookmarks } from "../../content/utils";
import CollectionMenu from "./CollectionMenu";
import { backToState } from "../../utils/routes";

const SubscriberCount = ({ collection }: { collection: Collection }) => (
  <>
    {collection.public ? (
      <>
        <strong>{collection.nbSubscribers}</strong> Subscribers
      </>
    ) : (
      <strong>Private</strong>
    )}
  </>
);

const Tile = ({
  collection,
  isCurrentUser,
}: {
  collection: Collection;
  isCurrentUser?: boolean;
}) => (
  <div
    className="mx-auto mb-12 md:mx-4 flex flex-col justify-between"
    style={{ width: 248 }}
  >
    <Link
      className="block"
      key={collection.id}
      to={`/collection/${isBookmarks(collection) ? "" : collection.id}`}
    >
      <CollectionCoverImage
        style={{ height: 120, width: "100%" }}
        collection={collection}
        size="large"
      />
      <div className="mt-3">
        <h1 className="font-raleway text-2xl mb-1">{collection.title}</h1>
        <p className="overflow-hidden mb-4" style={{ height: 40 }}>
          {collection.description}
        </p>
      </div>
    </Link>
    <div>
      {collection.kernelCount !== undefined && (
        <>
          <strong>{collection.kernelCount}</strong> Kernel
          {collection.kernelCount !== 1 && "s"}
        </>
      )}
      {!!isCurrentUser && (
        <>
          &nbsp;&#11825;&nbsp;
          <SubscriberCount collection={collection} />
        </>
      )}
      <div className="flex justify-between items-center">
        <div>
          <Categories categories={collection.categories} />
        </div>
        <div className="flex space-x-4 p-4">
          <CollectionBell collection={collection} />
          <div>
            <CollectionMenu collection={collection} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const Row = ({
  collection,
  isCurrentUser,
  user,
}: {
  collection: Collection;
  isCurrentUser?: boolean;
  user: User;
}) => {
  const location = useLocation();

  return (
    <div className="md:mr-4 mb-12">
      <div className="flex flex-col md:flex-row">
        <Link
          to={{
            pathname: `/collection/${
              isBookmarks(collection) ? "" : collection.id
            }`,
            state: backToState(location),
          }}
        >
          <CollectionCoverImage
            className="mx-auto shrink-0 inline-block rounded-2xl sm:mr-8 bg-cover bg-center"
            collection={collection}
            style={{ height: 200, width: 200 }}
            size="large"
          />
        </Link>
        <div className="flex flex-col grow justify-center min-w-0">
          <Link
            to={`/collection/${isBookmarks(collection) ? "" : collection.id}`}
          >
            <div className="flex items-center text-xs font-normal font-raleway mt-4">
              <Avatar user={user} />
              {user.firstname}&nbsp;{user.lastname}
            </div>
            <div className="my-4">
              <h1 className="font-raleway font-bold text-2xl mb-2">
                {collection.title}
              </h1>
              <p className="truncate">{collection.description}</p>
            </div>
          </Link>
          <div className="flex justify-between items-center">
            <div className="flex flex-wrap items-center">
              <Categories categories={collection.categories} />
              <div className="py-1">
                <span className="whitespace-nowrap">
                  {collection.kernelCount !== undefined && (
                    <>
                      <strong>{collection.kernelCount}</strong> Kernel
                      {collection.kernelCount !== 1 && "s"}
                    </>
                  )}
                  {!!isCurrentUser && (
                    <>
                      &nbsp;&#11825;&nbsp;
                      <SubscriberCount collection={collection} />
                    </>
                  )}
                </span>
              </div>
            </div>
            <div className="flex items-center">
              <CollectionBell collection={collection} />
              <div className="ml-4">
                <CollectionMenu collection={collection} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Component = ({
  collection,
  tile,
  user,
  isCurrentUser,
}: {
  collection: Collection;
  tile: boolean;
  user: User;
  isCurrentUser: boolean;
}) =>
  tile ? (
    <Tile collection={collection} isCurrentUser={isCurrentUser} />
  ) : (
    <Row collection={collection} user={user} isCurrentUser={isCurrentUser} />
  );

export default Component;
