import classNames from "classnames";
import { ReactComponent as Pencil } from "../assets/pencil.svg";
import { ReactComponent as Camera } from "../assets/camera.svg";
import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import ChangeAvatar from "./editProfile/ChangeAvatar";
import LoginLayout from "./LoginLayout";
import ModalWrapper from "./modal/ModalWrapper";
import { CATEGORIES, fullCategory } from "./newCollection/PickCategories";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetCategoriesForUserQuery,
  useGetUserByIdQuery,
  useUpdateCategoriesForUserMutation,
  useUpdateUserMutation,
} from "../api/troveApi";
import { selectCurrentUser } from "../state/reducers/auth";
import { useAppSelector } from "../state/hooks";
import { XState } from "../state/xstate/XStateContext";
import { BannerTheme } from "../state/xstate/banner";
import { pick } from "lodash";

enum Step {
  PickCategories,
  BioAndPicture,
  Intermediate,
  Onboarding,
  Success,
}

const CompleteYourProfile = () => {
  const [panel, setPanel] = useState<Step>(Step.PickCategories);
  const [categories, setCategories] = useState<string[]>([]);
  const [img, setImg] = useState<{
    data: string;
    mime: string;
    dataURL: string;
  }>();
  const [bio, setBio] = useState<string>("");
  const { userId } = useAppSelector(selectCurrentUser);
  const { isLoading, data: profile } = useGetUserByIdQuery(userId || skipToken);
  const [updateUser] = useUpdateUserMutation();
  const { data: categoriesFromApi } = useGetCategoriesForUserQuery(
    userId || skipToken
  );
  const [updateCategories] = useUpdateCategoriesForUserMutation();
  const { banner } = useContext(XState);

  useEffect(() => {
    if (categoriesFromApi) {
      setCategories(categoriesFromApi);
    }
    if (profile?.profilePicture) {
      setImg({ data: "", mime: "", dataURL: profile.profilePicture });
    }
    if (profile?.bio) {
      setBio(profile.bio);
    }
  }, [profile, categoriesFromApi]);

  if (isLoading) {
    return null;
  }

  const backStep = () => {
    if (panel === Step.BioAndPicture) {
      setPanel(Step.PickCategories);
    } else if (panel === Step.Intermediate) {
      setPanel(Step.BioAndPicture);
    } else if (panel === Step.Onboarding) {
      setPanel(Step.Intermediate);
    } else if (panel === Step.Success) {
      setPanel(Step.Onboarding);
    }
  };

  const nextStep = async () => {
    if (panel === Step.PickCategories) {
      if (categories.length < 3) {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            id: "notEnoughCategories",
            content: "Please choose 3 categories",
            theme: BannerTheme.Error,
          },
        });
      } else {
        await updateCategories({
          userId: userId!,
          categories,
        })
          .unwrap()
          .catch((e) => {
            banner.send({
              type: "ADD_BANNER",
              banner: {
                id: "categorySaveError",
                content: "Something went wrong trying to save your categories",
                theme: BannerTheme.Error,
              },
            });
            throw e;
          });
        setPanel(Step.BioAndPicture);
      }
    } else if (panel === Step.BioAndPicture) {
      if (img === undefined) {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            id: "noProfilePhoto",
            content: "Please choose a profile photo",
            theme: BannerTheme.Error,
          },
        });
      } else if (!bio?.length) {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            id: "noBio",
            content: "Please add a bio",
            theme: BannerTheme.Error,
          },
        });
      } else if (bio.length > 400) {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            id: "bioTooLong",
            content: "Bio can not exceed 400 characters",
            theme: BannerTheme.Error,
          },
        });
      } else {
        setPanel(Step.Intermediate);
      }
    } else if (panel === Step.Intermediate) {
      setPanel(Step.Onboarding);
    } else if (panel === Step.Onboarding) {
      setPanel(Step.Success);
    }
  };

  const updateProfile = () => {
    if (!profile) {
      throw new Error("Can't update a user if there is no one logged in");
    }
    updateUser({
      ...profile,
      firstLogin: false,
      bio,
      profilePicture: img ? pick(img, ["data", "mime"]) : undefined,
    }).then((result) => {
      if (result.hasOwnProperty("error")) {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            content: "Something went wrong saving your changes",
            id: "errorOnProfileUpdate",
            theme: BannerTheme.Error,
          },
        });
      } else {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            content: "You're ready to start using Trove!",
            id: "profileComplete",
            theme: BannerTheme.Success,
          },
        });
      }
    });
  };

  return (
    <LoginLayout>
      <ModalWrapper showing={true}>
        <div
          className="flex flex-col rounded-xl py-12 px-4 md:p-8"
          style={{
            width: panel === Step.Onboarding ? 1000 : 800,
            maxWidth: "100%",
          }}
        >
          {panel === Step.PickCategories && (
            <>
              <h1 className="font-bold text-2xl font-raleway text-center">
                What topics interest you most?
              </h1>
              <p className="prose text-center md:py-12 max-w-sm mx-auto">
                Select your favorite categories to get the most out of content
                that matters to you.
              </p>
              <div className="mb-12">
                {CATEGORIES.map(({ emoji, title }) => {
                  const full = fullCategory({ emoji, title });
                  const selected = categories.includes(full);
                  return (
                    <div
                      className={classNames(
                        "text-center cursor-pointer rounded-lg bg-white border-gray border font-bold inline-block m-1.5 p-2 md:px-4 md:py-3",
                        { "text-white bg-green": selected }
                      )}
                      key={title}
                      onClick={() => {
                        if (selected) {
                          setCategories(categories.filter((c) => c !== full));
                        } else {
                          setCategories([...categories, full]);
                        }
                      }}
                    >
                      <span className="pr-2">{emoji}</span>
                      {title}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {panel === Step.BioAndPicture && (
            <>
              <h1 className="font-bold text-2xl font-raleway">
                Build your presence
              </h1>
              <div className="my-12">
                <ChangeAvatar onChange={setImg}>
                  <div
                    className="w-20 h-20 bg-light-gray mx-auto rounded-full cursor-pointer flex items-center bg-cover"
                    style={{
                      backgroundImage: `url("${img?.dataURL}")`,
                    }}
                  >
                    <Pencil className="m-auto h-8 w-8 p-2 backdrop-blur-sm bg-white/50 rounded-full" />
                  </div>
                </ChangeAvatar>
                <ChangeAvatar onChange={setImg}>
                  <Button className="text-xs flex items-center mt-8">
                    <Camera className="inline w-4 mr-4" />
                    Upload image
                  </Button>
                </ChangeAvatar>
              </div>
              <p className="prose max-w-sm text-center mx-auto font-bold">
                Trove is focused on human connection.
              </p>
              <p className="prose max-w-sm text-center mx-auto mb-8">
                So, we ask our members to include a profile image to build a
                strong presence in our community.
              </p>
              <div className="max-w-xl mx-auto mb-16">
                <textarea
                  value={bio}
                  cols={60}
                  rows={2}
                  className="mx-auto p-4 rounded-lg border-gray border input w-auto"
                  placeholder="Share a bio about yourself. What inspires you? What keeps you going?"
                  onChange={(e) => {
                    if (e.target.value.length <= 400) {
                      setBio(e.target.value);
                    }
                  }}
                ></textarea>
                <div className={bio.length === 400 ? "text-red-600" : ""}>
                  {bio.length}/400 characters used
                </div>
              </div>
            </>
          )}
          {panel === Step.Intermediate && (
            <>
              <div className="flex justify-between flex-col">
                <h1 className="text-center text-2xl">
                  Quick Note: This web app is in early beta with limited
                  features
                </h1>
                <div className="flex flex-col justify-center items-center my-2 mt-12">
                  <div className="mt-12"></div>
                  <p className="font-bold text-center text-xl">
                    Download the iOS app for the full
                  </p>
                  <p className="font-bold text-center text-xl">experience!</p>
                  <a
                    href="https://apps.apple.com/us/app/trove-collective/id1529995819"
                    target="_blank"
                    className="mb-12"
                    rel="noreferrer"
                  >
                    <img
                      alt=""
                      className="rounded-xl"
                      src="/images/app_store.png"
                    />
                  </a>
                </div>
                <div className="mb-16">
                  <p className="prose text-center mx-auto text-xl">
                    Android User? Still enjoy the web app for now,
                  </p>
                  <p className="prose text-center mx-auto text-xl">
                    and we are working on the Android app for you!
                  </p>
                </div>
              </div>
            </>
          )}
          {panel === Step.Onboarding && (
            <>
              <div className="flex justify-between flex-col">
                <div className="flex justify-between mb-8">
                  <img
                    alt=""
                    className="rounded-2xl pr-2"
                    src="/images/onboarding_left.png"
                    style={{ width: "50%", height: "50%" }}
                  />
                  <img
                    alt=""
                    className="rounded-2xl pl-2"
                    src="/images/onboarding_right.png"
                    style={{ width: "50%", height: "50%" }}
                  />
                </div>
                <div className="mb-16">
                  <h1 className="text-center font-bold text-2xl mb-4">
                    Create collections on topics that you care about
                  </h1>
                  <p className="prose text-center mx-auto text-lg">
                    Collections are organized by topic, and you add ‘kernels’ of
                  </p>
                  <p className="prose text-center mx-auto text-lg">
                    knowledge into each one. Collections can be public or
                    private. And
                  </p>
                  <p className="prose text-center mx-auto text-lg">
                    you can collaborate w/ others!
                  </p>
                </div>
              </div>
            </>
          )}
          {panel === Step.Success && (
            <>
              <div className="flex justify-between flex-col mt-6">
                <div className="flex justify-center mt-12 mb-2">
                  <img
                    alt=""
                    className="rounded-2x"
                    src="/images/box.png"
                    style={{ width: "13%", height: "auto" }}
                  />
                </div>
                <div className="mb-16">
                  <p className="prose text-center mx-auto text-xl">
                    You’re all set.
                  </p>
                  <h1 className="text-center font-bold text-2xl">
                    Welcome to the Trove community!
                  </h1>
                  <p className="prose text-center mx-auto text-xl">
                    Remember to follow our
                    <a
                      style={{
                        color: "#007AFF",
                        textDecoration: "underline",
                        paddingLeft: "4px",
                      }}
                      href="https://www.trovecollective.co/#footer"
                      target="_blank"
                      rel="noreferrer"
                    >
                      community guidelines
                    </a>
                    !
                  </p>
                  <div className="flex justify-center">
                    <button
                      className="rounded-lg py-2 mt-4 text-white text-lg"
                      style={{ width: "150px", backgroundColor: "#164F4B" }}
                      onClick={updateProfile}
                    >
                      Complete
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {panel !== Step.Success && (
            <div className="flex justify-between">
              <Button
                className={classNames(
                  "border-none bg-transparent text-center cursor-pointer rounded-lg font-bold inline-block m-1.5 px-4 py-2",
                  { invisible: panel === Step.PickCategories }
                )}
                onClick={backStep}
              >
                Back
              </Button>

              <Button
                className={classNames(
                  "border-none bg-green text-white text-center cursor-pointer rounded-lg font-bold inline-block m-1.5 px-4 py-2"
                )}
                onClick={nextStep}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </ModalWrapper>
    </LoginLayout>
  );
};

export default CompleteYourProfile;
