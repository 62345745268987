import classNames from "classnames";
import { MouseEvent, ReactNode } from "react";

const RoundButton = ({
  children,
  className,
  onClick,
}: {
  children: ReactNode;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}) => (
  <div
    className={classNames(
      className,
      "flex bg-white dark:text-white dark:fill-white dark:stroke-white dark:bg-transparent items-center justify-center mx-1 w-8 h-8 rounded-full border border-neutral-300"
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default RoundButton;
