import { uniq } from "lodash";
import { Collection } from "../types/collection";
import Resizer from "react-image-file-resizer";

export const BOOKMARKED_CONTENT = "";

export const ALL_EMOJI =
  /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g;

export const bookmarksCollection = (
  overrides: Partial<Collection>
): Collection => {
  return {
    kernelCount: 0,
    updatedAt: "",
    userId: "",
    ...overrides,

    // These are always true
    categories: [],
    collaborators: [],
    coverImage: BOOKMARKED_CONTENT,
    description: "Kernels I’ve bookmarked that I want to retrieve easily",
    id: BOOKMARKED_CONTENT,
    nbSubscribers: 0,
    public: false,
    title: "Bookmarked Content",
  };
};

export const stripEmoji = (withEmoji: string): string =>
  withEmoji.replace(ALL_EMOJI, "").trim();

export const isBookmarks = (collection: Collection): boolean =>
  collection.id === BOOKMARKED_CONTENT;

export const collaborators = (collection: Collection): string[] => {
  return uniq([collection.userId, ...(collection.collaborators || [])]);
};

export const extractSelector = (
  html: string,
  selector: string
): NodeListOf<Element> => {
  return new DOMParser()
    .parseFromString(html, "text/html")
    .querySelectorAll(selector);
};

export const youTubeURLs = (html: string): string[] => {
  const iframes = extractSelector(
    html,
    ".iframe-Youtube"
  ) as NodeListOf<HTMLIFrameElement>;
  return Array.from(iframes)
    .map((i) => (i as any).href)
    .filter(Boolean);
};

export const youTubeImage = (html: string): string | null => {
  const url = youTubeURLs(html)[0];
  if (!url) {
    return null;
  }
  const videoId =
    new URL(url).searchParams.get("v") ||
    url.substring(url.lastIndexOf("/") + 1);
  return `https://img.youtube.com/vi/${videoId}/0.jpg`;
};

export const spotifyURLs = (html: string): string[] => {
  const songs = extractSelector(
    html,
    ".iframe-Music"
  ) as NodeListOf<HTMLIFrameElement>;
  const podcasts = extractSelector(
    html,
    ".iframe-Podcast"
  ) as NodeListOf<HTMLIFrameElement>;
  return [...Array.from(podcasts), ...Array.from(songs)]
    .map((i) => (i as any).href)
    .filter(Boolean);
};

const SPOTIFY_URL =
  /https:\/\/open.spotify.com\/(track|show|episode|artist|album)\/(.*)\/?/;

export const spotifyURLsWithCategory = (
  content: string
): { type: SpotifyResourceCategory; id: string } | null => {
  const urls = spotifyURLs(content);
  if (!urls.length) {
    return null;
  }
  const match = urls[0].match(SPOTIFY_URL);
  if (!match) {
    console.log(
      `Found a Spotify URL that we can't turn into a picture: ${urls[0]}`
    );
    return null;
  }
  return {
    id: match[2],
    type: `${match[1]}s` as SpotifyResourceCategory,
  };
};

export const resizeFile = (file: any, type: string, outputType: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      600,
      type,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      outputType
    );
  });

export const urlify = (text: string): string => {
  return text
    .split(" ")
    .map((w) => {
      if (validUrl.test(w)) {
        let url = w;
        if (!w.startsWith("http")) {
          url = `https://${w}`;
        }
        return `<a class="underline" href="${url}" target="_blank"/>${w}</a>`;
      } else {
        return w;
      }
    })
    .join(" ");
};

export const validUrl =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
