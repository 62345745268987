import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useInterpret, useSelector } from "@xstate/react";
import { createContext, ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import { InterpreterFrom } from "xstate";
import {
  useCreateCollectionMutation,
  useCreateUserMutation,
  useGetUserByIdQuery,
  useLazyGetCollectionsForUserQuery,
  useLazyVerifyReferralCodeQuery,
} from "../../api/troveApi";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectCurrentUser, setCredentials } from "../reducers/auth";
import authenticationMachine from "./auth";
import bannerMachine, { BannerTheme } from "./banner";
import collectionWizardMachine from "./collectionWizard";
import accountWizardMachine from "./accountWizard";
import modalMachine, { Modal } from "./modal";
import accountDetailsMachine from "./accountDetails";
import { generateId, signInWithGoogle, signUp } from "../../api/firebase";
import { sha256 } from "js-sha256";
// @ts-ignore
import { useScript, appleAuthHelpers } from "react-apple-signin-auth";

export const XState = createContext({
  accountWizard: {} as InterpreterFrom<typeof accountWizardMachine>,
  authentication: {} as InterpreterFrom<typeof authenticationMachine>,
  banner: {} as InterpreterFrom<typeof bannerMachine>,
  collectionWizard: {} as InterpreterFrom<typeof collectionWizardMachine>,
  modal: {} as InterpreterFrom<typeof modalMachine>,
});

export interface PersistableUser {
  email: string;
  firstname: string;
  id: string;
  lastname: string;
}

export const XStateProvider = ({ children }: { children: ReactNode }) => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const history = useHistory();
  const savedState = JSON.parse(localStorage.getItem("new-collection") || "{}");
  const [nonce] = useState(generateId());
  const [verify] = useLazyVerifyReferralCodeQuery();
  const [createUser] = useCreateUserMutation();
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);
  const authentication = useInterpret(authenticationMachine, {
    services: {
      // Technically, we don't need to do this. We could use a selector that
      // reads from this state machine, rather than a selector that reads from
      // the redux store, and passing in dispatch. However, I'm leaving this in
      // here, because it's a nice example of how to make the two work together,
      // and that might be useful to have in the future.
      persistUserDetails: async (context, event) => {
        if (
          event.type === "REPORT_IS_LOGGED_IN" ||
          event.type === "TOKEN_UPDATED"
        ) {
          return await dispatch(
            setCredentials({
              userId: event.userDetails.user.uid,
              token: event.userDetails.token,
            })
          );
        } else if (event.type === "REPORT_IS_LOGGED_OUT") {
          return await dispatch(setCredentials({ userId: null, token: null }));
        }
      },
    },
  });

  const modal = useInterpret(modalMachine, { context: { modal: Modal.None } });
  const banner = useInterpret(bannerMachine, {
    context: { banners: [] },
  });
  const [createCollection] = useCreateCollectionMutation();
  const { userId: currentUserId } = useAppSelector(selectCurrentUser);

  const currentUser = useSelector(
    authentication,
    (state) => state.context.userDetails?.user
  );

  const signInWithApple = async (): Promise<PersistableUser> => {
    const appleId = (window as any).AppleID;
    appleId.auth.init({
      clientId: "net.trovecollective.android-web",
      redirectURI: window.location.href,
      scope: "email name",
      nonce: sha256(nonce),
      usePopup: true,
    });
    const appleAuth: {
      authorization: { code: string; id_token: string };
      user: { email: string; name: { firstName: string; lastName: string } };
    } = await appleId.auth.signIn();
    return {
      email: appleAuth.user.email,
      firstname: appleAuth.user.name.firstName,
      lastname: appleAuth.user.name.lastName,
      id: "THIS SHOULD COME FROM FIREBASE EVENTUALLY",
    };
  };

  const accountWizard = useInterpret(accountWizardMachine, {
    services: {
      accountDetails: accountDetailsMachine.withConfig({
        services: {
          signInWithGoogle: async (): Promise<PersistableUser> => {
            const googleAuth = await signInWithGoogle();
            const firstname = googleAuth.user.displayName!.split(" ")[0];
            const lastname = googleAuth.user
              .displayName!.split(" ")
              .slice(1)
              .join(" ");
            return {
              email: googleAuth.user.email!,
              firstname,
              id: googleAuth.user.uid,
              lastname,
            };
          },
          signInWithApple,
          signInWithEmail: async (
            _context,
            event
          ): Promise<PersistableUser> => {
            if (event.type !== "SIGN_IN_WITH_EMAIL") {
              throw new Error(
                "signInWithEmail invoked with the wrong event type"
              );
            }
            if (event.password !== event.passwordConfirm) {
              throw new Error("Passwords don't match.");
            }
            if (
              event.password.length < 8 ||
              event.password.match(/^[a-zA-Z0-9]$/)
            ) {
              throw new Error(
                "Password must be at least 8 characters and include at least one special character."
              );
            }
            const firebaseAuth = await signUp(event.email, event.password);
            return {
              email: event.email,
              id: firebaseAuth.user.uid,
              firstname: event.firstname,
              lastname: event.lastname,
            };
          },
          persistAccount: (context, event) => {
            if (
              "done.invoke.signInWithGoogle" !== event.type &&
              "done.invoke.signInWithApple" !== event.type &&
              "done.invoke.signInWithEmail" !== event.type
            ) {
              throw new Error(
                "persistAccount called with incorrect event type"
              );
            }

            const user: PersistableUser = event.data;
            return createUser({
              bio: "",
              change_layout: false,
              profilePicture: undefined,
              username: `${user.firstname}.${user.lastname}`,
              referralCode: context.referralCode!,
              ...user,
            }).unwrap();
          },
        },
      }),
      verifyCode: async (context, event) => {
        if (event.type !== "VERIFY") {
          banner.send({
            type: "ADD_BANNER",
            banner: {
              id: "missingReferralCode",
              theme: BannerTheme.Error,
              content: "Unknown error",
            },
          });
          throw new Error("verifyCode called with a non-verify event");
        }
        if (!event.code) {
          banner.send({
            type: "ADD_BANNER",
            banner: {
              id: `invalidReferralCode-${event.code}`,
              theme: BannerTheme.Error,
              content: "Please enter a code",
            },
          });
          throw new Error("Please enter a code");
        }
        return new Promise(async (resolve, reject) => {
          try {
            await verify(event.code).unwrap();
            resolve(event.code);
          } catch (e) {
            banner.send({
              type: "ADD_BANNER",
              banner: {
                id: "invalidReferralCode",
                theme: BannerTheme.Error,
                content: "That isn't a valid code",
              },
            });
            reject(e);
          }
        });
      },
    },
  });

  const { data: user } = useGetUserByIdQuery(currentUser?.uid || skipToken);
  const [getCollectionsForUser] = useLazyGetCollectionsForUserQuery();
  const collectionWizard = useInterpret(collectionWizardMachine, {
    context: savedState,
    actions: {
      done: () => modal.send("HIDE_MODAL"),
      success: () => {
        banner.send({
          type: "ADD_BANNER",
          banner: {
            theme: BannerTheme.Success,
            content: <>🎉 &nbsp;&nbsp;Collection successfully created!</>,
            id: "createCollectionSuccess",
          },
        });
        getCollectionsForUser({ id: user!.id, currentUserId: user!.id });
      },
    },
    services: {
      createCollection: async (context) => {
        const collection = await createCollection({
          categories: context.categories,
          coverImage: context.coverImage!,
          description: context.description!,
          public: context.public,
          title: context.title!,
          userId: currentUserId!,
        }).unwrap();
        history.push(`/collection/${collection.id}`);
      },
    },
  });

  return (
    <XState.Provider
      value={{
        accountWizard,
        authentication,
        banner,
        collectionWizard,
        modal,
      }}
    >
      {children}
    </XState.Provider>
  );
};
