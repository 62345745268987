import { createMachine } from "xstate";
import { PersistableUser } from "./XStateContext";

export interface AccountDetailsMachineContext {
  email: string | undefined;
  errorMessage: string | null;
  firstname: string | undefined;
  lastname: string | undefined;
  // The worst naming in the whole wide world. Sorry, this is what the API
  // expects.
  not_verify_email: boolean;
  phone: string | undefined;
  referralCode: string | undefined;
}

export type AccountDetailsMachineEvent =
  | { type: "SIGN_IN_WITH_GOOGLE" }
  | { type: "SIGN_IN_WITH_APPLE" }
  | {
      type: "SIGN_IN_WITH_EMAIL";
      email: string;
      password: string;
      passwordConfirm: string;
      firstname: string;
      lastname: string;
    }
  | { type: "done.invoke.signInWithGoogle"; data: PersistableUser }
  | { type: "done.invoke.signInWithApple"; data: PersistableUser }
  | { type: "done.invoke.signInWithEmail"; data: PersistableUser };

const accountDetailsMachine = createMachine<
  AccountDetailsMachineContext,
  AccountDetailsMachineEvent
>({
  id: "accountDetails",
  context: {
    email: undefined,
    errorMessage: null,
    firstname: undefined,
    lastname: undefined,
    not_verify_email: false,
    phone: undefined,
    referralCode: undefined,
  },
  initial: "idle",
  states: {
    idle: {
      id: "signInWithGoogle",
      on: {
        SIGN_IN_WITH_APPLE: {
          target: "signingInWithApple",
        },
        SIGN_IN_WITH_EMAIL: { target: "signingInWithEmail" },
        SIGN_IN_WITH_GOOGLE: {
          target: "signingInWithGoogle",
        },
      },
    },
    signingInWithApple: {
      invoke: {
        src: "signInWithApple",
        onDone: {
          target: "persistingAccount",
        },
        onError: {
          target: "idle",
          actions: (_, context) => alert(context.data),
        },
      },
    },
    signingInWithEmail: {
      invoke: {
        src: "signInWithEmail",
        onDone: { target: "persistingAccount" },
        onError: {
          target: "idle",
          actions: (_, context) => alert(context.data),
        },
      },
    },
    signingInWithGoogle: {
      invoke: {
        src: "signInWithGoogle",
        onDone: { target: "persistingAccount" },
        onError: {
          target: "idle",
          actions: (_, context) => alert(context.data),
        },
      },
    },
    persistingAccount: {
      invoke: {
        src: "persistAccount",
        onDone: { target: "success" },
        onError: {
          target: "idle",
          actions: (_, context) => alert(context.data),
        },
      },
    },
    success: { type: "final" },
  },
});

export default accountDetailsMachine;
