const AddingContent = () => (
  <div className="flex flex-col rounded-xl text-center p-6">
    <h1 className="text-3xl mb-8 font-bold">Hello there!</h1>
    <div className="md:mb-4 md:mt-8 max-w-xl">
      Adding content is currently only supported on desktop web or on our iOS
      app.
    </div>
    <a
      href="https://apps.apple.com/us/app/trove-collective/id1529995819"
      target="_blank"
      rel="noreferrer"
      className="w-fit m-auto rounded-xl mt-8"
    >
      <img alt="Available on the App Store" src="/images/app_store.png" />
    </a>
  </div>
);

export default AddingContent;
