import algoliasearch from "algoliasearch";
import { CollectionExplore, CollectionIndexResult } from "../types/collection";

export const searchCollections = async (text: string, page: number) => {
  try {
    const collectionsIndex = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APPLICATION_ID ?? "",
      process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY ?? ""
    ).initIndex(process.env.REACT_APP_ALGOLIA_COLLECTIONS_INDEX ?? "");
    const data = await collectionsIndex?.search<CollectionIndexResult>(text, {
      facetFilters: [["public:true"], [`userActive:true`]],
      page: page,
    });
    return { value: data };
  } catch (error) {
    return { error };
  }
};

export const fetchExploreCollections = async (
  page: number,
  userID: string,
  hashtagSelected: string,
  categories: string[]
) => {
  try {
    const exploreIndex = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APPLICATION_ID ?? "",
      process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY ?? ""
    ).initIndex(process.env.REACT_APP_ALGOLIA_EXPLORES_INDEX ?? "");
    let facetFilters = [
      ["public:true"],
      [`subscribers:-${userID}`],
      [`user.id:-${userID}`],
      [`hashtags:${hashtagSelected}`],
      [`user.active:true`],
    ];
    const arrFacetCategories =
      categories &&
      categories.length > 0 &&
      categories.map((category) => `categories:${category}`);

    arrFacetCategories && facetFilters.push(arrFacetCategories);

    const data = await exploreIndex.search<CollectionExplore>("", {
      facetFilters: [...facetFilters],
      page: page,
    });
    return { value: data };
  } catch (error) {
    return { error };
  }
};

export const fetchLimitExploreCollections = async (
  limit: number,
  userID: string,
  categories: string[]
) => {
  try {
    const exploreIndex = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APPLICATION_ID ?? "",
      process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY ?? ""
    ).initIndex(process.env.REACT_APP_ALGOLIA_EXPLORES_INDEX ?? "");
    let facetFilters = [
      ["public:true"],
      [`subscribers:-${userID}`],
      [`user.id:-${userID}`],
      [`hashtags:`],
      [`user.active:true`],
    ];
    const arrFacetCategories =
      categories &&
      categories.length > 0 &&
      categories.map((category) => `categories:${category}`);

    arrFacetCategories && facetFilters.push(arrFacetCategories);

    const data = await exploreIndex.search<CollectionExplore>("", {
      facetFilters: [...facetFilters],
      page: 0,
      hitsPerPage: limit,
    });
    return { value: data };
  } catch (error) {
    return { error };
  }
};
