import { useEffect, useState } from "react";
import useCollapse from "react-collapsed";
import { ReactComponent as DownArrow } from "../../assets/ic_arr_down.svg";
import { ReactComponent as UpArrow } from "../../assets/ic_arr_up.svg";

interface Props {
  hashtags: string[],
  onChangeHashtag: (hashtag: string) => void,
}

interface Hashtag {
  hashtag: string;
  selected: boolean;
}

const FilterHashtags = (props: Props) => {
  const { hashtags: hashtagsProp, onChangeHashtag } = props;
  const [hashtags, setHashtags] = useState<Hashtag[]>([]);
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps } = useCollapse({ isExpanded, collapsedHeight: 60 });

  useEffect(() => {
    setHashtags(hashtagsProp.map(e => {
      return {
        hashtag: e,
        selected: false,
      }
    }));
    setExpanded(false);
  }, [hashtagsProp]);

  const onClickItem = (hashtag: string) => {
    setExpanded(false);
    if (hashtags.find(e => e.hashtag === hashtag && e.selected)) {
      onChangeHashtag("");
    } else {
      onChangeHashtag(hashtag);
    }
    setHashtags(hashtags.map(e => {
      return {
        hashtag: e.hashtag,
        selected: e.hashtag === hashtag ? !e.selected : false,
      }
    }));
  }

  return <div className="px-4 md:pr-8 md:pl-0 flex flex-col min-w-0">
    <div className="flex flex-row">
      <div className="flex-1" {...getCollapseProps()}>
        {hashtags.map((ele, index) => {
          if (ele.hashtag.length <= 0) return <></>;
          return <div
            onClick={() => onClickItem(ele.hashtag)}
            key={index}
            className={`group relative p-4 m-1 inline-block rounded-lg border border-light-gray dark:border-neutral-800 ${ele.selected ? `dark:bg-white bg-neutral-800` : `dark:bg-neutral-800 bg-white `}`}
          >
            <div className={`flex items-center text-xs font-inter font-medium ${ele.selected ? `text-light dark:text-black` : `text-dark dark:text-light`}`}>{ele.hashtag}</div>
          </div>
        })}
      </div>
      <div className="flex w-12 h-12 m-1 justify-end items-center" onClick={() => setExpanded(!isExpanded)}>
        {isExpanded ? <UpArrow className="w-4 h-3" /> : <DownArrow className="w-4 h-3" />}
      </div>
    </div>
  </div>
}


export default FilterHashtags;