import { useActor } from "@xstate/react";
import { useState } from "react";
import { Interpreter } from "xstate";
import {
  AccountWizardMachineContext,
  AccountWizardMachineEvent,
} from "../../state/xstate/accountWizard";

const ReferralCode = ({
  service,
}: {
  service: Interpreter<
    AccountWizardMachineContext,
    any,
    AccountWizardMachineEvent
  >;
}) => {
  const [state, send] = useActor(service);
  const [code, setCode] = useState<string>("");

  if (!["referralCode", "verifying"].some(state.matches)) {
    return null;
  }

  return (
    <div className="relative flex flex-col m-auto md:ml-40 w-full md:w-80 h-full items-center justify-center z-10 px-4">
      <h1 className="font-bold text-2xl font-raleway">Enter referral code</h1>
      <div className="w-full mt-6">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            send({ type: "VERIFY", code });
          }}
        >
          <input
            name="referral_code"
            onChange={(e) => setCode(e.target.value)}
            className="w-full rounded-lg p-4 my-1 text-black input"
          />
          <button className="bg-gold w-full text-black p-4 rounded-lg mt-6">
            {state.matches("verifying") ? "Verifying..." : "Verify"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReferralCode;
