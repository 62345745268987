import { useState } from "react";
import { Helmet } from "react-helmet";
import {
  useGetCategoriesMutation,
  useGetCategoriesUserMutation,
} from "../../api/troveApi";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import Loading from "../Loading";
import ExploreCollections from "./ExploreCollections";
import YourCategories, { lasted } from "./YourCategories";
import { useEffect } from "react";
import useWindowDimensions from "../../utils/WindowDimensions";
interface Props {}

const ExplorePage = (props: Props) => {
  const { userId } = useAppSelector(selectCurrentUser);
  const [getCategoriesForUser] = useGetCategoriesUserMutation();
  const [getCategories] = useGetCategoriesMutation();

  const [selectedCategory, setSelectedCategory] = useState<string>(lasted);
  const [categoriesForUser, setCategoriesForUser] = useState<string[]>([]);
  const [listCategories, setListCategories] = useState<string[]>([]);

  const { height } = useWindowDimensions();
  document.documentElement.style.setProperty(
    "--vh",
    `${Math.max(height, window.innerHeight) * 0.01}px`
  );

  const onChangeCategory = (category: string) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    if (userId) {
      getCategoriesForUser({ userId: userId })
        .then((res: any) => {
          if (!res.hasOwnProperty("error")) {
            setCategoriesForUser(res.data ?? []);
          }
        })
        .catch();
    }
    getCategories()
      .then((res: any) => {
        if (!res.hasOwnProperty("error")) {
          setListCategories(res.data ?? []);
        }
      })
      .catch();
  }, [
    userId,
    setListCategories,
    setCategoriesForUser,
    getCategories,
    getCategoriesForUser,
  ]);

  if (listCategories.length === 0) {
    return <Loading />;
  }

  return (
    <div className="explore-sidebar">
      <Helmet>
        <title>Explore</title>
        <meta property="description" content="Explore the Trove universe" />
        <meta
          property="og:title"
          content="Trove - where ideas & recommendations thrive"
        />
        <meta
          property="twitter:title"
          content="Trove - where ideas & recommendations thrive"
        />
        <meta
          property="og:url"
          content={
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname
          }
        />
      </Helmet>
      <YourCategories
        categoriesFromApi={categoriesForUser}
        listCategoriesFromApi={listCategories}
        onChangeCategory={onChangeCategory}
        categoryActive={selectedCategory}
      />
      <ExploreCollections
        selectedCategory={
          selectedCategory.includes("Latest") ||
          selectedCategory.includes("For You")
            ? selectedCategory.replaceAll(/[^\w]/gi, "")
            : selectedCategory
        }
        categoriesForUser={categoriesForUser}
      />
    </div>
  );
};

export default ExplorePage;
