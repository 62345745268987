import { Link, useHistory, useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../state/hooks";
import { selectCurrentUser } from "../state/reducers/auth";
import { useState, useMemo } from "react";
import { signOut } from "../api/firebase";
import { Twirl as Hamburger } from "hamburger-react";

import Avatar from "./user/Avatar";
import Menu from "./Menu";
import { useGetUserByIdQuery } from "../api/troveApi";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ReactComponent as ExploreIcon } from "../assets/ic_explore.svg";
import { ReactComponent as ExploreInactive } from "../assets/ic_explore_inactive.svg";
import { ReactComponent as MyTroveIcon } from "../assets/ic_mytrove.svg";
import { ReactComponent as MyTroveInactive } from "../assets/ic_mytrove_inactive.svg";
import classNames from "classnames";
import ThemePicker, { VerbalThemePicker } from "./ThemePicker";
import { User } from "../types/user";
import { clearCache } from "../state/reducers/content";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";

const DownloadiOSApp = () => (
  <a
    className="text-center"
    href="https://apps.apple.com/us/app/trove-collective/id1529995819"
    target="_blank"
    rel="noreferrer"
  >
    Download iOS App
  </a>
);

const SignInOrSignUp = () => (
  <Link className="font-semibold" to="/welcome">
    Sign In/Up
  </Link>
);

const MyTrove = ({
  isMobile,
  profile,
}: {
  isMobile: boolean;
  profile: User;
}) => {
  const { pathname } = useLocation();
  const onProfilePage = pathname?.startsWith("/profile") || pathname === "/";
  return (
    <Link to={`/profile/${profile.username}`}>
      <div className="flex flex-row justify-center items-center">
        {!isMobile &&
          (onProfilePage ? (
            <MyTroveIcon className="mr-2 stroke-transparent" />
          ) : (
            <MyTroveInactive className="mr-2 stroke-transparent" />
          ))}
        <p
          className={classNames("whitespace-nowrap", {
            "text-green": onProfilePage,
          })}
        >
          My Trove
        </p>
      </div>
    </Link>
  );
};

const Explore = ({ isMobile }: { isMobile: boolean }) => {
  const { pathname } = useLocation();
  const onExplorePage = pathname?.startsWith("/explore");
  return (
    <Link to="/explore">
      <div className="flex flex-row justify-center items-center">
        {isMobile &&
          (onExplorePage ? (
            <ExploreIcon className="mr-2 stroke-transparent" />
          ) : (
            <ExploreInactive className="mr-2 stroke-transparent" />
          ))}
        <p className={classNames({ "text-green": onExplorePage })}>Explore</p>
      </div>
    </Link>
  );
};

const NotLoggedInMobile = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <Hamburger
        hideOutline={true}
        toggled={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <Menu
          className="text-center"
          close={() => setIsOpen(false)}
          x={window.innerWidth}
          y={60}
          items={[
            {
              onClick: () => { },
              children: <Explore isMobile={true} />,
            },
            {
              onClick: () => { },
              children: <SignInOrSignUp />,
            },
            {
              onClick: () => { },
              children: <VerbalThemePicker />,
            },
          ]}
        />
      )}
    </>
  );
};

export const MainNavActions = () => {
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const history = useHistory();
  const { userId } = useAppSelector(selectCurrentUser);
  const { data: profile } = useGetUserByIdQuery(userId ?? skipToken);
  const isMobile = useMemo(() => {
    let check = false;
    (function (a) {
      if (
        // eslint-disable-next-line
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuCoordinates, setMenuCoordinates] = useState<{
    x?: number;
    y?: number;
  }>({
    x: undefined,
    y: undefined,
  });

  // This is the place where this Flash of Unlogged-in Content (if you will)
  // happens, but theoretically if it started being a problem elsewhere we could
  // centralize this in WaitForUser and that should fix it anywhere.
  if (userId && !profile) {
    return null;
  }

  const menuItems = [
    {
      children: <div className="flex items-center cursor-pointer">Logout</div>,
      onClick: async () => await signOut().then(() => {
        dispatch(clearCache());
        history.push("/")
      }),
    },
  ];
  if (userId && profile && isMobile) {
    menuItems.unshift({
      onClick: async () => { },
      children: <VerbalThemePicker />,
    });
    menuItems.unshift({
      onClick: async () => { },
      children: <Explore isMobile={isMobile} />,
    });
    menuItems.unshift({
      children: <MyTrove isMobile={isMobile} profile={profile} />,
      onClick: async () => { },
    });
  }

  return (
    <div className="flex items-center space-x-4 font-semibold">
      {menuOpen && (
        <Menu
          close={() => setMenuOpen(false)}
          x={menuCoordinates.x!}
          y={menuCoordinates.y!}
          items={menuItems}
        />
      )}
      <DownloadiOSApp />
      {!isMobile && (
        <>
          <ThemePicker />
          <Explore isMobile={isMobile} />
        </>
      )}
      {isMobile ? (
        <>
          {userId && profile ? (
            <>
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  setMenuOpen(!menuOpen);
                  setMenuCoordinates({ x: e.clientX, y: e.clientY });
                }}
              >
                <Avatar user={profile} />
              </div>
            </>
          ) : (
            <NotLoggedInMobile />
          )}
        </>
      ) : (
        <>
          {userId && profile ? (
            <>
              <MyTrove isMobile={isMobile} profile={profile} />
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  setMenuOpen(!menuOpen);
                  setMenuCoordinates({ x: e.clientX, y: e.clientY });
                }}
              >
                <Avatar user={profile} />
              </div>
            </>
          ) : (
            <SignInOrSignUp />
          )}
        </>
      )}
    </div>
  );
};
