import branch from "branch-sdk";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useResendEmailMutation } from "../api/troveApi";
import { useAuth } from "../hooks/auth";
import { BannerTheme } from "../state/xstate/banner";
import { Modal } from "../state/xstate/modal";
import { XState } from "../state/xstate/XStateContext";
import LoginLayout from "./LoginLayout";

const ConfirmEmail = () => {
  const { state } = useAuth();
  const [resendEmail] = useResendEmailMutation();
  const { modal, banner } = useContext(XState);
  const { send } = modal;
  const [isExpiredEmail, setExpiredEmail] = useState<boolean>(true);

  useEffect(() => {
    branch.data((_err: any, data: any) => {
      if (
        data &&
        data.data_parsed &&
        data.data_parsed.action === "email-verify" &&
        !data.data_parsed.verified &&
        isExpiredEmail
      ) {
        send({ type: "SHOW_MODAL", modal: Modal.EmailExpired });
        setExpiredEmail(false);
      }
    });
  }, [isExpiredEmail, send]);

  if (
    !state.context.userDetails ||
    state.context.userDetails.user.emailVerified
  ) {
    return <Redirect to="/" />;
  }

  const onPressResend = () => {
    if (state.context.userDetails) {
      resendEmail({
        userID: state.context.userDetails.user.uid,
      })
        .then((res) => {
          if (res.hasOwnProperty("error")) {
            banner.send({
              type: "ADD_BANNER",
              banner: {
                theme: BannerTheme.Error,
                content: "Too many attempts. Please try later!",
                id: "resendEmail",
              },
            });
          } else {
            banner.send({
              type: "ADD_BANNER",
              banner: {
                theme: BannerTheme.Success,
                content: "Resend success!",
                id: "resendEmail",
              },
            });
          }
        })
        .catch((_err) => {
          banner.send({
            type: "ADD_BANNER",
            banner: {
              theme: BannerTheme.Error,
              content: "Too many attempts. Please try later!",
              id: "resendEmail",
            },
          });
        });
    }
  };

  return (
    <LoginLayout>
      <div className="relative flex flex-col m-auto md:ml-20 lg:ml-40 w-full md:w-80 lg:w-1/3 max-w-prose h-full items-center justify-center z-10 px-4">
        <h1 className="font-raleway text-2xl font-bold">Welcome aboard!</h1>
        <div className="py-8 flex-column items-center text-center text-xl">
          We’ve sent an email to&nbsp;
          <span className="font-bold">
            {state.context.userDetails?.user.email}
          </span>
          . Please check your email and click the verify link!
        </div>
        <div className="flex flex-col items-center text-center text-lg fixed bottom-0 py-12">
          Haven’t recieved an email with 5 minutes?
          <button
            className="bg-white rounded-lg py-2 mt-4 text-dark-gray text-lg font-semibold"
            style={{ width: "75%" }}
            onClick={onPressResend}
          >
            Resend email
          </button>
        </div>
      </div>
    </LoginLayout>
  );
};

export default ConfirmEmail;
