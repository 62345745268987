import classNames from "classnames";

interface MenuProps {
  className?: string;
  close: () => void;
  x: number;
  y: number;
  items: {
    children: React.ReactNode;
    close?: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
  }[];
}

type OpenMenu = {
  open: true;
  x: number;
  y: number;
};

type ClosedMenu = {
  open: false;
  x: number | undefined;
  y: number | undefined;
};

export type MenuState = OpenMenu | ClosedMenu;

const Menu = ({ className, close, x, y, items }: MenuProps) => {
  return (
    <div
      className="flex fixed top-4 bottom-4 right-4 left-4 z-50"
      onClick={close}
    >
      <div style={{ width: `calc(${x}px - 1rem)` }} />
      <div className="flex flex-col">
        <div style={{ height: `calc(${y}px - 1rem)` }} />
        <div
          className={classNames(
            "bg-white rounded-md shadow-md text-black w-fit z-50",
            className
          )}
          style={{ maxWidth: 250 }}
        >
          {items.map((item, i) => (
            <div
              key={i}
              onClick={(e) => {
                e.stopPropagation();
                item.onClick(e);
                if (typeof item.close === undefined || item.close) {
                  close();
                }
              }}
              className="p-4 hover:bg-yellow-50 rounded-md cursor-pointer"
            >
              {item.children}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Menu;
