import Logo from "../Logo";

const Welcome = () => (
  <div
    className="flex flex-col rounded-xl text-center md:p-6 md:pb-24"
    style={{
      backgroundImage: "url(/images/crowd.svg)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
    }}
  >
    <Logo />
    <div className="md:mb-4 md:mt-8 max-w-xl">
      We are so glad you are enjoying the Trove experience! In order to savor
      this content - and to build your own Trove -{" "}
      <span className="font-bold">download our app</span> and create an account
      using the code <span className="font-bold">TRVE50</span>!
    </div>
    <a
      href="https://apps.apple.com/us/app/trove-collective/id1529995819"
      target="_blank"
      rel="noreferrer"
      className="w-fit m-auto rounded-xl "
    >
      <img alt="Available on the App Store" src="/images/app_store.png" />
    </a>
  </div>
);

export default Welcome;
