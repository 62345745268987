import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: SpotifyState = {
  albums: {},
  artists: {},
  episodes: {},
  playlists: {},
  shows: {},
  tracks: {},
};

export const spotifySlice = createSlice({
  name: "spotify",
  initialState,
  reducers: {
    resourceRequested: (
      state: SpotifyState,
      action: PayloadAction<{ type: SpotifyResourceCategory; id: string }>
    ) => {},
    resourceFetched: (
      state: SpotifyState,
      action: PayloadAction<{
        resource: SpotifyResource;
        type: SpotifyResourceCategory;
      }>
    ) => {
      state[action.payload.type] = {
        ...state[action.payload.type],
        [action.payload.resource.id]: action.payload.resource,
      };
    },
  },
});

export const { resourceFetched, resourceRequested } = spotifySlice.actions;

export default spotifySlice.reducer;
