import { ReactComponent as Camera } from "../../assets/camera.svg";
import { useRef, useState } from "react";
import { useActor } from "@xstate/react";
import Button from "../Button";
import {
  CollectionWizardMachineContext,
  CollectionWizardMachineEvent,
} from "../../state/xstate/collectionWizard";
import { Interpreter } from "xstate";
import {
  arrayBufferToBase64,
  ColorFamily,
  randomColorImage,
} from "../../content/images";
import LoadingSpinner from "../LoadingSpinner";
import heic2any from "heic2any";
import {
  resizeFile
} from "../../content/utils";

const EnterContent = ({
  service,
}: {
  service: Interpreter<
    CollectionWizardMachineContext,
    any,
    CollectionWizardMachineEvent
  >;
}) => {
  const [state, send] = useActor(service);
  const fileInput = useRef<HTMLInputElement>(null);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);

  const {
    title,
    description,
    coverImage,
    id,
    public: isPublic,
  } = state.context;

  const updateColor = (
    colorFamily: ColorFamily
  ): {
    type: "CHANGE_CONTENT";
    info: {
      title: string | null;
      description: string | null;
      coverImage: string;
      public: boolean;
    };
  } => {
    return {
      type: "CHANGE_CONTENT",
      info: {
        title,
        description,
        coverImage: randomColorImage(colorFamily),
        public: isPublic,
      },
    };
  };

  const updateImage = async (e: any) => {
    try {
      setLoadingUpload(true);
      const file = e.target.files?.[0];
      if (!file) {
        return;
      }
      let fileImg: any = file;
      let typeImgSupport: string = '';
      if (file.type === "") {
        typeImgSupport = 'image/' + fileImg.name.split('.').slice(1);
      }
      if ((file.type || typeImgSupport) === "image/heic") {
        fileImg = await heic2any({
          blob: file,
          toType: "image/png",
        });
        await resizeFile(fileImg, 'PNG', 'blob')
          .then((res) => {
            fileImg = res
          })
          .catch((err) => {
            setLoadingUpload(false);
          });
      }

      const arrayBuffer = await fileImg.arrayBuffer();
      const base64 = arrayBufferToBase64(arrayBuffer);
      const fileType = fileImg.type || typeImgSupport;
      const dataURL = `data:${fileType};base64,${base64}`;
      setLoadingUpload(false);
      send({
        type: "CHANGE_CONTENT",
        info: {
          title,
          description,
          coverImage: dataURL,
          public: isPublic,
        },
      });
    } catch (error) {
      setLoadingUpload(false);
    }
  }

  if (!state.matches("enteringContent")) {
    return null;
  }

  return (
    <div
      className="p-8 pb-2 text-center"
      style={{ maxWidth: "100%", width: 800 }}
    >
      {loadingUpload && <LoadingSpinner />}
      <h2 className="font-raleway text-left text-xl mb-12 font-bold">
        Create Collection
      </h2>
      {state.context.errorMessage && (
        <div className="absolute top-12 left-0 w-full ">
          <div className="inline-block bg-red-600 text-white p-4 rounded-lg text-base max-w-[75%]">
            {state.context.errorMessage}
          </div>
        </div>
      )}
      <div className="mb-6" style={{ height: 200 }}>
        <div
          className="border-4 border-gray rounded-lg m-auto"
          style={{
            width: 80,
            height: 80,
            backgroundImage: `url(${coverImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <h3 className="my-4 text-lg">
          <strong>Choose a color</strong> or{" "}
          <strong
            className="cursor-pointer"
            onClick={() => fileInput.current?.click()}
          >
            upload image
          </strong>
        </h3>
        <div className="justify-center flex">
          <div
            onClick={() => send(updateColor("yellow"))}
            className="cursor-pointer bg-gray h-12 w-12 rounded-md mx-1 "
            style={{ backgroundColor: "#F3BC01", opacity: 0.6 }}
          />
          <div
            className="cursor-pointer bg-gray h-12 w-12 rounded-md mx-1"
            style={{ backgroundColor: "#212121", opacity: 0.6 }}
            onClick={() => send(updateColor("blue"))}
          />
          <div
            className="cursor-pointer bg-gray h-12 w-12 rounded-md mx-1"
            style={{ backgroundColor: "#F34601", opacity: 0.6 }}
            onClick={() => send(updateColor("orange"))}
          />
          <div
            className="cursor-pointer bg-gray h-12 w-12 rounded-md mx-1"
            style={{ backgroundColor: "#009487", opacity: 0.6 }}
            onClick={() => send(updateColor("green"))}
          />
          <div className="cursor-pointer flex items-center justify-center bg-white border-gray border h-12 w-12 rounded-md mx-1">
            <Camera onClick={() => fileInput.current?.click()} />
            <input
              ref={fileInput}
              accept=".jpeg,.png,.tiff,.heic,.jpg,.webp"
              className="hidden"
              type="file"
              onChange={updateImage}
            />
          </div>
        </div>
      </div>
      <div className="text-center flex flex-col items-center mb-8">
        <input
          className="max-w-[75%] input my-2"
          placeholder="Title (required)"
          defaultValue={title || ""}
          onChange={(e) =>
            send({
              type: "CHANGE_CONTENT",
              info: {
                title: e.target.value,
                description,
                coverImage,
                public: isPublic,
              },
            })
          }
        />
        <textarea
          className="max-w-[75%] input my-2"
          placeholder="Description (required)"
          defaultValue={description || ""}
          onChange={(e) =>
            send({
              type: "CHANGE_CONTENT",
              info: {
                title,
                description: e.target.value,
                coverImage,
                public: isPublic,
              },
            })
          }
        />
        <div className="mt-8">
          <label>
            <input
              type="checkbox"
              checked={isPublic}
              onChange={(e) =>
                send({
                  type: "CHANGE_CONTENT",
                  info: {
                    title,
                    description,
                    coverImage,
                    public: !isPublic,
                  },
                })
              }
            />
            &nbsp; Make this collection public
          </label>
        </div>
      </div>
      <div className="text-right">
        <div className="absolute translate-x-1/2 right-1/2 flex-grow bottom-6 flex">
          <div
            onClick={() => send("BACK")}
            className="cursor-pointer h-2 w-2 m-1 rounded-full bg-gray"
          />
          <div className="h-2 w-2 m-1 rounded-full bg-green" />
        </div>
        <div>
          <Button
            onClick={() => send("CANCEL")}
            className="text-center cursor-pointer rounded-lg bg-white border-gray border font-bold inline-block m-1.5 px-4 py-2"
          >
            Cancel
          </Button>
          <Button
            className="bg-green text-white text-center cursor-pointer rounded-lg font-bold inline-block m-1.5 px-4 py-2"
            onClick={() => send({ type: "SUBMIT" })}
          >
            {!!id ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EnterContent;
