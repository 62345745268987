import { assign, createMachine } from "xstate";

export enum Modal {
  AddingContent,
  AddTags,
  EditProfile,
  InviteFriends,
  NewCollection,
  None,
  ReportAbuse,
  Settings,
  Welcome,
  EmailExpired,
  ModalExplore,
}

interface ModalMachineContext {
  modal: Modal;
}

export type ModalMachineEvent =
  | { type: "SHOW_MODAL"; modal: Modal }
  | { type: "HIDE_MODAL" };

const modalMachine = createMachine<ModalMachineContext, ModalMachineEvent>(
  {
    id: "modal",
    initial: "idle",
    states: {
      idle: {
        on: {
          SHOW_MODAL: { actions: ["assignModalToContext"] },
          HIDE_MODAL: { actions: ["clearModalFromContext"] },
        },
      },
    },
  },
  {
    actions: {
      assignModalToContext: assign((context, event) =>
        event.type === "SHOW_MODAL" ? { modal: event.modal } : {}
      ),
      clearModalFromContext: assign((context, event) =>
        event.type === "HIDE_MODAL" ? { modal: Modal.None } : {}
      ),
    },
  }
);

export default modalMachine;
