import { useActor } from "@xstate/react";
import { Interpreter } from "xstate";
import {
  AccountWizardMachineContext,
  AccountWizardMachineEvent,
} from "../../state/xstate/accountWizard";
import { ReactComponent as GoogleIcon } from "../../assets/google.svg";
import { ReactComponent as AppleIcon } from "../../assets/apple.svg";
import { useState } from "react";

const AccountDetails = ({
  service,
}: {
  service: Interpreter<
    AccountWizardMachineContext,
    any,
    AccountWizardMachineEvent
  >;
}) => {
  const [state, send] = useActor(service);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  if (!state.matches("accountDetails")) {
    return null;
  }

  return (
    <div className="relative flex flex-col m-auto md:ml-40 w-full md:w-80 h-full items-center justify-center z-10 px-4 pb-4">
      <h1 className="font-bold text-xl font-raleway">
        Sign up to create an account
      </h1>
      <div className="flex items-center my-8">
        <button
          onClick={() => send("SIGN_IN_WITH_GOOGLE")}
          className="bg-white fill-black rounded-full w-8 h-8 mx-2"
        >
          <GoogleIcon
            className="fill-black"
            style={{ width: 32, height: 32 }}
          />
        </button>
        <button
          onClick={() => send("SIGN_IN_WITH_APPLE")}
          className="bg-white fill-black rounded-full w-8 h-8 mx-2"
        >
          <AppleIcon style={{ width: 24, height: 24, margin: "auto" }} />
        </button>
      </div>
      <input
        onChange={(e) => setFirstName(e.target.value)}
        className="w-full rounded-lg p-4 my-1 text-black input"
        placeholder="First name"
      />
      <input
        onChange={(e) => setLastName(e.target.value)}
        className="w-full rounded-lg p-4 my-1 text-black input"
        placeholder="Last name"
      />
      <input
        onChange={(e) => setEmail(e.target.value)}
        className="w-full rounded-lg p-4 my-1 text-black input"
        placeholder="Email"
      />
      <input
        className="w-full rounded-lg p-4 my-1 text-black input"
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        type="password"
      />
      <input
        className="w-full rounded-lg p-4 my-1 text-black input"
        onChange={(e) => setPasswordConfirm(e.target.value)}
        placeholder="Confirm Password"
        type="password"
      />
      <p className="my-8 text-center">
        By continuing you aree to Trove Collective's{" "}
        <a
          target="_blank"
          href="https://www.trovecollective.co/#footer"
          className="text-gold underline"
          rel="noreferrer"
        >
          Privacy Policy
        </a>{" "}
        &amp;{" "}
        <a
          target="_blank"
          href="https://www.trovecollective.co/#footer"
          className="text-gold underline"
          rel="noreferrer"
        >
          User Agreement
        </a>
      </p>
      <div className="w-full">
        <button
          className="bg-gold w-full text-black p-4 rounded-lg"
          onClick={() =>
            send({
              type: "SIGN_IN_WITH_EMAIL",
              email,
              password,
              passwordConfirm,
              // The capitalization is wrong on these because what the API
              // expects. You wonder why it's built that way? Yeah me too.
              firstname: firstName,
              lastname: lastName,
            })
          }
        >
          Create account
        </button>
      </div>
    </div>
  );
};

export default AccountDetails;
