import { ReactComponent as Bell } from "../../assets/bell.svg";
import { ReactComponent as BellFilled } from "../../assets/bell_filled.svg";

import {
  useCreateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetSubscriptionsForUserQuery,
} from "../../api/troveApi";
import { useAppSelector } from "../../state/hooks";

import { Collection } from "../../types/collection";
import { selectCurrentUser } from "../../state/reducers/auth";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Modal } from "../../state/xstate/modal";
import { useContext } from "react";
import { XState } from "../../state/xstate/XStateContext";

const CollectionBell = ({ collection }: { collection: Collection }) => {
  const { modal } = useContext(XState);
  const { send } = modal;
  const { userId } = useAppSelector(selectCurrentUser);
  const { data: subscriptions } = useGetSubscriptionsForUserQuery(
    userId ? null : skipToken
  );
  const [createSubscription, { isLoading: isCreating }] =
    useCreateSubscriptionMutation();
  const [deleteSubscription, { isLoading: isDeleting }] =
    useDeleteSubscriptionMutation();

  const subscriptionStatus = subscriptions
    ?.map((s) => s.id)
    .includes(collection.id);

  if (!userId) {
    return (
      <div
        className="cursor-pointer stroke-transparent"
        onClick={() => send({ type: "SHOW_MODAL", modal: Modal.Welcome })}
      >
        <Bell />
      </div>
    );
  }

  if (userId === collection.userId) {
    return null;
  }

  if (isCreating || isDeleting) {
    return (
      <div
        className="cursor-pointer stroke-transparent"
        onClick={() => deleteSubscription(collection.id)}
      >
        <BellFilled fill="gray" />
      </div>
    );
  }

  return subscriptionStatus ? (
    <div
      className="cursor-pointer stroke-transparent"
      onClick={() => deleteSubscription(collection.id)}
    >
      <BellFilled />
    </div>
  ) : (
    <div
      className="cursor-pointer stroke-transparent"
      onClick={() => createSubscription(collection.id)}
    >
      <Bell />
    </div>
  );
};

export default CollectionBell;
