import classNames from "classnames";
import { ReactComponent as Bookmark } from "../../assets/bookmark.svg";
import { BOOKMARKED_CONTENT } from "../../content/utils";
import { Collection } from "../../types/collection";

// The data migration clearly did not really work because some of these are
// still complete URLs pointing at the old data source.
const maybeExpandURL = (maybeCompleteURL: string) => {
  if (maybeCompleteURL.startsWith("https://")) {
    return maybeCompleteURL;
  } else {
    return `${process.env.REACT_APP_IMAGE_HOST}${maybeCompleteURL}`;
  }
};

const CollectionCoverImage = ({
  className,
  collection,
  style,
  size,
}: {
  className?: string;
  collection: Collection;
  style?: React.CSSProperties;
  size?: "small" | "large";
}) => {
  if (collection.coverImage === BOOKMARKED_CONTENT) {
    return (
      <div
        className={classNames(
          "rounded-2xl flex items-center dark:stroke-transparent",
          className
        )}
        style={{
          background: "linear-gradient(180deg, #2C3030 0%, #000000 100%)",
          ...style,
        }}
      >
        <Bookmark
          width={size === "small" ? "27" : "40.5"}
          height={size === "small" ? "37.5" : "56.25"}
          fill="white"
          className="m-auto"
        />
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "rounded-2xl flex bg-center bg-cover items-center",
        className
      )}
      style={{
        backgroundImage: `url(${maybeExpandURL(collection.coverImage)})`,
        ...style,
      }}
    />
  );
};

export default CollectionCoverImage;
