import { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Banners from "../Banners";
import { Modal } from "../../state/xstate/modal";

interface ModalProps {
  children: ReactNode;
  showing: boolean;
  onClose?: () => void;
  modalName?: Modal;
}

const ModalWrapper = ({
  children,
  showing,
  onClose,
  modalName,
}: ModalProps) => {
  return (
    <Transition.Root show={showing} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 sm:overflow-y-auto max-h-screen overflow-y-scroll"
        onClose={onClose || (() => {})}
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative bg-very-light-gray rounded-lg mx-auto transition-all"
              style={{ maxWidth: "90%", width: "fit-content" }}
            >
              {modalName !== Modal.EmailExpired && onClose && (
                <button
                  className="focus:outline-neutral-300 absolute top-6 right-8 cursor-pointer text-xl px-1 transition-all"
                  onClick={onClose}
                >
                  ✕
                </button>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
        <Banners />
      </Dialog>
    </Transition.Root>
  );
};

export default ModalWrapper;
