import Logo from "./Logo";
import { useLocation } from "react-router-dom";
import { firstPathSegment } from "./Layout";

interface MainNavProps {
  actions: React.ReactNode;
}

const ROUTES_WITHOUT_NAV_BAR = [
  "account_error",
  "complete_your_profile",
  "confirm_email",
  "login",
  "signup",
  "welcome",
];

const MainNav = ({ actions }: MainNavProps) => {
  const location = useLocation();

  // This is a little hacky, sorry.
  if (ROUTES_WITHOUT_NAV_BAR.includes(firstPathSegment(location.pathname))) {
    return <div />;
  }

  return (
    <div className="bg-white dark:bg-inherit flex text-neutral-400 dark:text-neutral-300 items-center justify-between px-4 shadow dark:shadow-none z-10">
      <div className="branding flex items-center">
        <Logo />
        <div className="pl-4"></div>
      </div>
      <div className="actions flex items-center">{actions}</div>
    </div>
  );
};

export default MainNav;
