import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  useGetCollectionsForUserQuery,
  useUpdateKernelMutation,
} from "../../api/troveApi";
import { useAppSelector } from "../../state/hooks";
import { selectCurrentUser } from "../../state/reducers/auth";
import Menu from "../Menu";
import { Kernel } from "../../types/kernel";

const MoveKernelMenu = ({
  close,
  x,
  y,
  kernel,
}: {
  close: () => void;
  x: number;
  y: number;
  kernel: Kernel;
}) => {
  const { userId: currentUserId } = useAppSelector(selectCurrentUser);
  const { data: collections } = useGetCollectionsForUserQuery(
    currentUserId ? { id: currentUserId, currentUserId } : skipToken
  );
  const [updateKernel] = useUpdateKernelMutation();

  if (!collections) {
    return (
      <Menu
        x={x}
        y={y}
        close={close}
        items={[
          {
            onClick: () => {},
            children: <span>Loading</span>,
          },
        ]}
        className="font-bold"
      />
    );
  }

  return (
    <Menu
      className="font-bold"
      x={x}
      y={y}
      close={close}
      items={collections.map((c) => ({
        children: (
          <div className="flex items-center cursor-pointer">
            <div className="mr-4">{c.title}</div>
          </div>
        ),
        onClick: () =>
          updateKernel({
            kernel: {
              ...kernel,
              collectionId: c.id,
              shareUpdate: false,
            },
            kernelId: kernel.id,
          }),
      }))}
    />
  );
};

export default MoveKernelMenu;
