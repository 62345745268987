export type ColorFamily = "yellow" | "blue" | "orange" | "green";

export const randomColorImage = (colorFamily: ColorFamily) => {
  // The pictures are stored at URLs that conform to this shape, with the
  // indices starting at 1 and going up to 4
  const randomIndex = Math.floor(1.0 + Math.random() * 4.0);
  return `https://images.trovecollective.co/default/${colorFamily}-${randomIndex}.png`;
};

export const arrayBufferToBase64 = (buffer: ArrayBufferLike) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};
