import { MouseEventHandler } from "react";
import { User } from "../../types/user";

const BasicInfoForm = ({
  changes,
  current,
  submit,
  update,
}: {
  changes: Partial<User>;
  current: User;
  submit: MouseEventHandler<EventTarget & HTMLFormElement>;
  update: (changes: Partial<User>) => void;
}) => {
  return (
    <form
      className="overflow-y-scroll flex flex-col px-6 pb-4 bg-very-light-gray"
      onSubmit={submit}
    >
      <input type="submit" style={{ display: "none" }} />
      <label className="text-sm text-left mb-1 text-neutral-600 font-bold">
        Name
      </label>
      <input
        className="input text-sm"
        defaultValue={`${current.firstname} ${current.lastname}`}
        onChange={(e) => {
          let [lastname, ...firstname] = e.target.value.split(" ").reverse();
          update({
            ...changes,
            firstname: firstname.reverse().join(" "),
            lastname,
          });
        }}
        name="name"
        type="text"
      />
      <div>
        <label className="text-sm block text-left mb-1 mt-6 text-neutral-600 font-bold">
          Bio
        </label>
        <textarea
          defaultValue={current.bio}
          value={changes.bio}
          className="input text-sm"
          name="bio"
          onChange={(e) =>
            update({ ...changes, bio: e.target.value.slice(0, 400) })
          }
          rows={3}
        />
        <div
          className={`text-left ${
            (changes.bio || current.bio).length >= 400 ? "text-red-600" : ""
          }`}
        >
          {(changes.bio || current.bio).length}/400 characters used
        </div>
      </div>
      <label className="text-sm text-left mb-1 mt-6 text-neutral-600 font-bold">
        Username
      </label>
      <input
        className="input text-sm"
        defaultValue={current.username}
        disabled
        name="username"
        type="text"
      />
      <label className="text-sm text-left mb-1 mt-6 text-neutral-600 font-bold">
        Email
      </label>
      <input
        defaultValue={current.email}
        className="input text-sm"
        name="email"
        disabled
        type="text"
      />
    </form>
  );
};

export default BasicInfoForm;
