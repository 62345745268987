import { Link } from "react-router-dom";
import { Kernel } from "../types/kernel";
import {
  youTubeImage,
  extractSelector,
  spotifyURLsWithCategory,
} from "../content/utils";
import KernelMenu from "./kernel/KernelMenu";

interface KernelsProps {
  fallbackImage: string;
  kernels?: Kernel[];
  spotify: SpotifyState;
}

const Kernels = ({ fallbackImage, kernels, spotify }: KernelsProps) => {
  const spotifyURL = (kernel: Kernel) => {
    const spotifyResouceToShow = spotifyURLsWithCategory(kernel.content);
    if (spotifyResouceToShow) {
      return spotify?.[spotifyResouceToShow.type]?.[spotifyResouceToShow.id]
        ?.images?.[0]?.url;
    }
  };

  const backgroundImageURL = (kernel: Kernel) => {
    return (
      kernel.imageUrls?.[0] ||
      youTubeImage(kernel.content) ||
      spotifyURL(kernel) ||
      (
        extractSelector(
          kernel.content,
          "img:not(.author-edit)"
        ) as NodeListOf<HTMLImageElement>
      )[0]?.src ||
      fallbackImage
    );
  };

  return (
    <div className="sm:overflow-y-auto max-h-full thin-scrollbar pt-2">
      {kernels?.map((kernel) => {
        return (
          <div key={kernel.id} className="mb-8 pr-8">
            <div className="flex items-center">
              <div className="grow">
                <Link key={kernel.id} to={`/kernel/${kernel.id}`}>
                  <div className="flex items-center">
                    <div
                      className="shrink-0 h-32 w-32 bg-cover bg-center rounded-xl mr-8 shrink-0"
                      style={{
                        backgroundImage: `url(${backgroundImageURL(kernel)})`,
                      }}
                    ></div>
                    <h3 className="font-raleway font-bold text-xl grow">
                      {kernel.title}
                    </h3>
                  </div>
                </Link>
              </div>
              <div className="flex space-x-4 items-center">
                <KernelMenu kernel={kernel} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Kernels;
