import { debounce } from "lodash";
import React from "react";
import { useCallback } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { usePrevious } from "../../hooks";
import TailSpin from "./TailSpin";

interface Props<T> {
  data: T[];
  onLoad: (page: number, onLoadSuccess: () => void, onLoadFailure: () => void) => void;
  initialPage?: number;
  renderItem: (item: T, index: number) => JSX.Element;
  containerStyle?: string;
  hasNext?: boolean;
  enableLoadMore?: boolean;
}

const InfinityScrollComponent = <T,>(props: Props<T>, ref: any) => {
  const { data, onLoad, initialPage = 0, renderItem, containerStyle = "", hasNext = false, enableLoadMore = false } = props;
  const [page, setPage] = useState<number>(initialPage);
  const [loading, setLoading] = useState<boolean>(false);
  const previousLoading = usePrevious(loading);
  const firstLoad = useRef<boolean>(true);
  const listRef = useRef<any>(null);

  const onScroll = debounce(() => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        enableLoadMore && !loading && hasNext && fetchData();
      }
    }
  }, 100);

  const fetchData = () => {
    setLoading(true);
  };

  const onLoadSuccess = useCallback(() => {
    setPage(page + 1);
    setLoading(false);
  }, [page]);

  const onLoadFailure = useCallback(() => {
    setLoading(false);
  }, []);

  useImperativeHandle(ref, () => ({
    reloadData() {
      listRef && listRef.current && listRef.current.scrollTo({ top: 0, behavior: "smooth" });
      setPage(initialPage);
      setTimeout(() => {
        fetchData();
      }, 200);
    },
    resetInitPage(page = 1) {
      setPage(page);
    },
    scrollToTop() {
      listRef && listRef.current && listRef.current.scrollTo({ top: 0, behavior: "smooth" });
    },
  }));

  useEffect(() => {
    if (loading && loading !== previousLoading) {
      firstLoad.current = false;
      onLoad(page, onLoadSuccess, onLoadFailure);
    }
  }, [loading, page, onLoadSuccess, onLoadFailure, onLoad, firstLoad, previousLoading]);

  useEffect(() => {
    fetchData();
  }, []);

  return <div
    ref={listRef}
    className={containerStyle}
    onScroll={onScroll}
  >
    {data.map((ele, index) => renderItem(ele, index))}
    {!firstLoad.current && loading && enableLoadMore && hasNext && (
      <TailSpin
        height={25}
        width={25}
        color={"#212121"}
        wrapperClass="flex justify-center content-center my-4 stroke-transparent"
      />
    )}
  </div>
};

type ForwardRefFn<R> = <P = {}>(p: P & React.RefAttributes<R>) => JSX.Element | null;

export const InfinityScroll = React.forwardRef(InfinityScrollComponent) as ForwardRefFn<React.Component>;