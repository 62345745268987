import classnames from "classnames";
import { User } from "../../types/user";

const Avatar = ({
  className,
  user,
}: {
  className?: string;
  user: User | undefined;
}) => (
  <div
    className={classnames(
      "h-8 w-8 rounded-full bg-cover bg-center mr-2 inline-block",
      className
    )}
    style={{ backgroundImage: `url("${user?.profilePicture}")` }}
  />
);

export default Avatar;
